module Modules.Inventories.Default

open Elmish.UrlParser
open Modules.Inventories.Types

let parser =
    oneOf [
        map Home (s "home")
        //map Details (s "details" </> str)
    ]

let getPage url : Page =
    match parseUrl parser url with
    | Some p -> p
    | None -> Home

let create page session setPage (unauthorizedRedirection : obj -> unit) =
    match page with
    | Home -> Home.create session setPage unauthorizedRedirection
    //| Details employeeId -> Details.create employeeId session setPage unauthorizedRedirection
