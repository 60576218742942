module Modules.AppDocs.Feedback

open Sutil
open Sutil.CoreElements
open Auth
open Fable.Remoting.Client
open IApplicationApi

type private State =
    { Session: ClientSession
      Message: string
      SendingFeedbackMessage: Deferred<unit> }

    static member Default session =
        { Session = session
          Message = ""
          SendingFeedbackMessage = Deferred.NotStarted }

type private Message =
    | SetMessage of string
    | SendFeedbackMessage of Command<string>

let private appApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IApplicationApiRoute.builder
    |> Remoting.buildProxy<IApplicationApi>

let private init details = details, Cmd.none

let private update (setAppHomePage : obj -> unit) (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | SetMessage message -> { state with Message = message }, Cmd.none
    | SendFeedbackMessage vc ->
        match vc with
        | Command.Started _ when state.SendingFeedbackMessage = Deferred.InProgress -> state, Cmd.none
        | Command.Started message ->
            { state with SendingFeedbackMessage = Deferred.InProgress },
            remoteCallCommand
                appApi.sendFeedback
                { SessionId = state.Session.SessionId.Value
                  Content = message }
                SendFeedbackMessage
        | Command.Completed ->
            { state with SendingFeedbackMessage = Deferred.Resolved() },
            Cmd.batch [
                Cmd.successToast "Feedback message sent!"
                Cmd.ofEffect setAppHomePage
            ]
        | Command.Error err ->
            { state with SendingFeedbackMessage = Deferred.NotStarted },
            Cmd.errorToast err

let private view (state : IStore<State>) dispatch setAppHomePage =
    fragment [
        UI.heading "Feedback"
        Html.divc "w-full bg-white rounded-lg p-6" [
            Html.divc "w-full md:w-1/2 px-3 mb-6 md:mb-0" [
                Html.pc "mb-6 text-gray-600" [
                    text "We value your input and would love to hear what you think about our web application. Whether you have suggestions for new features, want to report a problem, or just want to share your impressions, we're here to listen."
                ]
                Html.pc "mb-6 text-gray-600" [
                    text "Use the form below to send us your feedback. Your comments will be carefully reviewed and used to improve our application."
                ]
                Html.pc "mb-6 text-gray-600" [
                    text "Thank you for your support!"
                ]
            ]

            Html.divc "grid grid-cols-1 sm:grid-cols-2 gap-4" [
                UI.renderTextAreaField 15 "Feedback" (state .>> (fun s -> s.Message)) (SetMessage >> dispatch)
            ]
            Html.divc "border-t my-4" []
            Html.divc "flex justify-start" [
                Bind.el (
                    state .>> (fun s -> s.SendingFeedbackMessage),
                    fun sending ->
                        match sending with
                        | Deferred.NotStarted ->
                            fragment [
                                Html.buttonc "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" [
                                    Html.text "Send"
                                    onClick (fun _ -> (state |> Store.get).Message |> Command.Started |> SendFeedbackMessage |> dispatch) [ PreventDefault ]
                                ]
                                Html.buttonc "bg-white hover:bg-gray-200 text-gray-600 font-medium py-2 px-4 border border-gray-200 rounded ml-2" [
                                    Html.text "Cancel"
                                    onClick setAppHomePage []
                                ]
                            ]

                        | Deferred.InProgress ->
                            fragment [
                                Html.buttonc "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded disabled:opacity-50" [
                                    Attr.disabled true
                                    text ""
                                    Html.ic "fas fa-spinner fa-spin" []
                                ]
                                Html.buttonc "bg-white hover:bg-gray-200 text-gray-600 font-medium py-2 px-4 border border-gray-200 rounded ml-2 disabled:opacity-50" [
                                    Attr.disabled true
                                    Html.text "Cancel"
                                ]
                            ]
                        | Deferred.Resolved _ -> Html.none
                )
            ]
        ]

        //Bind.el (state .>> (fun s -> s.SendingFeedbackMeessage), UI.spinnerOnCompleted)
    ]

let create session (setAppHomePage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update setAppHomePage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setAppHomePage
    ]