module Modules.Members.Create

open Member
open IMembersApi
open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Modules.Members.Types
open Auth

type private State =
    { Session: ClientSession
      Member: CreateMemberCommand
      CreatingMember: CommandExec<CreateMemberCommand> }

    static member Default session =
        { Session = session
          Member = CreateMemberCommand.New (MemberId.Create().Value)
          CreatingMember = CommandExec.NotStarted }

type private Message =
    | CreateMember of Command<CreateMemberCommand>
    | SetFirstName of string
    | SetLastName of string
    | SetJoinedDate of string
    | SetEmail of string
    | SetJmbg of string
    | SetPersonalId of string
    | SetAddress of string
    | SetBankAccountNumber of string
    | SetEducation of string
    | SetPosition of string
    | SetNotes of string

let private membersApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IMembersApiRoute.builder
    |> Remoting.buildProxy<IMembersApi>

let private init details = details, Cmd.none

let private update setPage unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | SetFirstName firstName -> {state with Member = { state.Member with FirstName = firstName }}, Cmd.none
    | SetLastName lastName -> {state with Member = { state.Member with LastName = lastName }}, Cmd.none
    | SetJoinedDate joinedDate -> {state with Member = { state.Member with Joined = joinedDate }}, Cmd.none
    | SetEmail email -> {state with Member = { state.Member with Email = email }}, Cmd.none
    | SetJmbg jmbg -> {state with Member = { state.Member with Jmbg = jmbg }}, Cmd.none
    | SetPersonalId personalId -> {state with Member = { state.Member with PersonalId = personalId }}, Cmd.none
    | SetAddress address -> {state with Member = { state.Member with Address = address }}, Cmd.none
    | SetBankAccountNumber ban -> {state with Member = { state.Member with BankAccountNumber = ban }}, Cmd.none
    | SetEducation education -> {state with Member = { state.Member with Education = education }}, Cmd.none
    | SetPosition position -> {state with Member = { state.Member with Position = position }}, Cmd.none
    | SetNotes notes -> {state with Member = { state.Member with Notes = notes }}, Cmd.none
    | CreateMember vc ->
        match vc with
        | Command.Started _ when state.CreatingMember = CommandExec.InProgress -> state, Cmd.none
        | Command.Started memb ->
            { state with CreatingMember = CommandExec.InProgress },
            remoteCallCommand
                membersApi.createMember
                { SessionId = state.Session.SessionId.Value
                  Content = { memb with OrganizationId = state.Session.OrgInfo.Id } }
                CreateMember
        | Command.Completed ->
            { state with CreatingMember = CommandExec.Completed },
            Cmd.batch [
                Cmd.successToast "Member created."
                Cmd.ofEffect (fun _ -> setPage Home)
            ]
        | Command.Error err ->
            { state with CreatingMember = CommandExec.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private view state dispatch setPage =
    fragment [
        UI.heading "Create member"
        Html.divc "w-full bg-white rounded-lg p-6" [
            Html.divc "grid grid-cols-1 sm:grid-cols-2 gap-4" [
                UI.renderTextField "First name" (state .>> (fun s -> s.Member.FirstName)) (SetFirstName >> dispatch)
                UI.renderTextField "Last name" (state .>> (fun s -> s.Member.LastName)) (SetLastName >> dispatch)
                UI.renderTextField "Email" (state .>> (fun s -> s.Member.Email)) (SetEmail >> dispatch)
                UI.renderDateField "Joined on" (state .>> (fun s -> s.Member.Joined)) (SetJoinedDate >> dispatch)
                UI.renderTextField "JMBG" (state .>> (fun s -> s.Member.Jmbg)) (SetJmbg >> dispatch)
                UI.renderTextField "Personal ID" (state .>> (fun s -> s.Member.PersonalId)) (SetPersonalId >> dispatch)
                UI.renderTextField "Bank Account Number" (state .>> (fun s -> s.Member.BankAccountNumber)) (SetBankAccountNumber >> dispatch)
                UI.renderTextField "Education" (state .>> (fun s -> s.Member.Education)) (SetEducation >> dispatch)
                UI.renderTextAreaField 3 "Address" (state .>> (fun s -> s.Member.Address)) (SetAddress >> dispatch)
                UI.renderTextField "Position" (state .>> (fun s -> s.Member.Position)) (SetPosition >> dispatch)
                UI.renderTextAreaFieldFullWidth 8 "Notes" (state .>> (fun s -> s.Member.Notes)) (SetNotes >> dispatch)
            ]

            Html.divc "border-t my-4" []

            Html.divc "flex justify-start" [
                Bind.el (
                    state .>> (fun s -> CommandState.notInProgress s.CreatingMember),
                    UI.okBtn "Create" (fun _ -> (state |> Store.get).Member |> Command.Started |> CreateMember |> dispatch)
                )
                Bind.el (
                    state .>> (fun s -> CommandState.notInProgress s.CreatingMember),
                    UI.cancelBtn "Cancel" (fun _ -> setPage Home)
                )
            ]
        ]
    ]

let create session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update setPage unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]
