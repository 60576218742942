module Modules.Inventories.Home

open Organization
open Sutil
open Sutil.Core
open Sutil.CoreElements
open Fable.Remoting.Client
open Auth
open Modules.Inventories.Types
open Shared.Validation
open IInventoriesApi
open Shared

type private InventoryPanelState =
    | None
    | CreatingNew
    | EditingExisting

type private State =
    { GettingOrgInventories: Deferred<GetOrgInventoriesResponse>
      SavingInventory: CommandExec<InventoryCommand>
      Session: ClientSession
      Inventory: InventoryCommand
      PanelState: InventoryPanelState }

    static member Default session =
        { GettingOrgInventories = Deferred.NotStarted
          SavingInventory = CommandExec.NotStarted
          Session = session
          Inventory = InventoryCommand.empty (OrganizationId.Create().Value) session.OrgInfo.Id
          PanelState = None }

type private Message =
    | SetName of string
    | SetNotes of string
    | CloseModal
    | OpenCreateModal
    | StartEditing of OrgInventory
    | GetInventories of Query<string, GetOrgInventoriesResponse>
    | CreateInventory of Command<InventoryCommand>
    | UpdateInventory of Command<InventoryCommand>

let private init state =
    state, Cmd.ofMsg (state.Session.OrgInfo.Id |> Query.Started |> GetInventories)

let private inventoriesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IInventoriesApiRoute.builder
    |> Remoting.buildProxy<IInventoriesApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | SetName name -> { state with Inventory =  { state.Inventory with Name = name }}, Cmd.none
    | SetNotes notes -> { state with Inventory = { state.Inventory with Notes = notes }}, Cmd.none
    | OpenCreateModal -> { state with Inventory = InventoryCommand.empty (OrganizationId.Create().Value) state.Session.OrgInfo.Id; PanelState = CreatingNew }, Cmd.none
    // todo djordje: init Inventory on close and open? same on salary
    | CloseModal -> { state with Inventory = InventoryCommand.empty (OrganizationId.Create().Value) state.Session.OrgInfo.Id; PanelState = None }, Cmd.none
    | StartEditing item ->
        { state with
            PanelState = EditingExisting
            Inventory = { Id = item.Id
                          OrganizationId = state.Session.OrgInfo.Id
                          Name = item.Name
                          Notes = item.Notes }
            }, Cmd.none
    | GetInventories gi ->
        match gi with
        | Query.Started _ when state.GettingOrgInventories = Deferred.InProgress -> state, Cmd.none
        | Query.Started orgId ->
            { state with GettingOrgInventories = Deferred.InProgress },
            remoteCallQuery
                inventoriesApi.getOrgInventories
                { SessionId = state.Session.SessionId.Value
                  Content = { OrganizationId = orgId } }
                  //Content = { OrganizationId = state.Session.OrgInfo.Id } }//todo: djordje ask dejan diff between those two
                GetInventories
        | Query.Completed result ->
            { state with GettingOrgInventories = Deferred.Resolved result },
            Cmd.none
        | Query.Error err ->
            { state with GettingOrgInventories = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | CreateInventory ci ->
        match ci with
        | Command.Started _ when state.SavingInventory = CommandExec.InProgress -> state, Cmd.none
        | Command.Started inventory ->
            { state with SavingInventory =  CommandExec.InProgress },
            remoteCallCommand
                inventoriesApi.createInventory
                { SessionId = state.Session.SessionId.Value //todo djordje: check what if command if diff from presentation
                  Content =  inventory }
                CreateInventory
        | Command.Completed ->
            match state.GettingOrgInventories with
                | Deferred.Resolved inventories ->
                    let newEntry : OrgInventory =
                        {
                            Id = state.Inventory.Id
                            Name = state.Inventory.Name
                            Notes = state.Inventory.Notes
                        }
                    { state with
                        SavingInventory =  CommandExec.Completed
                        GettingOrgInventories = Deferred.Resolved (inventories @ [ newEntry ])
                    }
                | _ ->
                    { state with SavingInventory =  CommandExec.Completed }
            ,
            Cmd.batch [
                Cmd.ofMsg CloseModal
                Cmd.successToast "Inventory added."
            ]
        | Command.Error err ->
            { state with SavingInventory =  CommandExec.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | UpdateInventory ci ->
        match ci with
        | Command.Started _ when state.SavingInventory = CommandExec.InProgress -> state, Cmd.none
        | Command.Started inventory ->
            { state with SavingInventory =  CommandExec.InProgress },
            remoteCallCommand
                inventoriesApi.updateInventory
                { SessionId = state.Session.SessionId.Value
                  Content =  inventory }
                UpdateInventory
        | Command.Completed ->
            let updateElement (el : OrgInventory) (lst : OrgInventory list) =
                lst |> List.map (fun curr -> if curr.Id = el.Id then el else curr)

            match state.GettingOrgInventories with
                | Deferred.Resolved inventories ->
                    let updatedEntry : OrgInventory =
                        {
                            Id = state.Inventory.Id
                            Name = state.Inventory.Name
                            Notes = state.Inventory.Notes
                        }
                    { state with
                        SavingInventory =  CommandExec.Completed
                        GettingOrgInventories = Deferred.Resolved (inventories |> updateElement updatedEntry)
                    }
                | _ ->
                    { state with SavingInventory =  CommandExec.Completed }
            ,
            Cmd.batch [
                Cmd.ofMsg CloseModal
                Cmd.successToast "Inventory added."
            ]
        | Command.Error err ->
            { state with SavingInventory =  CommandExec.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private view (state: IStore<State>) dispatch setPage =
    let renderModal (title:string) (actionBtn: SutilElement) =
        fragment [
            Html.divc "fixed left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full flex" [
                Html.divc "relative w-full h-full max-w-2xl px-4 md:h-auto" [
                    Html.divc "relative bg-white rounded-lg shadow" [
                        Html.divc "flex items-start justify-between p-5 border-b rounded-t" [
                            Html.h3c "text-xl font-semibold" [
                                Html.text title
                            ]
                            Html.buttonc "ext-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" [
                                Html.ic "fa-solid fa-xmark" []
                                onClick (fun _ -> CloseModal |> dispatch) [ PreventDefault ]
                            ]
                        ]
                        Html.divc "p-6 space-y-6" [
                            Html.divc "grid grid-cols-1 gap-4" [
                                UI.renderTextField "Name" (state .>> (fun s -> s.Inventory.Name)) (SetName >> dispatch)
                                UI.renderTextAreaField 3 "Notes" (state .>> (fun s -> s.Inventory.Notes)) (SetNotes >> dispatch)
                            ]
                        ]
                        Html.divc "items-center p-6 border-t border-gray-200 rounded-b" [
                            actionBtn
                        ]
                    ]
                ]
            ]
            Html.divc "bg-gray-900 bg-opacity-50 fixed inset-0 z-40" []
        ]
    let renderCreateInventoryModal =
        renderModal
           "Add Inventory"
           (Bind.el (
                state .>> (fun s -> CommandState.notInProgress s.SavingInventory),
                UI.okBtn "Create" (fun _ -> (state |> Store.get).Inventory |> Command.Started |> CreateInventory |> dispatch)
            ))

    let renderEditInventoryModal =
        renderModal
            "Edit Inventory" //todo djordje: is this ok or should be different true button
            (Bind.el (
                state .>> (fun s -> CommandState.notInProgress s.SavingInventory),
                UI.okBtn "Update" (fun _ -> (state |> Store.get).Inventory |> Command.Started |> UpdateInventory |> dispatch)
            ))
    Html.div [
        UI.heading "Inventories"

        Html.divc "flex w-full flex-col justify-start space-y-2 sm:flex-row sm:space-y-0" [
            Html.buttonc "focus:ring outline-none rounded-lg text-white bg-blue-600 px-8 py-2 font-bold active:scale-95 hover:opacity-90" [
                Html.text "Create New"
                onClick (fun _ -> OpenCreateModal |> dispatch) []
            ]
        ]

        Bind.el(
            state .>> (fun s -> s.GettingOrgInventories), fun gettingInventories ->
            match gettingInventories with
            | Deferred.NotStarted
            | Deferred.InProgress -> UI.loader
            | Deferred.Resolved inventories ->
                Html.divc "overflow-x-auto w-full py-5" [
                    Html.tablec "mx-auto w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden" [
                        Html.theadc "bg-gray-50" [
                            Html.trc "text-black text-left" [
                                let cell el =
                                    Html.tdc "font-semibold text-sm uppercase px-6 py-4" [el]

                                cell (Html.text "Name")
                                cell (Html.text "Actions")
                            ]
                        ]
                        Html.tbody [
                            Attr.className "divide-y divide-gray-200"
                            fragment (
                                inventories |>
                                List.map (fun inventory ->
                                    Html.tr [
                                        let cell el = Html.tdc "px-6 py-4" [el]

                                        cell(Html.text $"{inventory.Name}")
                                        cell(
                                            Html.divc "flex items-center" [
                                                UI.editBtn
                                                    "Edit salary"
                                                    (fun _ -> inventory |> StartEditing  |> dispatch)
                                            ]
                                        )
                                    ]
                                )
                            )
                        ]
                    ]
                ]
            )
        Bind.el(state .>> (fun state -> state.PanelState), fun panelState ->
            match panelState with
            | None ->
                Html.none
            | CreatingNew ->
                renderCreateInventoryModal
            | EditingExisting ->
                renderEditInventoryModal
        )
    ]

let create session setPage (unauthorizedRedirPage: obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore

    fragment [ disposeOnUnmount [ state ]; view state dispatch setPage ]