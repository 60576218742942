module IAbsencesApi

open Shared
open Shared.Validation
open Absences
open System
open Ulid

let getDaysInMonth (year: int) (month: int) = 
    DateTime.DaysInMonth(year, month)        

type GetCalendarMonthRequest = 
    { 
        OrganizationId: string
        Month: CalendarMonth }

type GetCalendarMonthResponse = 
    {
        Calendar: AbsenceCalendarMonth
        Employees: (string * string) list
    }

type CreateAbsenceCommand = 
    {
        OrganizationId: string
        EmployeeId: string
        Interval: DateRange.T
        Type: string
        Note: string
    }

module CreateAbsenceCommand =
    let empty orgId : CreateAbsenceCommand =
        {
            OrganizationId = orgId
            EmployeeId = ""
            Interval = DateRange.empty
            Type = ""
            Note = ""
        }

    let isValid (c : CreateAbsenceCommand) =
        not (String.IsNullOrWhiteSpace c.OrganizationId)
        &&
        not (String.IsNullOrWhiteSpace c.EmployeeId)
        &&
        DateRange.isValid c.Interval

    let (|Valid|_|) (c: CreateAbsenceCommand) =
        if isValid c then Some c else None

    // todo : this function should use Domain.Absence contructor instead of manually constructing object
    // and name it tryCreate
    let toDomain (c : CreateAbsenceCommand) : Result<Domain.Absence, AggregateError> =
        let start' = DateRange.start c.Interval
        let end' = DateRange.``end`` c.Interval
        
        match start', end' with
        | Some s, Some e ->
            Ok {
                Id = Ulid.New.Value.ToString()
                OrganizationId = c.OrganizationId
                EmployeeId = c.EmployeeId
                Start = s
                End = e
                Type = c.Type
                Note = c.Note
            }
        | _, _ -> [ ValidationError "Invalid absence interval" ] |> AggregateError |> Error

type IAbsencesApi =
    { 
        getCalendarMonth: SecureRequest<GetCalendarMonthRequest> -> Async<Result<GetCalendarMonthResponse, AggregateError>>   
        sendCreateAbsenceCommand : SecureRequest<CreateAbsenceCommand> -> Async<Result<unit, AggregateError>>
    }

module IAbsencesApiRoute =
    let builder typeName methodName = $"/api/{typeName}/{methodName}"
