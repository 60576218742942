module Modules.AppDocs.Defaults

open Elmish.UrlParser
open Modules.AppDocs.Types

let parser =
    oneOf [
        map About (top)
        map Team (s "team")
        map Feedback (s "feedback")
    ]

let getPage url : Page =
    match parseUrl parser url with
    | Some p -> p
    | None -> About

let create page session setPage (setAppHomePage : obj -> unit) =
    match page with
    | About -> About.create setPage
    | Team -> Team.create setPage
    | Feedback -> Feedback.create session setAppHomePage
