module Modules.Employees.Create

open Employee
open IEmployeesApi
open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Modules.Employees.Types
open Auth
open Browser.Types
open Modules.Employees.Utils
open Employees.UI

type private State =
    { Session: ClientSession
      Employee: CreateEmployeeCommand
      CreatingEmployee: CommandExec<CreateEmployeeCommand> }

    static member Default session =
        { Session = session
          Employee = CreateEmployeeCommand.empty (EmployeeId.Create().Value) session.OrgInfo.Id
          CreatingEmployee = CommandExec.NotStarted }

type private Message =
    | CreateEmployee of Command<CreateEmployeeCommand>
    | SetFirstName of string
    | SetLastName of string
    | SetJoinedDate of string
    | SetEmail of string
    | SetJmbg of string
    | SetPersonalId of string
    | SetAddress of string
    | SetBankAccountNumber of string
    | SetEducation of string
    | SetPosition of string
    | SetNotes of string
    | AddFile of File
    | HandleFileContent of RawFileInfo
    | RemoveFile of string

let private employeesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IEmployeesApiRoute.builder
    |> Remoting.buildProxy<IEmployeesApi>

let private init details = details, Cmd.none

let private update setPage unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | SetFirstName firstName -> {state with Employee = { state.Employee with FirstName = firstName }}, Cmd.none
    | SetLastName lastName -> {state with Employee = { state.Employee with LastName = lastName }}, Cmd.none
    | SetJoinedDate joinedDate -> {state with Employee = { state.Employee with Joined = joinedDate }}, Cmd.none
    | SetEmail email -> {state with Employee = { state.Employee with Email = email }}, Cmd.none
    | SetJmbg jmbg -> {state with Employee = { state.Employee with Jmbg = jmbg }}, Cmd.none
    | SetPersonalId personalId -> {state with Employee = { state.Employee with PersonalId = personalId }}, Cmd.none
    | SetAddress address -> {state with Employee = { state.Employee with Address = address }}, Cmd.none
    | SetBankAccountNumber ban -> {state with Employee = { state.Employee with BankAccountNumber = ban }}, Cmd.none
    | SetEducation education -> {state with Employee = { state.Employee with Education = education }}, Cmd.none
    | SetPosition position -> {state with Employee = { state.Employee with Position = position }}, Cmd.none
    | SetNotes notes -> {state with Employee = { state.Employee with Notes = notes }}, Cmd.none
    | CreateEmployee vc ->
        match vc with
        | Command.Started _ when state.CreatingEmployee = CommandExec.InProgress -> state, Cmd.none
        | Command.Started command ->
            { state with CreatingEmployee = CommandExec.InProgress },
            remoteCallCommand
                employeesApi.createEmployee
                { SessionId = state.Session.SessionId.Value
                  Content = command }
                CreateEmployee
        | Command.Completed ->
            { state with CreatingEmployee = CommandExec.Completed },
            Cmd.batch [
                Cmd.successToast "Employee created."
                Cmd.ofEffect (fun _ -> setPage Home)
            ]
        | Command.Error err ->
            { state with CreatingEmployee = CommandExec.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | AddFile file ->
        state,
        Cmd.OfAsync.perform readContent file HandleFileContent
    | HandleFileContent fi ->
        { state with Employee = { state.Employee with Files = state.Employee.Files @ [{ FileId = ""; Content = fi.Content; Name = fi.Name; MimeType = fi.MimeType; Size = fi.Size}] }},
        Cmd.none
    | RemoveFile fileName ->
        { state with Employee = { state.Employee with Files = state.Employee.Files |> List.filter (fun file -> file.Name <> fileName ) }},
        Cmd.none

let private view state dispatch setPage =

    let files = Store.make Unchecked.defaultof<Browser.Types.FileList>
    let fileSeq = files |> Store.map (Helpers.fileListToSeq >> Seq.toList)

    fragment [
        disposeOnUnmount [ files ]

        disposeOnUnmount [
            fileSeq
            |> Store.iter (
                fun files ->
                   for file in files do
                        dispatch (AddFile file)
            )
        ]

        UI.heading "Create employee"
        Html.divc "w-full bg-white rounded-lg p-6" [
            Html.divc "grid grid-cols-1 sm:grid-cols-2 gap-4" [
                UI.renderTextField "First name" (state .>> (fun s -> s.Employee.FirstName)) (SetFirstName >> dispatch)
                UI.renderTextField "Last name" (state .>> (fun s -> s.Employee.LastName)) (SetLastName >> dispatch)
                UI.renderTextField "Email" (state .>> (fun s -> s.Employee.Email)) (SetEmail >> dispatch)
                UI.renderDateField "Joined on" (state .>> (fun s -> s.Employee.Joined)) (SetJoinedDate >> dispatch)
                UI.renderTextField "JMBG" (state .>> (fun s -> s.Employee.Jmbg)) (SetJmbg >> dispatch)
                UI.renderTextField "Personal ID" (state .>> (fun s -> s.Employee.PersonalId)) (SetPersonalId >> dispatch)
                UI.renderTextField "Bank Account Number" (state .>> (fun s -> s.Employee.BankAccountNumber)) (SetBankAccountNumber >> dispatch)
                UI.renderTextField "Education" (state .>> (fun s -> s.Employee.Education)) (SetEducation >> dispatch)
                UI.renderTextAreaField 3 "Address" (state .>> (fun s -> s.Employee.Address)) (SetAddress >> dispatch)
                UI.renderTextField "Position" (state .>> (fun s -> s.Employee.Position)) (SetPosition >> dispatch)
                UI.renderTextAreaFieldFullWidth 6 "Notes" (state .>> (fun s -> s.Employee.Notes)) (SetNotes >> dispatch)
            ]
            Html.divc "flex flex-col sm:flex-row justify-start mt-4" [
                Html.divc "" [
                    Html.labelc "block mb-2 text-sm font-medium text-gray-900 dark:text-white" [
                        Html.text "Files"
                    ]

                    Html.divc "flex flex-wrap justify-start" [
                        Bind.each (
                            state .>> (fun s -> s.Employee.Files),
                            fun file ->
                                Html.divc "relative mr-5 mb-5 md:mb-0" [
                                    renderPreview file.MimeType file.Content
                                    removeFileBtn (fun _ -> RemoveFile file.Name |> dispatch)
                                    renderFileName file.Name
                                ]
                        )

                        uploadFileArea files
                    ]
                ]
            ]


            Html.divc "border-t my-4" []

            Html.divc "flex justify-start" [
                Bind.el (
                    state .>> (fun s -> CommandState.notInProgress s.CreatingEmployee),
                    UI.okBtn "Create" (fun _ -> (state |> Store.get).Employee |> Command.Started |> CreateEmployee |> dispatch)
                )
                Bind.el (
                    state .>> (fun s -> CommandState.notInProgress s.CreatingEmployee),
                    UI.cancelBtn "Cancel" (fun _ -> setPage Home)
                )
            ]
        ]
    ]

let create session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update setPage unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]
