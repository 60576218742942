namespace Shared

[<AutoOpen>]
module PrimitiveTypes =
    open System
    open Shared.Validation
    open Ulid

    type Email =
        private
        | Email of string
        member this.Value = this |> fun (Email value) -> value

        static member New(email) =
            match email with
            | "" -> [ ValidationError "email is empty" ] |> AggregateError |> Error
            | null -> [ ValidationError "email is empty"] |> AggregateError |> Error
            | email when not (email.Contains("@")) ->
                [ ValidationError "invalid email format" ] |> AggregateError |> Error
            | email when email.Contains("@") && not ((email.Split('@')[1]).Contains(".")) ->
                [ ValidationError "invalid email format" ] |> AggregateError |> Error
            | _ -> Ok(Email email)

        static member Parse(email) =
            match email with
            | null
            | "" -> Ok None
            | _ ->
                let parsedEmail = Email.New email
                match parsedEmail with
                | Error err -> err |> Error
                | Ok x -> Some x |> Ok

    type Phone =
        private
        | Phone of string
        member this.Value = this |> fun (Phone value) -> value

        static member New(phone) =
            match phone with
            | null
            | "" -> [ ValidationError "phone is empty" ] |> AggregateError |> Error
            | _ -> Ok(Phone phone)

        static member Parse(phone) =
            match phone with
            | null
            | "" -> Ok None
            | _ ->
                let parsedPhone = Phone.New phone
                match parsedPhone with
                | Error err -> err |> Error
                | Ok x -> Some x |> Ok

    type SessionId =
        private
        | SessionId of string
        member this.Value =
            this |> fun (SessionId value) -> value

        static member Create () =
            SessionId (Ulid.New.ToString())

        static member New id =
            match id with
            | null
            | "" -> [ ValidationError "id is empty" ] |> AggregateError |> Error
            | _ -> Ok(SessionId id)

    type UserId =
        private
        | UserId of string
        member this.Value = this |> fun (UserId value) -> value

        static member Create () =
            UserId (Ulid.New.ToString())

        static member New(id) =
            match id with
            | null
            | "" -> [ ValidationError "user id is empty" ] |> AggregateError |> Error
            | _ -> Ok(UserId id)

    type Date =
        private
        | Date of DateTime
        member this.Value = this |> fun (Date value) -> value.Date
        member this.FormattedValue (format : string) = this |> fun (Date value) -> value.Date.ToString(format)

        static member New(date: string) =
            match date with
            | null
            | "" -> [ ValidationError "date is empty" ] |> AggregateError |> Error
            | _ ->
                let success, parsedDate = DateTime.TryParse(date)
                match success with
                | true -> Ok (Date parsedDate)
                | false -> [ ValidationError "Invalid date format"] |> AggregateError |> Error

    type Address =
        private
        | Address of string
        member this.Value =
            let (Address value) = this in value

        static member New(address) =
            match address with
            | null
            | "" -> [ ValidationError "address is empty" ] |> AggregateError |> Error
            | _ -> Address address |> Ok

        static member NewOptional(address) =
            match address with
            | null
            | "" -> None |> Ok
            | _ -> Address address |> Some |> Ok

    type Name =
        private
        | Name of string
        member this.Value =
            let (Name value) = this in value

        static member New(name) =
            match name with
            | null
            | "" -> [ ValidationError "name is empty" ] |> AggregateError |> Error
            | _ -> Name name |> Ok

    let toISO (date: DateTime) =
        date.ToString("yyyy-MM-dd")

    type CalendarMonth =
        { Month : int 
          Year : int }
    module CalendarMonth = 
        let create month year : CalendarMonth =
            {
                Month = month
                Year = year
            }

        let current : CalendarMonth = 
            let today = DateTime.Today    
            {
                Month = today.Month
                Year = today.Year
            }

        let prev (current : CalendarMonth) : CalendarMonth =
            let prevMonth = (new DateTime(current.Year, current.Month, 1)).AddMonths(-1)
            {
                Month = prevMonth.Month
                Year = prevMonth.Year
            }

        let next (current : CalendarMonth) : CalendarMonth =
            let nextMonth = (new DateTime(current.Year, current.Month, 1)).AddMonths(1)
            {
                Month = nextMonth.Month
                Year = nextMonth.Year
            }

        let numberOfDays (m : CalendarMonth) : int = 
            DateTime.DaysInMonth(m.Year, m.Month)

        let firstDay (m : CalendarMonth) =
            let date = new DateTime(m.Year, m.Month, 1)
            let day = int date.DayOfWeek
            (day - 1) % 7 // -1 because week does not start from Sunday - instead it starts from Monday

        let firstDate (m : CalendarMonth) : DateTime =
            new DateTime (m.Year, m.Month, 1)

        let lastDate (m : CalendarMonth) : DateTime =
            new DateTime (m.Year, m.Month, numberOfDays m)

    type CalendarInterval =
        {
            From: DateTime
            To: DateTime
        }
    
    module CalendarInterval =
        let monthSurroundedWithWeeks (month: CalendarMonth) : CalendarInterval =
            {
                From = (CalendarMonth.firstDate month).AddDays(-7)
                To = (CalendarMonth.lastDate month).AddDays(7)
            }

    module DateRange = 
        type T = //private
            { 
                StartDate: DateTime option
                EndDate: DateTime option
            }

        type Error =
            | StartDateAfterEndDate
            | InvalidDateFormat

        let empty : T =
            {
                StartDate = None
                EndDate = None
            }

        let start (range : T) = range.StartDate

        let ``end`` (range : T) = range.EndDate

        let startValue (range : T) =
            match range.StartDate with
            | None -> ""
            | Some date -> date.ToString("s")

        let endValue (range : T) =
            match range.EndDate with
            | None -> ""
            | Some date -> date.ToString("s")

        let setStart (range : T) (startDate : string) : Result<T, Error> =
            match startDate with
            | "" -> Ok { range with StartDate = None }
            | _ ->
                let (success, startDate') = DateTime.TryParse startDate
                match (success, startDate') with
                | false, _ -> InvalidDateFormat |> Error
                | true, _ ->
                    match range.EndDate with
                    | Some endDate when startDate' > endDate -> StartDateAfterEndDate |> Error
                    | _ -> Ok { range with StartDate = Some startDate' }

        let setEnd (range : T) (endDate : string) : Result<T, Error> =
            match endDate with
            | "" -> Ok { range with EndDate = None }
            | _ ->
                let (success, endDate') = DateTime.TryParse endDate
                match (success, endDate') with
                | false, _ -> InvalidDateFormat |> Error
                | true, _ ->
                    match range.StartDate with
                    | Some startDate when startDate > endDate' -> StartDateAfterEndDate |> Error
                    | _ -> Ok { range with EndDate = Some endDate' }

        let isValid (range: T) : bool =
            match (range.StartDate, range.EndDate) with
            | Some s, Some e when s <= e -> true
            | _, _ -> false

        let (|Valid|_|) (range: T) =
            if isValid range then Some range else None