module Organization

open Shared
open Validation
open Ulid

type OrganizationId =
    private
    | OrganizationId of string
    member this.Value =
        let (OrganizationId value) = this in value

    static member Create () =
        OrganizationId (Ulid.New.ToString())

    static member New(id) =
        match id with
        | null
        | "" -> [ ValidationError "organization id is empty" ] |> AggregateError |> Error
        | _ -> OrganizationId id |> Ok

type OrganizationName =
    private
    | OrganizationName of string
    member this.Value =
        let (OrganizationName value) = this in value

    static member New(name) =
        match name with
        | null
        | "" -> [ ValidationError "organization name is empty" ] |> AggregateError |> Error
        | _ -> OrganizationName name |> Ok

type CompanyTaxNumber =
    private
    | CompanyTaxNumber of string
    member this.Value =
        let (CompanyTaxNumber value) = this in value

    static member New(taxNumber) =
        match taxNumber with
        | null
        | "" -> [ ValidationError "TaxNumber is empty" ] |> AggregateError |> Error
        | _ -> CompanyTaxNumber taxNumber |> Ok

    static member NewOptional(taxNumber) =
        match taxNumber with
        | null
        | "" -> None |> Ok
        | _ -> CompanyTaxNumber taxNumber |> Some |> Ok

type CompanyRegistrationNumber =
    private
    | CompanyRegistrationNumber of string
    member this.Value =
        let (CompanyRegistrationNumber value) = this in value

    static member New(regNumber) =
        match regNumber with
        | null
        | "" -> [ ValidationError "RegNumber is empty" ] |> AggregateError |> Error
        | _ -> CompanyRegistrationNumber regNumber |> Ok

    static member NewOptional(regNumber) =
        match regNumber with
        | null
        | "" -> None |> Ok
        | _ -> CompanyRegistrationNumber regNumber |> Some |> Ok

[<RequireQualifiedAccess>]
module Domain =
    type Organization =
        { Id: OrganizationId
          Owner: UserId
          Name: OrganizationName
          Address: Address option
          RegistrationNumber: CompanyRegistrationNumber option
          TaxNumber: CompanyTaxNumber option
          Email: Email option
          Phone: Phone option
           }

    module Organization =
        let create
            (id: OrganizationId)
            (owner: UserId)
            (name: OrganizationName)
            (address: Address option)
            (regNum: CompanyRegistrationNumber option)
            (taxNum: CompanyTaxNumber option)
            (email: Email option)
            (phone: Phone option)
            : Result<Organization, AggregateError> 
            =
                  Ok 
                    {   Id = id
                        Owner = owner
                        Name = name
                        Address = address
                        RegistrationNumber = regNum
                        TaxNumber = taxNum
                        Email = email
                        Phone = phone
                    }

