module Modules.Salaries.Details

open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Auth
open ISalariesApi
open Shared

type private SalaryDetailPanelState =
    | None
    | CreatingNew
    | EditingExisting

type private State =
    { GettingEmployeeSalaries: Deferred<EmployeeSalariesResponse>
      SavingSalary: CommandExec<SalaryCommand>
      Session: ClientSession
      Salary: SalaryCommand
      EmployeeId: string
      PanelState: SalaryDetailPanelState
      }
      static member Default session employeeId =
        {  GettingEmployeeSalaries = Deferred.NotStarted
           Session = session
           Salary = SalaryCommand.New(employeeId)
           SavingSalary = CommandExec.NotStarted
           EmployeeId = employeeId
           PanelState = None }

type private Message =
    | GetEmployeeSalaries of Query<string, EmployeeSalariesResponse>
    | CreateSalary of Command<SalaryCommand>
    | UpdateSalary of Command<SalaryCommand>
    | SetTaxedAmount of decimal
    | SetTaxedCurrency of string
    | SetUntaxedAmount of decimal
    | SetUntaxedCurrency of string
    | SetEffectiveDate of string
    | SetNotes of string
    | StartEditing of EmployeeSalaryHistoryEntry
    | CloseModal
    | OpenCreateModal

let private init state =
    state, Cmd.ofMsg (state.EmployeeId |> Query.Started |> GetEmployeeSalaries)

let private salariesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder ISalariesApiRoute.builder
    |> Remoting.buildProxy<ISalariesApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | SetTaxedAmount amount -> {state with Salary = { state.Salary with TaxedAmount = amount }}, Cmd.none
    | SetTaxedCurrency currency -> {state with Salary = { state.Salary with TaxedCurrency = currency }}, Cmd.none
    | SetUntaxedAmount amount -> {state with Salary = { state.Salary with UntaxedAmount = amount }}, Cmd.none
    | SetUntaxedCurrency currency -> {state with Salary = { state.Salary with UntaxedCurrency = currency }}, Cmd.none
    | SetEffectiveDate effectiveDate -> {state with Salary = { state.Salary with EffectiveDate = effectiveDate }}, Cmd.none
    | SetNotes notes -> {state with Salary = { state.Salary with Notes = notes }}, Cmd.none
    | CloseModal -> { state with Salary = SalaryCommand.New(state.EmployeeId); PanelState = None }, Cmd.none
    | OpenCreateModal -> { state with Salary = SalaryCommand.New(state.EmployeeId); PanelState = CreatingNew }, Cmd.none
    | CreateSalary vc ->
        match vc with
        | Command.Started _ when state.SavingSalary = CommandExec.InProgress -> state, Cmd.none
        | Command.Started salary ->
            { state with SavingSalary = CommandExec.InProgress },
            remoteCallCommand
                salariesApi.saveSalary
                { SessionId = state.Session.SessionId.Value
                  Content =  salary }
                CreateSalary
        | Command.Completed ->
            match state.GettingEmployeeSalaries with
                | Deferred.Resolved salaryHistory ->
                    let newEntry : EmployeeSalaryHistoryEntry =
                        {
                            Id = state.Salary.Id
                            Notes = state.Salary.Notes
                            EffectiveDate = state.Salary.EffectiveDate
                            TaxedAmount = state.Salary.TaxedAmount
                            TaxedCurrency = state.Salary.TaxedCurrency
                            UntaxedAmount = state.Salary.UntaxedAmount
                            UntaxedCurrency = state.Salary.UntaxedCurrency
                        }
                    { state with
                        SavingSalary = CommandExec.Completed
                        GettingEmployeeSalaries = Deferred.Resolved (
                                {
                                    EmployeeName = salaryHistory.EmployeeName
                                    Salaries = salaryHistory.Salaries @ [ newEntry ] |> List.sortByDescending(fun x -> x.EffectiveDate)
                                })
                    }
                | _ ->
                    { state with SavingSalary = CommandExec.Completed }
            ,
            Cmd.batch [
                Cmd.ofMsg CloseModal
                Cmd.successToast "Salary added."
            ]
        | Command.Error err ->
            { state with SavingSalary = CommandExec.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | GetEmployeeSalaries fo ->
        match fo with
        | Query.Started _ when state.GettingEmployeeSalaries = Deferred.InProgress -> state, Cmd.none
        | Query.Started _ ->
            { state with GettingEmployeeSalaries = Deferred.InProgress },
            remoteCallQuery
                salariesApi.getEmployeeSalaries {
                    SessionId = state.Session.SessionId.Value
                    Content = { EmployeeId = state.EmployeeId } }
                GetEmployeeSalaries
        | Query.Completed result ->
            { state with GettingEmployeeSalaries = Deferred.Resolved(result) },
            Cmd.none
        | Query.Error err ->
            { state with GettingEmployeeSalaries = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | StartEditing item ->
        { state with
            PanelState = EditingExisting
            Salary = {  Id = item.Id
                        EmployeeId = state.EmployeeId
                        TaxedAmount = item.TaxedAmount
                        TaxedCurrency = item.TaxedCurrency
                        UntaxedAmount = item.UntaxedAmount
                        UntaxedCurrency = item.UntaxedCurrency
                        EffectiveDate = item.EffectiveDate
                        Notes = item.Notes }
            }, Cmd.none
    | UpdateSalary us ->
        match us with
        | Command.Started _ when state.SavingSalary = CommandExec.InProgress -> state, Cmd.none
        | Command.Started salary ->
            { state with SavingSalary = CommandExec.InProgress },
            remoteCallCommand
                salariesApi.saveSalary
                { SessionId = state.Session.SessionId.Value
                  Content =  salary }
                UpdateSalary
        | Command.Completed ->
            let updateElement (el : EmployeeSalaryHistoryEntry) (lst : EmployeeSalaryHistoryEntry list) =
                lst |> List.map (fun curr -> if curr.Id = el.Id then el else curr)

            match state.GettingEmployeeSalaries with
                | Deferred.Resolved salaryHistory ->
                    let updatedEntry : EmployeeSalaryHistoryEntry =
                        {
                            Id = state.Salary.Id
                            Notes = state.Salary.Notes
                            EffectiveDate = state.Salary.EffectiveDate
                            TaxedAmount = state.Salary.TaxedAmount
                            TaxedCurrency = state.Salary.TaxedCurrency
                            UntaxedAmount = state.Salary.UntaxedAmount
                            UntaxedCurrency = state.Salary.UntaxedCurrency
                        }
                    { state with
                        SavingSalary = CommandExec.Completed
                        GettingEmployeeSalaries = Deferred.Resolved (
                                {
                                    EmployeeName = salaryHistory.EmployeeName
                                    Salaries = salaryHistory.Salaries
                                                |> updateElement updatedEntry
                                                |> List.sortByDescending(fun x -> x.EffectiveDate)
                                })
                    }
                | _ ->
                    { state with SavingSalary = CommandExec.Completed }
            ,
            Cmd.batch [
                Cmd.ofMsg CloseModal
                Cmd.successToast "Salary updated."
            ]
        | Command.Error err ->
            { state with SavingSalary = CommandExec.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private view (state: IStore<State>) dispatch setPage =
    let renderModal (title:string) actionBtn =
        fragment [
            Html.divc "fixed left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full flex" [
                Html.divc "relative w-full h-full max-w-2xl px-4 md:h-auto" [
                    Html.divc "relative bg-white rounded-lg shadow" [
                        Html.divc "flex items-start justify-between p-5 border-b rounded-t" [
                            Html.h3c "text-xl font-semibold" [
                                Html.text title
                            ]
                            Html.buttonc "ext-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" [
                                Html.ic "fa-solid fa-xmark" []
                                onClick (fun _ -> CloseModal |> dispatch) [ PreventDefault ]
                            ]
                        ]
                        Html.divc "p-6 space-y-6" [
                            Html.divc "grid grid-cols-6 gap-6" [
                                Html.divc "col-span-6 sm:col-span-2" [
                                    Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                                        Html.text "Taxed amount"
                                    ]
                                    Html.divc "relative mt-1 rounded-md shadow-sm" [
                                        Html.inputc "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" [
                                            Attr.typeNumber
                                            Bind.attr ("value", state .>> (fun s -> s.Salary.TaxedAmount), SetTaxedAmount >> dispatch)
                                        ]
                                        Html.divc "absolute inset-y-0 right-2 flex items-center" [
                                            Html.select [
                                                Attr.className "h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-1 text-gray-700"
                                                Bind.selectSingle (state .>> (fun s -> s.Salary.TaxedCurrency), SetTaxedCurrency >> dispatch)
                                                Html.option [
                                                    Attr.value "RSD"
                                                    text "RSD"
                                                ]
                                                Html.option [
                                                    Attr.value "EUR"
                                                    text "EUR"
                                                ]
                                                Html.option [
                                                    Attr.value "USD"
                                                    text "USD"
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                                Html.divc "col-span-6 sm:col-span-2" [
                                    Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                                        Html.text "Untaxed amount"
                                    ]
                                    Html.divc "relative mt-1 rounded-md shadow-sm" [
                                        Html.inputc "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" [
                                            Attr.typeNumber
                                            Bind.attr ("value", state .>> (fun s -> s.Salary.UntaxedAmount), SetUntaxedAmount >> dispatch)
                                        ]
                                        Html.divc "absolute inset-y-0 right-2 flex items-center" [
                                            Html.select [
                                                Attr.className "h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-1 text-gray-700"
                                                Bind.selectSingle (state .>> (fun s -> s.Salary.UntaxedCurrency), SetUntaxedCurrency >> dispatch)
                                                Html.option [
                                                    Attr.value "RSD"
                                                    text "RSD"
                                                ]
                                                Html.option [
                                                    Attr.value "EUR"
                                                    text "EUR"
                                                ]
                                                Html.option [
                                                    Attr.value "USD"
                                                    text "USD"
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                                Html.divc "col-span-6 sm:col-span-2" [
                                    Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                                        Html.text "Effective date"
                                    ]
                                    Html.inputc "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" [
                                        Html.text "Effective date"
                                        Attr.typeDate
                                        Bind.attr ("value", (state .>> (fun s -> s.Salary.EffectiveDate)), (SetEffectiveDate >> dispatch))        ]
                                ]
                                Html.divc "col-span-6" [
                                    Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                                        Html.text "Notes"
                                    ]
                                    Html.textarea [
                                        Attr.className "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                        Attr.placeholder "Notes"
                                        Attr.rows 3
                                        Bind.attr ("value", (state .>> (fun s -> s.Salary.Notes)), (SetNotes >> dispatch))
                                    ]
                                ]
                            ]
                        ]
                        Html.divc "items-center p-6 border-t border-gray-200 rounded-b" [
                            actionBtn
                        ]
                    ]
                ]
            ]
            Html.divc "bg-gray-900 bg-opacity-50 fixed inset-0 z-40" []
        ]
    let renderCreateSalaryModal =
        renderModal
           "Add Salary"
           (UI.okBtn "Add New" (fun _ -> (state |> Store.get).Salary |> Command.Started |> CreateSalary |> dispatch) true)

    let renderEditSalaryModal =
        renderModal
            "Edit Salary"
            (UI.okBtn "Update" (fun _ -> (state |> Store.get).Salary |> Command.Started |> UpdateSalary |> dispatch) true)

    fragment [
        Html.div [
        Bind.el(state .>> (fun x -> x.GettingEmployeeSalaries), fun gsh ->
            match gsh with
            | Deferred.Resolved res -> UI.heading res.EmployeeName
            | _ -> Html.none
        )
        Html.divc "flex w-full flex-col justify-start space-y-2 sm:flex-row sm:space-y-0" [
            Html.buttonc "focus:ring outline-none rounded-lg text-white bg-blue-600 px-8 py-2 font-bold active:scale-95 hover:opacity-90" [
                Html.text "Create New"
                onClick (fun _ -> OpenCreateModal |> dispatch) []
            ]
        ]
        Bind.el(
            state .>> (fun s -> s.GettingEmployeeSalaries), fun history ->
            match history with
            | Deferred.NotStarted
            | Deferred.InProgress -> UI.loader
            | Deferred.Resolved salaryHistory ->
                Html.divc "overflow-x-auto w-full py-5" [
                    Html.tablec "mx-auto w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden" [
                        Html.theadc "bg-gray-50" [
                            Html.trc "text-black text-left" [
                                let cell el = Html.tdc "font-semibold text-sm uppercase px-6 py-4" [ el ]

                                cell (Html.text "Effective date")
                                cell (Html.text "Taxed Salary")
                                cell (Html.text "Untaxed Salary")
                                cell (Html.text "Notes")
                                cell (Html.text "Actions")
                            ]
                        ]
                        Html.tbody [
                            Attr.className "divide-y divide-gray-200"

                            fragment (
                                salaryHistory.Salaries |>
                                List.map
                                    (fun item ->
                                            Html.tr [
                                                let cell el = Html.tdc "px-6 py-4" [ el ]

                                                cell (Html.text $"{item.EffectiveDate}")
                                                cell (Html.text $"{formatMoney item.TaxedAmount} {item.TaxedCurrency}")
                                                cell (Html.text $"{formatMoney item.UntaxedAmount} {item.UntaxedCurrency}")
                                                cell (Html.text $"{item.Notes}")
                                                cell (
                                                    Html.divc "flex items-center" [
                                                        UI.editBtn
                                                            "Edit salary"
                                                            (fun _ -> item |> StartEditing  |> dispatch)
                                                    ]
                                                )
                                            ]
                                    )
                            )
                        ]
                    ]
                ]
            )
        ]

        Bind.el(state .>> (fun state -> state.PanelState), fun panelState ->
            match panelState with
            | None ->
                Html.none
            | CreatingNew ->
                renderCreateSalaryModal
            | EditingExisting ->
                renderEditSalaryModal
        )
    ]

let create employeeId session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session employeeId
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]