module Modules.Members.Edit

open IMembersApi
open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Modules.Members.Types
open Auth

type private State =
    { Session: ClientSession
      GettingMember: Deferred<UpdateMemberCommand>
      UpdatingMember: CommandExec<UpdateMemberCommand> }

    static member Default session =
        { Session = session
          GettingMember = Deferred.NotStarted
          UpdatingMember = CommandExec.NotStarted }

type private Message =
    | GetUpdateCommand of Query<string, UpdateMemberCommand>
    | SendUpdateCommand of Command<UpdateMemberCommand>
    | SetFirstName of string
    | SetLastName of string
    | SetJoinedDate of string
    | SetEmail of string
    | SetJmbg of string
    | SetPersonalId of string
    | SetAddress of string
    | SetBankAccountNumber of string
    | SetEducation of string
    | SetPosition of string
    | SetNotes of string

let private membersApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IMembersApiRoute.builder
    |> Remoting.buildProxy<IMembersApi>

let private init memberId state = state, Cmd.ofMsg (memberId |> Query.Started |> GetUpdateCommand)

let private update setPage unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match state.GettingMember with
    | Deferred.Resolved memb ->
        match msg with
        | SetFirstName firstName -> {state with GettingMember = Deferred.Resolved { memb with FirstName = firstName }}, Cmd.none
        | SetLastName lastName -> {state with GettingMember = Deferred.Resolved { memb with LastName = lastName }}, Cmd.none
        | SetJoinedDate joinedDate -> {state with GettingMember = Deferred.Resolved { memb with Joined = joinedDate }}, Cmd.none
        | SetEmail email -> {state with GettingMember = Deferred.Resolved { memb with Email = email }}, Cmd.none
        | SetJmbg jmbg -> {state with GettingMember = Deferred.Resolved { memb with Jmbg = jmbg }}, Cmd.none
        | SetPersonalId personalId -> {state with GettingMember = Deferred.Resolved { memb with PersonalId = personalId }}, Cmd.none
        | SetAddress address -> {state with GettingMember = Deferred.Resolved { memb with Address = address }}, Cmd.none
        | SetBankAccountNumber ban -> {state with GettingMember = Deferred.Resolved { memb with BankAccountNumber = ban }}, Cmd.none
        | SetEducation education -> {state with GettingMember = Deferred.Resolved { memb with Education = education }}, Cmd.none
        | SetPosition position -> {state with GettingMember = Deferred.Resolved { memb with Position = position }}, Cmd.none
        | SetNotes notes -> {state with GettingMember = Deferred.Resolved { memb with Notes = notes }}, Cmd.none
        | SendUpdateCommand sur ->
            match sur with
            | Command.Started _ when state.UpdatingMember = CommandExec.InProgress -> state, Cmd.none
            | Command.Started memb ->
                { state with UpdatingMember = CommandExec.InProgress },
                remoteCallCommand
                    membersApi.sendUpdateCommand
                    { SessionId = state.Session.SessionId.Value
                      Content = memb }
                    SendUpdateCommand
            | Command.Completed ->
                { state with UpdatingMember = CommandExec.Completed },
                Cmd.batch [
                    Cmd.successToast "Member updated."
                    Cmd.ofEffect (fun _ -> setPage Home)
                ]
            | Command.Error err ->
                { state with UpdatingMember = CommandExec.NotStarted },
                Cmd.ofErr err unauthorizedRedirPage

        | GetUpdateCommand _ ->
            state, Cmd.none
    | Deferred.NotStarted
    | Deferred.InProgress ->
        match msg with
        | GetUpdateCommand gur ->
            match gur with
            | Query.Started _ when state.GettingMember = Deferred.InProgress -> state, Cmd.none
            | Query.Started memberId ->
                { state with GettingMember = Deferred.InProgress },
                remoteCallQuery
                    membersApi.getUpdateCommand
                    { SessionId = state.Session.SessionId.Value
                      Content = memberId }
                    GetUpdateCommand
            | Query.Completed res ->
                { state with GettingMember = Deferred.Resolved res },
                Cmd.none
            | Query.Error err ->
                { state with GettingMember = Deferred.NotStarted },
                Cmd.ofErr err unauthorizedRedirPage
        | _ ->
            state, Cmd.none

let private view state dispatch setPage =

    let getFieldContent fieldSelector =
        (state .>> fun s ->
            match s.GettingMember with
            | Deferred.Resolved x -> fieldSelector x
            | Deferred.InProgress -> "Loading..."
            | Deferred.NotStarted -> "")

    let renderTextField name fieldSelector onChange =
        UI.renderTextField name (getFieldContent fieldSelector) onChange

    let renderDateField name fieldSelector onChange =
        UI.renderDateField name (getFieldContent fieldSelector) onChange

    let renderTextAreaField rows name fieldSelector onChange =
        UI.renderTextAreaField rows name (getFieldContent fieldSelector) onChange

    let renderTextAreaFieldFullWidth rows name fieldSelector onChange =
            UI.renderTextAreaFieldFullWidth rows name (getFieldContent fieldSelector) onChange

    fragment [
        UI.heading "Edit member"
        Html.divc "w-full bg-white rounded-lg p-6" [
            Html.divc "grid grid-cols-1 sm:grid-cols-2 gap-4" [
                renderTextField "First name" (fun s -> s.FirstName) (SetFirstName >> dispatch)
                renderTextField "Last name" (fun s -> s.LastName) (SetLastName >> dispatch)
                renderTextField "Email" (fun s -> s.Email) (SetEmail >> dispatch)
                renderDateField "Joined on" (fun s -> s.Joined) (SetJoinedDate >> dispatch)
                renderTextField "JMBG" (fun s -> s.Jmbg) (SetJmbg >> dispatch)
                renderTextField "Personal ID" (fun s -> s.PersonalId) (SetPersonalId >> dispatch)
                renderTextField "Bank Account Number" (fun s -> s.BankAccountNumber) (SetBankAccountNumber >> dispatch)
                renderTextField "Education" (fun s -> s.Education) (SetEducation >> dispatch)
                renderTextAreaField 3 "Address" (fun s -> s.Address) (SetAddress >> dispatch)
                renderTextField "Position" (fun s -> s.Position) (SetPosition >> dispatch)
                renderTextAreaFieldFullWidth 8 "Notes" (fun s -> s.Notes) (SetNotes >> dispatch)
            ]
            Html.divc "border-t my-4" []

            Html.divc "flex justify-start" [
                Bind.el (
                    state .>> (fun s -> (Deferred.resolved s.GettingMember) && (CommandExec.notInProgress s.UpdatingMember)),
                    UI.okBtn "Save" (fun _ -> (state |> Store.get).GettingMember |> Deferred.exec (fun c -> c |> Command.Started |> SendUpdateCommand |> dispatch))
                )
                Bind.el (
                    state .>> (fun s -> (Deferred.notInProgress s.GettingMember) && (CommandExec.notInProgress s.UpdatingMember)),
                    UI.cancelBtn "Cancel" (fun _ -> setPage Home)
                )
            ]
        ]
        Bind.el (state .>> (fun s -> s.GettingMember), UI.spinnerDeferred)
    ]

let create memberId session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish (init memberId) (update setPage unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]
