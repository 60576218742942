[<AutoOpen>]
module Util

open Thoth.Json
open Shared.Validation
open Sutil
open System
open Fable.Core
open Fable.Core.JsInterop

[<Literal>]
let browserSessionKey = "BrowserSession"

let loadBrowserSession () : Auth.ClientSession option =
    let decoder =
        Decode.Auto.generateDecoder<Auth.ClientSession> ()

    match LocalStorage.load decoder browserSessionKey with
    | Ok browserSession -> Some browserSession
    | Error _ -> None

let createBrowserSession (session: Auth.ClientSession) =
    LocalStorage.save browserSessionKey session

let deleteBrowserSession () = LocalStorage.delete browserSessionKey

let redirUnauthorized (err : AggregateError) redirPage =
    match (err : AggregateError) with
    | AggregateError appErrors ->
        if List.contains AuthorizationError appErrors then
            Cmd.ofEffect redirPage
        else
            Cmd.none

module Cmd =
    let ofErr (err : AggregateError) unauthorizedRedirPage =
        Cmd.batch [
            Cmd.errorToast err
            redirUnauthorized err unauthorizedRedirPage
        ]

let (|HasValue|_|) (str: string) =
    if not (String.IsNullOrWhiteSpace str) then Some str else None

let (|IsTrue|_|) (value : bool) =
    if value then Some value else None

[<Emit("parseFloat($0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })")>]
let formatMoney (x: decimal): string = jsNative