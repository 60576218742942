[<AutoOpen>]
module ToastrHelper

open Sutil.Toastr
open Shared.Validation
open Sutil

let getMessage appError =
    match appError with
    | ValidationError err -> [ err ]
    | AuthenticationError _ -> [ "Invalid session" ]
    | InvalidSession -> [ "Invalid session" ]
    | AuthorizationError -> [ "You do not have permissions for this operation" ]
    | ServerError err -> [ "Server error" ]

let getMessages (err: AggregateError) =
    let errs =
        match err with
        | AggregateError list -> list

    errs
    |> List.map getMessage
    |> List.concat
    |> List.rev

let applyCommonStyle msg =
    msg
    |> Toastr.position TopRight
    |> Toastr.timeout 3000
    |> Toastr.withProgressBar
    |> Toastr.hideEasing Easing.Swing
    |> Toastr.showCloseButton

let errorToastMsg msg =
    Toastr.message msg
    |> Toastr.title "Error"
    |> applyCommonStyle
    |> Toastr.error

module Cmd =
    let errorToast (err: AggregateError) =
        err
        |> getMessages
        |> List.map errorToastMsg
        |> Cmd.batch

    let successToast message =
        Toastr.message message
        |> Toastr.title "Success"
        |> applyCommonStyle
        |> Toastr.success
