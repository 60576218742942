module Modules.AppAdmin.WaitingList

open Sutil
open Sutil.CoreElements
open Modules.AppAdmin.Types
open Appl
open IApplicationApi
open Shared.Validation
open Auth
open Fable.Remoting.Client
open Sutil.Core

type private State =
    { GettingWaitingListEntries: Deferred<Result<Domain.WaitingListEntry, AggregateError> list>
      SendingInvitation: ParametrizedDeferred<string, Result<unit, AggregateError>>
      Session: ClientSession }
    static member Default session =
        {  GettingWaitingListEntries = Deferred.NotStarted
           SendingInvitation = ParametrizedDeferred.NotStarted
           Session = session }

type private Message =
    | GetWaitingListEntries of EmptyQuery<Result<Domain.WaitingListEntry, AggregateError> list>
    | SendInvitation of Command<string>

let private init (state : State) : State * Cmd<Message> =
    state, Cmd.ofMsg (GetWaitingListEntries EmptyQuery.Started)

let private appApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IApplicationApiRoute.builder
    |> Remoting.buildProxy<IApplicationApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | GetWaitingListEntries gwle ->
        match gwle with
        | EmptyQuery.Started when state.GettingWaitingListEntries = Deferred.InProgress -> state, Cmd.none
        | EmptyQuery.Started ->
            { state with GettingWaitingListEntries = Deferred.InProgress },
            remoteCallEmptyQuery
                appApi.getWaitingListEntries 
                { SessionId = state.Session.SessionId.Value
                  Content = () }
                GetWaitingListEntries
        | EmptyQuery.Completed result ->
            { state with GettingWaitingListEntries = Deferred.Resolved(result) },
            Cmd.none
        | EmptyQuery.Error err ->
            { state with GettingWaitingListEntries = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | SendInvitation inv ->
        match inv with
        | Command.Started id ->
            match state.SendingInvitation with
            | ParametrizedDeferred.InProgress _ -> state, Cmd.none
            | _ ->
                { state with SendingInvitation = ParametrizedDeferred.InProgress id },
                remoteCallCommand
                    appApi.invite
                    { SessionId = state.Session.SessionId.Value
                      Content = { WaitingListId = id } }
                    SendInvitation
        | Command.Completed ->
            { state with SendingInvitation = ParametrizedDeferred.Resolved( Ok () ) },
            Cmd.batch [ 
                Cmd.successToast "Invitation sent!"
                Cmd.ofMsg (EmptyQuery.Started |> GetWaitingListEntries)
            ]
        | Command.Error err ->
            { state with SendingInvitation = ParametrizedDeferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private inviteCell (state: IStore<State>) (entry : Domain.WaitingListEntry) dispatch : SutilElement =
    Html.divc "flex items-center" [
        UI.elementWithTooltip
            "Invite"
            (Bind.el (
                state |> Store.map (fun state -> state.SendingInvitation),
                fun sending -> 
                    Html.a [
                        Attr.className "rounded border border-transparent focus:outline-none focus:border-gray-800 focus:shadow-outline-gray"
                        onClick (fun _ -> SendInvitation (Command.Started entry.Id) |> dispatch) [PreventDefault]
                        Html.divc "p-2 bg-gray-100 hover:bg-gray-200 rounded cursor-pointer text-blue-600" [
                            match sending with
                            | ParametrizedDeferred.InProgress id ->
                                if (id = entry.Id) then
                                    Html.ic "fas fa-spinner fa-spin" []
                                else
                                    Html.ic "fa-solid fa-bolt-lightning" []
                            | _ -> 
                                Html.ic "fa-solid fa-bolt-lightning" []
                        ]
                    ]
            ))
    ]

let private view state dispatch setPage =
    Html.div [

        // https://flowbite.com/docs/components/breadcrumb/#solid-background
        Html.navc "mb-6 flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700" [
            Attr.ariaLabel "Breadcrumb"
            Html.ol [
                Attr.className "inline-flex items-center space-x-1 md:space-x-3"
                Html.lic "inline-flex items-center" [
                    Html.ic "fa-solid fa-house-user mr-1" []
                    Html.a [
                        Attr.className "inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                        Attr.href "#"
                        Attr.text "Home"
                    ]
                ]
                Html.li [
                    Html.divc "flex items-center" [
                        Html.ic "fa-light fa-greater-than" []
                        Html.divc "cursor-pointer ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white" [
                            Attr.text "App Admin"
                            onClick (fun _ -> setPage Home) []
                        ]
                    ]
                ]
                Html.li [
                    Html.divc "flex items-center" [
                        Html.ic "fa-light fa-greater-than" []
                        Html.spanc "ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400" [
                            Attr.text "Waiting List"
                        ]
                    ]
                ]
            ]
        ]

        Html.div [
            UI.heading "Waiting List"
            Bind.el(state .>> (fun s -> s.GettingWaitingListEntries), fun gettingWaitingListEntries ->
                match gettingWaitingListEntries with
                | Deferred.NotStarted
                | Deferred.InProgress -> UI.loader
                | Deferred.Resolved wles ->
                    match wles with
                    | orgs when orgs.IsEmpty ->
                        fragment [
                            Html.pc "mb-6 text-gray-600" [
                                Html.text "Noone is waiting for the invitation"
                            ]
                        ]
                    | _ ->
                        fragment [
                            Html.divc "overflow-x-auto w-full py-5" [
                                Html.tablec "mx-auto w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden" [
                                    Html.theadc "bg-gray-50" [
                                        Html.trc "text-black text-left" [
                                            let cell el = Html.tdc "font-semibold text-sm uppercase px-6 py-4" [ el ]

                                            cell(Html.text "Email")
                                            cell(Html.text "Date")
                                            cell(Html.text "Actions")
                                        ]
                                    ]
                                    Html.tbody [
                                        Attr.className "divide-y divide-gray-200"
                                        fragment (
                                            List.map
                                                (fun wle ->
                                                    match (wle : Result<Domain.WaitingListEntry, AggregateError>) with
                                                        | Ok (entry : Domain.WaitingListEntry) ->
                                                            Html.tr [
                                                                let cell el = Html.tdc "px-6 py-4" [ el ]
                                                                
                                                                cell(Html.text $"{entry.Email.Value}")
                                                                cell(Html.text $"{entry.Created.Value.ToShortDateString()}")
                                                                cell(inviteCell state entry dispatch)
                                                            ]
                                                        | Error (AggregateError x) ->
                                                            Html.tr [
                                                                Html.tdc "whitespace-no-wrap py-4 text-left text-sm text-red-600 sm:px-3 lg:text-left" [
                                                                    Html.text "Error while fetching this organization from the database"
                                                                ]
                                                            ]
                                                )
                                                wles
                                        )
                                    ]
                                ]
                            ]
                        ]
            )
        ]        
    ]

let create session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore 

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]
