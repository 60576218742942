module IOrganizationsApi

open Shared
open Organization
open Shared.Validation

type GetOrganizationRequest = { Id: string }

type UpdateOrganizationCommand =
    { Id: string
      Owner: string
      Name: string
      Address: string
      RegistrationNumber: string
      TaxNumber: string
      Email: string
      Phone: string }

module UpdateOrganizationCommand =
    let toDomain (this: UpdateOrganizationCommand): Result<Domain.Organization, AggregateError> =
        result {
          let! id = OrganizationId.New this.Id
          and! owner = UserId.New this.Owner
          and! name = OrganizationName.New this.Name
          and! address = Address.NewOptional this.Address
          and! regNum = CompanyRegistrationNumber.NewOptional this.RegistrationNumber
          and! taxNum = CompanyTaxNumber.NewOptional this.TaxNumber
          and! email = Email.Parse this.Email
          and! phone = Phone.Parse this.Phone        
          
          return
            Domain.Organization.create
              id
              owner
              name
              address
              regNum
              taxNum
              email
              phone
        }
        |> Result.flatten

type CreateOrganizationCommand =
    { Id: string
      Owner: string
      Name: string
      Address: string
      RegistrationNumber: string
      TaxNumber: string
      Email: string
      Phone: string }

    static member New(orgId) =
      { Id = orgId
        Owner = ""
        Name = ""
        Address = ""
        RegistrationNumber = ""
        TaxNumber = ""
        Email = ""
        Phone = "" }

module CreateOrganizationCommand =
    let toDomain (this: CreateOrganizationCommand): Result<Domain.Organization, AggregateError> =
        result {
          let! id = OrganizationId.New this.Id
          and! owner = UserId.New this.Owner
          and! name = OrganizationName.New this.Name
          and! address = Address.NewOptional this.Address
          and! regNum = CompanyRegistrationNumber.NewOptional this.RegistrationNumber
          and! taxNum = CompanyTaxNumber.NewOptional this.TaxNumber
          and! email = Email.Parse this.Email
          and! phone = Phone.Parse this.Phone        
          
          return
            Domain.Organization.create
              id
              owner
              name
              address
              regNum
              taxNum
              email
              phone
        }
        |> Result.flatten

type GetOrganizationsResponse = Domain.Organization list

type IOrganizationsApi =
    {   getOrganization: SecureRequest<GetOrganizationRequest> -> Async<Result<Domain.Organization, AggregateError>>
        getOrganizations: SecureRequest<unit> -> Async<Result<GetOrganizationsResponse, AggregateError>>
        createOrganization: SecureRequest<CreateOrganizationCommand> -> Async<Result<unit, AggregateError>>
        updateOrganization: SecureRequest<UpdateOrganizationCommand> -> Async<Result<unit, AggregateError>>
        getUpdateRequest: SecureRequest<string> -> Async<Result<UpdateOrganizationCommand, AggregateError>>
        sendUpdateRequest: SecureRequest<UpdateOrganizationCommand> -> Async<Result<unit, AggregateError>>
        }

module IOrganizationsApiRoute =
    let builder typeName methodName = $"/api/{typeName}/{methodName}"

