module ISalariesApi

open Shared
open Salary
open Shared.Validation
open System
open Ulid

type OrganizationSalariesRequest =
    { OrganizationId: string
      SortBy: SortableColumn
      SortDirection: SortDirection }

type SalaryOverview =
    {
        EmployeeId: string
        FirstName: string
        LastName: string
        TaxedAmount: decimal
        TaxedCurrency: string
        UntaxedAmount: decimal
        UntaxedCurrency: string }
type SalaryStats =
    {
        Taxed: decimal
        Untaxed: decimal
        Total: decimal
        Currency: string }

type OrganizationSalariesResponse =
    {
        Salaries: SalaryOverview list
        Stats: SalaryStats list
    }

type EmployeeSalariesRequest = { EmployeeId: string }
type EmployeeSalariesResponse =
    {
        EmployeeName: string
        Salaries: EmployeeSalaryHistoryEntry list }
and
    EmployeeSalaryHistoryEntry =
    {
        Id: string
        EffectiveDate: string
        TaxedAmount: decimal
        TaxedCurrency: string
        UntaxedAmount: decimal
        UntaxedCurrency: string
        Notes: string }

type SalaryCommand =
    { Id: string
      EmployeeId: string
      TaxedAmount: decimal
      TaxedCurrency: string
      UntaxedAmount: decimal
      UntaxedCurrency: string
      EffectiveDate: string
      Notes: string }

    static member New(employeeId) =
        { Id = Ulid.New.ToString()
          EmployeeId = employeeId
          TaxedAmount = 0m
          TaxedCurrency =  "RSD"
          UntaxedAmount = 0m
          UntaxedCurrency =  "RSD"
          EffectiveDate = DateTime.Today |> toISO
          Notes = "" }

module SalaryCommand =
    open Employee
    let toDomain (rawSalary : SalaryCommand) : Result<Domain.Salary, AggregateError> =
        result {
            let! id = SalaryId.New rawSalary.Id
            and! employeeId = EmployeeId.New rawSalary.EmployeeId
            and! taxedPart = Amount.New rawSalary.TaxedAmount rawSalary.TaxedCurrency
            and! untaxedPart = Amount.New rawSalary.UntaxedAmount rawSalary.UntaxedCurrency
            and! date = Date.New rawSalary.EffectiveDate

            return
                { Id = id
                  EmployeeId = employeeId
                  TaxedPart = taxedPart
                  UntaxedPart = untaxedPart
                  EffectiveDate = date
                  Notes = rawSalary.Notes }
        }

    let fromDomain (this: Domain.Salary) : SalaryCommand =
        { Id = this.Id.Value
          EmployeeId = this.EmployeeId.Value
          TaxedAmount = this.TaxedPart.Value.Value
          TaxedCurrency = this.TaxedPart.Currency.ToString()
          UntaxedAmount = this.UntaxedPart.Value.Value
          UntaxedCurrency = this.UntaxedPart.Currency.ToString()
          EffectiveDate = this.EffectiveDate.Value |> toISO
          Notes = this.Notes }

type ISalariesApi =
    { getOrganizationSalaries: SecureRequest<OrganizationSalariesRequest> -> Async<Result<OrganizationSalariesResponse, AggregateError>>
      getEmployeeSalaries: SecureRequest<EmployeeSalariesRequest> -> Async<Result<EmployeeSalariesResponse, AggregateError>>
      saveSalary: SecureRequest<SalaryCommand> -> Async<Result<unit, AggregateError>> }

module ISalariesApiRoute =
    let builder typeName methodName = $"/api/{typeName}/{methodName}"

