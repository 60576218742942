module IInventoriesApi

open Shared
open Shared.Validation
open Inventory

type GetOrgInventoriesRequest = { OrganizationId: string }
type GetOrgInventoriesResponse = OrgInventory list

and OrgInventory =
    { Id: string
      Name: string
      Notes: string }

type InventoryCommand =
    { Id: string
      OrganizationId: string
      Name: string
      Notes: string }

module InventoryCommand =
    open Organization
    let empty inventoryId organizationId =
        { Id = inventoryId
          OrganizationId = organizationId
          Name = ""
          Notes = "" }

    let toDomain (command : InventoryCommand) : Result<Domain.Inventory, AggregateError> =
        result {
            let! id = InventoryId.New command.Id
            and! organizationId = OrganizationId.New command.OrganizationId
            and! name = Name.New command.Name

            return
                { Id = id
                  OrganizationId = organizationId
                  Name = name
                  Notes = command.Notes }
        }

type IInventoriesApi =
    { getOrgInventories: SecureRequest<GetOrgInventoriesRequest> -> Async<Result<GetOrgInventoriesResponse, AggregateError>>
      createInventory: SecureRequest<InventoryCommand> -> Async<Result<unit, AggregateError>>
      updateInventory: SecureRequest<InventoryCommand> -> Async<Result<unit, AggregateError>> }

module IInventoriesApiRoute =
    let builder typeName methodName = $"/api/{typeName}/{methodName}"
