module Modules.Members.Home

open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Auth
open Modules.Members.Types
open Member
open Shared.Validation
open IMembersApi
open Organization
open Shared

type private State =
    { GettingMembers: Deferred<GetOrgMembersResponse>
      Session: ClientSession }
      static member Default session =
        {  GettingMembers = Deferred.NotStarted
           Session = session }

type private Message =
    | GetMembers of Query<string, GetOrgMembersResponse>

let private init state =
    state, Cmd.ofMsg ( state.Session.OrgInfo.Id |> Query.Started |> GetMembers )

let private membersApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IMembersApiRoute.builder
    |> Remoting.buildProxy<IMembersApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | GetMembers fo ->
        match fo with
        | Query.Started _ when state.GettingMembers = Deferred.InProgress -> state, Cmd.none
        | Query.Started orgId ->
            { state with GettingMembers = Deferred.InProgress },
            remoteCallQuery
                membersApi.getOrgMembers
                { SessionId = state.Session.SessionId.Value
                  Content = { OrganizationId = orgId } }
                GetMembers
        | Query.Completed result ->
            { state with GettingMembers = Deferred.Resolved(result) },
            Cmd.none
        | Query.Error err ->
            { state with GettingMembers = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private view (state: IStore<State>) dispatch setPage =
    Html.div [
        UI.heading "Members"

        Html.divc "flex w-full flex-col justify-start space-y-2 sm:flex-row sm:space-y-0" [
            Html.buttonc "focus:ring outline-none rounded-lg text-white bg-blue-600 px-8 py-2 font-bold active:scale-95 hover:opacity-90" [
                Html.text "Create New"
                onClick (fun _ -> setPage Create) []
            ]
        ]

        Bind.el(
            state .>> (fun s -> s.GettingMembers), fun gettingMembers ->
            match gettingMembers with
            | Deferred.NotStarted
            | Deferred.InProgress -> UI.loader
            | Deferred.Resolved members ->
                Html.divc "overflow-x-auto w-full py-5" [
                    Html.tablec "mx-auto w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden" [
                        Html.theadc "bg-gray-50" [
                            Html.trc "text-black text-left" [
                                let cell el = Html.tdc "font-semibold text-sm uppercase px-6 py-4" [ el ]

                                cell (Html.text "First Name")
                                cell (Html.text "Last Name")
                                cell (Html.text "Email")
                                cell (Html.text "Joined on")
                                cell (Html.text "Actions")
                            ]
                        ]
                        Html.tbody [
                            Attr.className "divide-y divide-gray-200"

                            fragment
                                (List.map
                                    (fun (memb : Domain.Member) ->
                                        Html.tr [
                                            let cell el = Html.tdc "px-6 py-4" [ el ]

                                            cell (Html.text $"{memb.FirstName.Value}")
                                            cell (Html.text $"{memb.LastName.Value}")
                                            cell (
                                                match memb.Email with
                                                | None -> Html.none
                                                | Some email -> Html.text $"{email.Value}"                                                        
                                            )
                                            cell (Html.text $"{memb.Joined.Value |> toISO}")
                                            cell (
                                                Html.divc "flex items-center" [
                                                    UI.editBtn "Edit member" (fun _ -> setPage (Edit memb.Id.Value))
                                                ]                                                        
                                            )
                                        ]
                                    )
                                    members
                                )
                        ]
                    ]
                ]
            )
    ]

let create session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]

