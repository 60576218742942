module Modules.Organizations.Default

open Elmish.UrlParser
open Modules.Organizations.Types

let parser =
    oneOf [
        map Home (s "home")
        map Create (s "create")
        map Edit (s "edit" </> str)
    ]

let getPage url : Page =
    match parseUrl parser url with
    | Some p -> p
    | None -> Home

let create page session setPage setOrg (unauthorizedRedirection : obj -> unit) =
    match page with
    | Home -> Home.create session setPage setOrg unauthorizedRedirection
    | Create ->  Create.create session setPage setOrg unauthorizedRedirection
    | Edit orgId -> Edit.create orgId session setPage setOrg unauthorizedRedirection