module IAuthApi

open Shared.Validation
open Auth

type LogoutRequest = { SessionId: string }
type MagicLinkRequest = { Email: string }
type ChallengeRequest = { Challenge: string }

type IAuthApi =
    { logout: LogoutRequest -> Async<unit>
      sendMagicLink: MagicLinkRequest -> Async<Result<unit, AggregateError>>
      challenge: ChallengeRequest -> Async<Result<ClientSession, AggregateError>> }

module IAuthApiRoute =
    let builder typeName methodName = $"/api/{typeName}/{methodName}"
