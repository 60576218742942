module Modules.Organizations.Home

open Organization
open IOrganizationsApi
open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Modules.Organizations.Types
open Auth
open Shared.Validation

type private State =
    { GettingOrganizations: Deferred<GetOrganizationsResponse>
      Session: ClientSession }
    static member Default session =
        {  GettingOrganizations = Deferred.NotStarted
           Session = session }

type private Message =
    | GetOrganizations of EmptyQuery<GetOrganizationsResponse>

let private init state =
    state, Cmd.ofMsg (GetOrganizations EmptyQuery.Started)

let private organizationsApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IOrganizationsApiRoute.builder
    |> Remoting.buildProxy<IOrganizationsApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | GetOrganizations fo ->
        match fo with
        | EmptyQuery.Started when state.GettingOrganizations = Deferred.InProgress -> state, Cmd.none
        | EmptyQuery.Started ->
            { state with GettingOrganizations = Deferred.InProgress },
            remoteCallEmptyQuery
                organizationsApi.getOrganizations
                { SessionId = state.Session.SessionId.Value
                  Content = () }
                GetOrganizations
        | EmptyQuery.Completed result ->
            { state with GettingOrganizations = Deferred.Resolved(result) },
            Cmd.none
        | EmptyQuery.Error err ->
            { state with GettingOrganizations = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private view (state: IStore<State>) dispatch setPage setOrg =
    Html.div [
        UI.heading "Organizations"
        Bind.el(state .>> (fun s -> s.GettingOrganizations), fun gettingOrganizations ->
            match gettingOrganizations with
            | Deferred.NotStarted
            | Deferred.InProgress -> UI.loader
            | Deferred.Resolved orgs ->
                match orgs with
                | orgs when orgs.IsEmpty ->
                    fragment [
                        Html.pc "mb-6 text-gray-600" [
                            Html.text "You don't have any organization. Please create one."
                        ]
                        Html.divc "flex w-full flex-col justify-start space-y-2 sm:flex-row sm:space-y-0" [
                            Html.buttonc "focus:ring outline-none rounded-lg text-white bg-blue-600 px-8 py-2 font-bold active:scale-95 hover:opacity-90" [
                                Html.text "Create New"
                                onClick (fun _ -> setPage Create) []
                            ]
                        ]
                    ]
                | _ ->
                    fragment [
                        Html.divc "flex w-full flex-col justify-start space-y-2 sm:flex-row sm:space-y-0" [
                            Html.buttonc "focus:ring outline-none rounded-lg text-white bg-blue-600 px-8 py-2 font-bold active:scale-95 hover:opacity-90" [
                                Html.text "Create New"
                                onClick (fun _ -> setPage Create) []
                            ]
                        ]
                        Html.divc "overflow-x-auto w-full py-5" [
                            Html.tablec "mx-auto w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden" [
                                Html.theadc "bg-gray-50" [
                                    Html.trc "text-black text-left" [
                                        let cell el = Html.tdc "font-semibold text-sm uppercase px-6 py-4" [ el ]

                                        cell(Html.text "Name")
                                        cell(Html.text "Actions")
                                    ]
                                ]
                                Html.tbody [
                                    Attr.className "divide-y divide-gray-200"
                                    fragment (
                                        List.map
                                            (fun (org: Domain.Organization) ->
                                                Html.tr [
                                                    let cell el =  Html.tdc "px-6 py-4" [ el ]

                                                    cell(Html.text $"{org.Name.Value}")
                                                    cell(
                                                        Html.divc "flex items-center" [
                                                            UI.editBtn "Edit organization" (fun _ -> setPage (Edit org.Id.Value))

                                                            Bind.el (state .>> (fun x -> x.Session.OrgInfo.Id = org.Id.Value),
                                                                fun isCurrent ->
                                                                    match isCurrent with
                                                                    | true -> Html.none
                                                                    | false ->
                                                                        UI.switchBtn
                                                                            "Switch to this organization"
                                                                            (fun _ -> setOrg { Id = org.Id.Value; Name = org.Name.Value })
                                                            )
                                                        ]
                                                    )
                                                ]
                                            )
                                            orgs
                                    )
                                ]
                            ]
                        ]
                    ]
        )
    ]

let create session setPage setOrg (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage setOrg
    ]