module Modules.Absences.Default

open Elmish.UrlParser
open Modules.Absences.Types

let parser =
    oneOf [
        map Home (s "home")
    ]

let getPage url : Page =
    match parseUrl parser url with
    | Some p -> p
    | None -> Home

let create page session setPage (unauthorizedRedirection : obj -> unit) =
    match page with
    | Home -> Home.create session setPage unauthorizedRedirection