module Modules.AppAdmin.Feedback

open Sutil
open Sutil.CoreElements
open Modules.AppAdmin.Types
open IApplicationApi
open Shared.Validation
open Auth
open Fable.Remoting.Client

type private State =
    { GettingFeedbackEntries: Deferred<FeedbackEntryView list>
      Session: ClientSession }
    static member Default session =
        {  GettingFeedbackEntries = Deferred.NotStarted
           Session = session }

type private Message =
    | GetFeedbackEntries of EmptyQuery<FeedbackEntryView list>

let private init state =
    state, Cmd.ofMsg (GetFeedbackEntries EmptyQuery.Started)

let private appApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IApplicationApiRoute.builder
    |> Remoting.buildProxy<IApplicationApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | GetFeedbackEntries gwle ->
        match gwle with
        | EmptyQuery.Started when state.GettingFeedbackEntries = Deferred.InProgress -> state, Cmd.none
        | EmptyQuery.Started ->
            { state with GettingFeedbackEntries = Deferred.InProgress },
            remoteCallEmptyQuery
                appApi.getFeedbackEntries
                { SessionId = state.Session.SessionId.Value
                  Content = () }
                GetFeedbackEntries
        | EmptyQuery.Completed result ->
            { state with GettingFeedbackEntries = Deferred.Resolved(result) },
            Cmd.none
        | EmptyQuery.Error err ->
            { state with GettingFeedbackEntries = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private view state dispatch setPage =
    Html.div [

        // https://flowbite.com/docs/components/breadcrumb/#solid-background
        Html.navc "mb-6 flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50" [
            Attr.ariaLabel "Breadcrumb"
            Html.ol [
                Attr.className "inline-flex items-center space-x-1 md:space-x-3"
                Html.lic "inline-flex items-center" [
                    Html.ic "fa-solid fa-house-user mr-1" []
                    Html.a [
                        Attr.className "inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600"
                        Attr.href "#"
                        Attr.text "Home"
                    ]
                ]
                Html.li [
                    Html.divc "flex items-center" [
                        Html.ic "fa-light fa-greater-than" []
                        Html.divc "cursor-pointer ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2" [
                            Attr.text "App Admin"
                            onClick (fun _ -> setPage Home) []
                        ]
                    ]
                ]
                Html.li [
                    Html.divc "flex items-center" [
                        Html.ic "fa-light fa-greater-than" []
                        Html.spanc "ml-1 text-sm font-medium text-gray-500 md:ml-2" [
                            Attr.text "Feedback"
                        ]
                    ]
                ]
            ]
        ]

        Html.div [
            UI.heading "Feedback"
            Bind.el(state .>> (fun s -> s.GettingFeedbackEntries), fun gettingFeedbackEntries ->
                match gettingFeedbackEntries with
                | Deferred.NotStarted
                | Deferred.InProgress -> UI.loader
                | Deferred.Resolved fe ->
                    match fe with
                    | fes when fes.IsEmpty ->
                        fragment [
                            Html.pc "mb-6 text-gray-600" [
                                Html.text "No feedback at the moment"
                            ]
                        ]
                    | fes ->
                        fragment [
                            Html.divc "overflow-x-auto w-full py-5" [
                                Html.tablec "mx-auto w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden" [
                                    Html.theadc "bg-gray-50" [
                                        Html.trc "text-black text-left" [
                                            let cell el =
                                                Html.td [
                                                    Attr.className "font-semibold text-sm uppercase px-6 py-4"
                                                    el
                                                ]

                                            cell(Html.text "User")
                                            cell(Html.text "Message")
                                        ]
                                    ]
                                    Html.tbody [
                                        Attr.className "divide-y divide-gray-200"
                                        fragment (
                                            fes
                                            |> List.map
                                                (fun (entry: FeedbackEntryView) ->
                                                    Html.tr [

                                                        let cell el =
                                                            Html.tdc "px-6 py-4" [
                                                                el
                                                            ]

                                                        cell(Html.text $"{entry.Email}")
                                                        cell(Html.text $"{entry.Message}")
                                                    ]
                                                )
                                        )
                                    ]
                                ]
                            ]
                        ]
            )
        ]
    ]

let create session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]
