module IEmployeesApi

open Member
open Shared
open Employee
open Shared.Validation
open Organization
open File

type GetOrgEmployeesRequest = { OrganizationId: string }

type GetOrgEmployeesResponse = OrgEmployee list

and OrgEmployee =
    { Id: string
      FirstName: string
      LastName: string
      Email: string
      Joined: string }

        static member empty =
            { Id = ""
              FirstName =  ""
              LastName= ""
              Email = ""
              Joined = "" }

type CreateEmployeeCommand =
    { Id: string
      OrganizationId: string
      FirstName: string
      LastName: string
      Jmbg: string
      PersonalId: string
      Address: string
      Joined: string
      BankAccountNumber: string
      Education: string
      Position: string
      Notes: string
      Email: string
      Files: FileMeta list }

module CreateEmployeeCommand =
    let empty employeeId organizationId =
        { Id = employeeId
          OrganizationId = organizationId
          FirstName = ""
          LastName = ""
          Jmbg = ""
          PersonalId = ""
          Address = ""
          Joined = ""
          BankAccountNumber = ""
          Education = ""
          Position = ""
          Notes = ""
          Email = ""
          Files = List.empty }

    let toDomains (r: CreateEmployeeCommand) : Result<Domain.Employee, AggregateError> * Domain.File list =
        let validFiles =
            r.Files
            |> List.map (fun (meta: FileMeta) -> Domain.File.from meta)
            |> List.filter (function
                | Ok _ -> true
                | _ -> false)
            |> List.map (function
                | Ok file -> file
                | _ -> failwith "unreachable")

        let employee =
            result {
                let! id = EmployeeId.New r.Id
                and! organizationId = OrganizationId.New r.OrganizationId
                and! firstName = FirstName.New r.FirstName
                and! lastName = LastName.New r.LastName
                and! jmbg = Jmbg.NewOptional r.Jmbg
                and! personalId = PersonalId.NewOptional r.PersonalId
                and! address = Address.NewOptional r.Address
                and! joined = Date.New r.Joined
                and! bankAccountNumber = BankAccountNumber.NewOptional r.BankAccountNumber
                and! email = Email.Parse r.Email

                return
                    Domain.Employee.Create
                        id
                        organizationId
                        firstName
                        lastName
                        jmbg
                        personalId
                        address
                        joined
                        bankAccountNumber
                        r.Education
                        r.Position
                        r.Notes
                        email
                        (validFiles |> List.map (fun x -> x.Id))
            }
            |> Result.flatten

        employee, validFiles

type UpdateEmployeeCommand =
    { EmployeeId: string
      OrganizationId: string
      FirstName: string
      LastName: string
      Jmbg: string
      PersonalId: string
      Address: string
      Joined: string
      BankAccountNumber: string
      Education: string
      Position: string
      Notes: string
      Email: string
      Files: FileMeta list
      AddFiles: FileMeta list
      RemoveFiles: string list }

module UpdateEmployeeCommand =
    let empty employeeId : UpdateEmployeeCommand =
        { EmployeeId = employeeId
          OrganizationId = ""
          FirstName = ""
          LastName = ""
          Jmbg = ""
          PersonalId = ""
          Address = ""
          Joined = ""
          BankAccountNumber = ""
          Education = ""
          Position = ""
          Notes = ""
          Email = ""
          Files = List.Empty // todo
          AddFiles = List.empty
          RemoveFiles = List.Empty }

    let toDomain (r: UpdateEmployeeCommand) : Result<Domain.Employee, AggregateError> =
        result {
            let! id = EmployeeId.New r.EmployeeId
            and! organizationId = OrganizationId.New r.OrganizationId
            and! firstName = FirstName.New r.FirstName
            and! lastName = LastName.New r.LastName
            and! jmbg = Jmbg.NewOptional r.Jmbg
            and! personalId = PersonalId.NewOptional r.PersonalId
            and! address = Address.NewOptional r.Address
            and! joined = Date.New r.Joined
            and! bankAccountNumber = BankAccountNumber.NewOptional r.BankAccountNumber
            and! email = Email.Parse r.Email

            return
                Domain.Employee.Create
                    id
                    organizationId
                    firstName
                    lastName
                    jmbg
                    personalId
                    address
                    joined
                    bankAccountNumber
                    r.Education
                    r.Position
                    r.Notes
                    email
                    List.empty // todo: (r.Files |> List.filter (fun file -> file.FileId <> "") |> List.map (fun meta -> Domain.File.from meta))
        }
        |> Result.flatten

type TerminateEmployeeCommand = {
    EmployeeId: string }

type GetFileRequest = {
    EmployeeId: string
    FileId: string
    FileName: string
     }

type GetFileResponse = {
    FileName: string
    FileContent: byte[]
    }

type IEmployeesApi =
    { getOrgEmployees: SecureRequest<GetOrgEmployeesRequest> -> Async<Result<GetOrgEmployeesResponse, AggregateError>>
      createEmployee: SecureRequest<CreateEmployeeCommand> -> Async<Result<unit, AggregateError>>
      getUpdateCommand: SecureRequest<string> -> Async<Result<UpdateEmployeeCommand, AggregateError>>
      sendUpdateCommand: SecureRequest<UpdateEmployeeCommand> -> Async<Result<unit, AggregateError>>
      sendTerminateCommand: SecureRequest<TerminateEmployeeCommand> -> Async<Result<unit, AggregateError>>
      getFileRequest: SecureRequest<GetFileRequest> -> Async<Result<GetFileResponse, AggregateError>> }

module IEmployeesApiRoute =
    let builder typeName methodName = $"/api/{typeName}/{methodName}"
