module Employees.UI
    open Sutil
    open Sutil.CoreElements
    open Browser.Types

    let renderPreview (mimeType: string) (content: byte array) =
        match mimeType with
            | "image/jpeg"
            | "image/png"
            | "image/gif" ->
                match content.Length > 0 with
                | true ->
                    Html.imgc "focus:outline-none w-24 h-24 rounded-lg" [
                        Attr.src $"data:{mimeType};base64,{System.Convert.ToBase64String(content)}"
                    ]
                | false ->
                    Html.ic "fa-regular fa-file-image focus:outline-none w-24 h-24 rounded-lg" []
            | "application/pdf"
            | "application/vnd.ms-excel"
            | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            | "application/msword"
            | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            | "application/vnd.ms-powerpoint"
            | "application/vnd.openxmlformats-officedocument.presentationml.presentation"
            | "application/gzip"
            | "application/zip"
            | "application/x-7z-compressed"
            | "application/x-zip-compressed"
            | "text/csv" ->
                let icon =
                    match mimeType with
                    | "application/vnd.ms-excel"
                    | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        -> "excel"
                    | "application/msword"
                    | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        -> "word"
                    | "application/vnd.ms-powerpoint"
                    | "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                        -> "powerpoint"
                    | "application/gzip"
                    | "application/zip"
                    | "application/x-7z-compressed"
                    | "application/x-zip-compressed"
                        -> "zipper"
                    | _
                        -> (mimeType.Split('/'))[1]

                Html.ic $"fa-solid fa-file-{icon} focus:outline-none w-24 h-24 rounded-lg" []
            | _ ->
                Html.ic "fa-regular fa-file focus:outline-none w-24 h-24 rounded-lg" []

    let removeFileBtn onClickFn =
        Html.buttonc "absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 rounded-full bg-gray-100 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-4 h-4absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 rounded-full bg-gray-100 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-4 h-4" [
            Html.ic "fa-solid fa-xmark fa-xs text-gray-500" []
            onClick onClickFn []
        ]

    let renderFileName fileName =
        UI.elementWithTooltip
            fileName
            (Html.divc "truncate w-24 mt-1 text-center text-xs" [
                Attr.text $"{fileName}"
            ])

    let uploadFileArea (fileList : IStore<FileList>) =
        fragment [
            Html.labelc "focus:outline-none w-24 h-24 rounded-lg mr-3 flex items-center justify-center bg-gray-200" [
                Attr.for' "upload-file"
                Attr.role "button"
                Html.ic "fa-solid fa-plus" []
            ]
            Html.inputc "hidden" [
                Bind.attr("files", fileList)
                Attr.typeFile
                Attr.multiple true
                Attr.id "upload-file"
            ]
        ]