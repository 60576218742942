module Modules.AppDocs.About

open Sutil
open Sutil.CoreElements
open Modules.AppDocs.Types

type private State =
    { Message: string }
    static member Default = { Message = "" }

type private Msg = | Init

let private init details = details, Cmd.none

let private update msg state = state, Cmd.none

let private view state dispatch setPage =
    Html.div [
        Html.h1 "About page"
        Html.br []
        Html.p [
            text "this is About page content"
        ]
        Html.pc "cursor-pointer underline" [
            text "Team"
            onClick (fun _ -> setPage Team) []
        ]
    ]

let create setPage =

    let state, dispatch =
        State.Default
        |> Store.makeElmish init update ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]
