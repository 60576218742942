[<AutoOpen>]
module Extensions

[<RequireQualifiedAccess>]
type Deferred<'res> =
    | NotStarted
    | InProgress
    | Resolved of 'res

[<RequireQualifiedAccess>]
type ParametrizedDeferred<'arg, 'res> =
    | NotStarted
    | InProgress of 'arg
    | Resolved of 'res

[<RequireQualifiedAccess>]
type CommandExec<'arg> =
    | NotStarted
    | InProgress
    | Completed

[<RequireQualifiedAccess>]
module CommandExec =
    let notInProgress (deferredCall : CommandExec<'a>) : bool =
        match deferredCall with
        | CommandExec.NotStarted -> true
        | CommandExec.InProgress -> false
        | CommandExec.Completed _ -> true

    let (|NotInProgress|_|) (exec : CommandExec<'a>) =
        match exec with
        | CommandExec.InProgress -> None
        | _ -> Some exec

[<RequireQualifiedAccess>]
module CommandState =
    let notInProgress (commandState : CommandExec<'a>) : bool =
        match commandState with
        | CommandExec.NotStarted -> true
        | CommandExec.InProgress -> false
        | CommandExec.Completed _ -> true

[<RequireQualifiedAccess>]
module Deferred =

    let resolved deferred : bool =
        match deferred with
        | Deferred.NotStarted -> false
        | Deferred.InProgress -> false
        | Deferred.Resolved _ -> true

    let notInProgress deferred : bool =
        match deferred with
        | Deferred.NotStarted -> true
        | Deferred.InProgress -> false
        | Deferred.Resolved _ -> true

    let exec (action : 'arg -> unit) (arg : Deferred<'arg>) =
        match arg with
        | Deferred.NotStarted -> ()
        | Deferred.InProgress -> ()
        | Deferred.Resolved value -> value |> action

open Shared.Validation

[<RequireQualifiedAccess>]
type Query<'arg, 'res> =
    | Started of 'arg
    | Completed of 'res
    | Error of AggregateError

[<RequireQualifiedAccess>]
type EmptyQuery<'res> =
    | Started
    | Completed of 'res
    | Error of AggregateError

[<RequireQualifiedAccess>]
type Command<'arg> =
    | Started of 'arg
    | Completed
    | Error of AggregateError

let remoteCallCommand remoteFunc args command =
    Sutil.Cmd.OfAsync.either
        remoteFunc
        args
        (fun res ->
            match res with
            | Ok _ -> command (Command.Completed)
            | Error err -> command (Command.Error err))
        (fun exn -> command (Command.Error([ ServerError exn.Message ] |> AggregateError)))

let remoteCallEmptyQuery (remoteFunc : 'a -> Async<Result<'b, AggregateError>>) args (query) =
    Sutil.Cmd.OfAsync.either
        remoteFunc
        args
        (fun res ->
            match res with
            | Ok (okRes : 'b) -> okRes |> EmptyQuery.Completed |> query
            | Error (err :  AggregateError ) -> err |> EmptyQuery.Error |> query)
        (fun exn -> [ exn.Message |> ServerError ] |> AggregateError |> EmptyQuery.Error |> query)

let remoteCallQuery (remoteFunc : 'a -> Async<Result<'b, AggregateError>>) args query =
    Sutil.Cmd.OfAsync.either
        remoteFunc
        args
        (fun res ->
            match res with
            | Ok okRes -> okRes |> Query.Completed |> query
            | Error err -> err |> Query.Error |> query)
        (fun exn -> [ exn.Message |> ServerError ] |> AggregateError |> Query.Error |> query)
