module App.View

open Sutil
open Sutil.Core
open Sutil.CoreElements
open App.Router
open Auth

let viewPage (session : option<ClientSession>) dispatch (page : Page) =
    let unauthorizedRedirection = dispatch << SetPage << (fun _ -> AuthHomePage)

    match page with
    | AnonHomePage ->
        Modules.Home.AnonHome.create
    | AuthHomePage ->
        match session with
        | None ->
            Modules.Home.AnonHome.create
        | Some s ->
            let setCreateOrgPage = dispatch << SetPage << (fun _ -> OrganizationsPage Modules.Organizations.Types.Page.Create)
            let setCreateEmployeePage = dispatch << SetPage << (fun _ -> EmployeePage Modules.Employees.Types.Page.Create)
            Modules.Home.AuthHome.create s setCreateOrgPage setCreateEmployeePage unauthorizedRedirection
    | AppDocsPage p ->
        match session with
        | None -> Modules.Home.AnonHome.create
        | Some s ->
            let setPage = dispatch << SetPage << AppDocsPage
            let setAppHomePage = dispatch << SetPage << (fun _ -> AuthHomePage)
            Modules.AppDocs.Defaults.create p s setPage setAppHomePage
    | AuthPage p ->
        Modules.Auth.Default.create p (dispatch << StartSession)
    | OrganizationsPage p ->
        match session with
        | None -> Modules.Home.AnonHome.create
        | Some s ->
            let setPage = dispatch << SetPage << OrganizationsPage
            let setOrg = dispatch << SetOrg
            Modules.Organizations.Default.create p s setPage setOrg unauthorizedRedirection
    | MembersPage p ->
        match session with
        | None -> Modules.Home.AnonHome.create
        | Some s ->
            let setPage = dispatch << SetPage << MembersPage
            Modules.Members.Default.create p s setPage unauthorizedRedirection
    | EmployeePage p ->
        match session with
        | None -> Modules.Home.AnonHome.create
        | Some s ->
            let setPage = dispatch << SetPage << EmployeePage
            Modules.Employees.Default.create p s setPage unauthorizedRedirection
    | SalariesPage p ->
        match session with
        | None -> Modules.Home.AnonHome.create
        | Some s ->
            let setPage = dispatch << SetPage << SalariesPage
            Modules.Salaries.Default.create p s setPage unauthorizedRedirection
    | InventoriesPage p ->
        match session with
        | None -> Modules.Home.AnonHome.create
        | Some s ->
            let setPage = dispatch << SetPage << InventoriesPage
            Modules.Inventories.Default.create p s setPage unauthorizedRedirection
    | AppAdminPage p ->
        match session with
        | None -> Modules.Home.AnonHome.create
        | Some s ->
            let setPage = dispatch << SetPage << AppAdminPage
            Modules.AppAdmin.Default.create p s dispatch setPage unauthorizedRedirection
    | AbsencesPage p ->
        match session with
        | None -> Modules.Home.AnonHome.create
        | Some s ->
            let setPage = dispatch << SetPage << AbsencesPage
            Modules.Absences.Default.create p s setPage unauthorizedRedirection

let menu (page : Page) (session : ClientSession option) dispatch =
    let menuItem (selected : bool) (text : string) clickAction (icon : string) =
        let selectedClass = if selected then "font-semibold" else "text-gray-300"

        Html.li [
            Html.buttonc $"focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-8 py-2 focus:outline-none {selectedClass}" [
                onClick clickAction []
                Html.spanc "text-2xl" [
                    Html.ic icon []
                ]
                Html.span [
                    Attr.text text
                ]
            ]
            // todo: check if smaller menu items are better
            (*Html.ac "relative flex flex-row items-center h-11 focus:outline-none focus:bg-slate-600 hover:bg-slate-600focus:bg-slate-600 hover:bg-slate-600 text-gray-300" [
                Attr.href "#"
                Html.spanc "inline-flex justify-center items-center ml-4" [
                    Html.ic icon []
                ]
                Html.spanc "ml-2 text-sm tracking-wide truncate" [
                    Attr.text text
                ]
            ]*)
        ]

    let getClaims (session : ClientSession option) : Claim list =
            match session with
            | None -> []
            | Some s -> s.UserClaims

    let hasCLaim (claim: Claim) (claims : Claim list) : bool =
        claims
        |> List.exists (fun userClaim -> userClaim ==> claim)

    let hasRequiredClaim (session : ClientSession option) (requiredClaim: Claim) : bool =
        session
        |> getClaims
        |> hasCLaim requiredClaim

    let authorizedMenuItem (selected : bool) (requiredClaim: Claim) (text : string) clickAction (icon : string) =
        if hasRequiredClaim session requiredClaim then
            menuItem selected text clickAction icon
        else
            Html.none

    let menuSection (text : string) =
        Html.lic "px-5" [
            Html.divc "flex flex-row items-center h-8" [
                Html.divc "px-3 text-xs text-gray-300 uppercase" [
                    Html.text text
                ]
            ]
        ]

    let currentOrg =
        match session with
        | Some s -> s.OrgInfo.Id
        | None -> ""

    let authorizedFragment claim (content : SutilElement list) =
        if hasRequiredClaim session claim then
            fragment content
        else
            Html.none

    //Html.ulc "flex flex-col py-4 space-y-1" [
    Html.ulc "mt-8 space-y-3 md:mt-10" [
        menuSection "General"
        menuItem
            (Route.get page = Auth "")
            "Home" (fun _ -> SetPage AuthHomePage |> dispatch) "fa-solid fa-house fa-sm"
        //menuItem "About" (fun _ -> SetPage (AboutPage Modules.About.Types.About) |> dispatch) "fa-solid fa-wallet fa-sm"
        authorizedMenuItem
            (Route.get page = Organizations "")
            { Claim = "organizations"; Resource = "" }
            "Organizations" (fun _ -> SetPage (OrganizationsPage Modules.Organizations.Types.Home) |> dispatch) "fa-solid fa-gear fa-sm"
        //menuItem "Members" (fun _ -> SetPage (MembersPage Modules.Members.Types.Home) |> dispatch) "fa-solid fa-users"
        //menuItem "Inventories" (fun _ -> SetPage (InventoriesPage Modules.Inventories.Types.Home) |> dispatch) "fa-solid fa-list-check"
        authorizedMenuItem
            (Route.get page = Employees "")
            { Claim = "employees"; Resource = currentOrg }
            "Employees" (fun _ -> SetPage (EmployeePage Modules.Employees.Types.Home) |> dispatch) "fa-solid fa-users"

        authorizedMenuItem
            (Route.get page = Salaries "")
            { Claim = "salaries"; Resource = currentOrg }
            "Salaries" (fun _ -> SetPage (SalariesPage Modules.Salaries.Types.Home) |> dispatch) "fa-solid fa-hand-holding-dollar"

        authorizedMenuItem
            (Route.get page = Absences "")
            { Claim = "absences"; Resource = currentOrg }
            "Absences" (fun _ -> SetPage (AbsencesPage Modules.Absences.Types.Home) |> dispatch) "fa-solid fa-plane-departure"

        menuSection "Support"
        menuItem
            (Route.get page = AppDocs "")
            "Send Feedback" (fun _ -> SetPage (AppDocsPage Modules.AppDocs.Types.Page.Feedback) |> dispatch) "fa-solid fa-comment fa-sm"

        authorizedFragment { Claim = "appadmin"; Resource = "imogen" }
            [
                menuSection "App Admin"
                menuItem
                    (page = AppAdminPage Modules.AppAdmin.Types.Feedback)
                    "Feedback" (fun _ -> SetPage (AppAdminPage Modules.AppAdmin.Types.Feedback) |> dispatch) "fa-solid fa-comment fa-sm"
                menuItem
                    (page = AppAdminPage Modules.AppAdmin.Types.WaitingList)
                    "Waiting List" (fun _ -> SetPage (AppAdminPage Modules.AppAdmin.Types.WaitingList) |> dispatch) "fa-solid fa-list fa-sm"
            ]
    ]

let sidebar (page : Page) (session : ClientSession option) dispatch =
    Html.asidec "fixed z-50 md:relative" [
        Html.inputc "peer hidden" [
            Attr.typeCheckbox
            Attr.id "sidebar-open"
        ]
        Html.labelc "peer-checked:rounded-full peer-checked:p-2 peer-checked:right-6 peer-checked:bg-gray-600 peer-checked:text-white absolute top-8 z-20 mx-4 cursor-pointer md:hidden" [
            Attr.for' "sidebar-open"
            Html.spanc "h-6 w-6" [
                Html.ic "fa-solid fa-bars fa-lg" []
            ]
        ]
        Html.navc "peer-checked:w-64 left-0 z-10 flex h-screen w-0 flex-col overflow-hidden bg-gray-700 text-white transition-all md:h-screen md:w-64 lg:w-64" [
            Attr.ariaLabel "Sidebar Navigation"
            Html.divc "bg-slate-800 py-6 pl-10" [
                Html.span [
                    Html.spanc "mr-1 inline-flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 align-bottom text-2xl font-bold" [
                        Attr.text "I"
                    ]
                    Html.spanc "text-xl" [
                        Attr.text "mogen"
                    ]
                ]
            ]

            menu page session dispatch

            match session with
            | None ->
                Html.divc "my-6 mt-auto flex mt-8 space-y-3" [
                    Html.buttonc "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-8 py-4 text-gray-300 focus:outline-none" [
                        onClick (fun _ -> SetPage (AuthPage Modules.Auth.Default.Page.Login) |> dispatch) []
                        Html.spanc "text-2xl" [
                            Html.ic "fa-solid fa-right-to-bracket fa-sm" []
                        ]
                        Html.span [
                            Attr.text "Sign In / Sign Up"
                        ]
                    ]
                ]
            | Some authSession ->
                Html.divc "my-6 mt-auto ml-8 flex" [
                    Html.divc "cursor-pointer" [
                        onClick (fun _ -> EndSession |> dispatch) [ PreventDefault ]
                        Html.spanc "text-2xl" [
                            Html.ic "fa fa-sign-out" []
                        ]
                    ]
                    Html.divc "ml-3" [
                        Html.pc "font-medium" [
                            Attr.text authSession.UserEmail.Value
                        ]
                    ]
                ]
        ]
    ]

let topbar (session : ClientSession option) =
    Html.headerc "relative flex flex-col items-center bg-white px-4 py-4 shadow sm:flex-row md:h-20" [
        Html.divc "flex w-full flex-col justify-start overflow-hidden transition-all sm:max-h-full sm:flex-row sm:items-center" [
            Html.divc "relative flex items-center ml-5" [
                match session with
                | None -> Html.none
                | Some session ->
                    Html.h1c "font-black text-2xl leading-[3.5rem] text-gray-800" [
                        text session.OrgInfo.Name
                    ]
            ]

            // Three top right icons - we do not need them at the moment

            (*Html.ul [
                Attr.className "mx-auto mt-4 flex space-x-6 sm:mx-5 sm:mt-0"
                Html.li [
                    Html.buttonc "flex h-8 w-8 items-center justify-center rounded-xl border text-gray-600 hover:text-black hover:shadow" [
                        Html.i [
                            Attr.className "fa-regular fa-circle-user"
                        ]
                    ]
                ]
                Html.li [
                    Html.buttonc "flex h-8 w-8 items-center justify-center rounded-xl border text-gray-600 hover:text-black hover:shadow" [
                        Html.i [
                            pAttrrop.className "fa-solid fa-life-ring"
                        ]
                    ]
                ]
                Html.li [
                    Html.buttonc "flex h-8 w-8 items-center justify-center rounded-xl border text-gray-600 hover:text-black hover:shadow" [
                        Html.i [
                            Attr.className "fa-solid fa-gear"
                        ]
                    ]
                ]
            ]*)
        ]
    ]



let masterPage (page : Page) (pageView : SutilElement) (session: ClientSession option) dispatch =
    Html.divc "bg-slate-200 flex h-screen" [
        sidebar page session dispatch
        Html.divc "flex h-full w-full flex-col" [
            topbar session
            Html.divc "h-full overflow-hidden" [
                Html.main [
                    Attr.id "dashboard-main"
                    Attr.className "h-full overflow-auto px-8 py-10"
                    pageView
                    //     <h1 class="text-2xl font-black text-gray-800">Good Morning!</h1>
                    //     <p class="mb-6 text-gray-600">Here's an overview of your monthly transactions.</p>
                    //     <div class="flex flex-wrap gap-x-4 gap-y-8">
                    //          <div class="h-56 w-72 rounded-xl bg-white p-10 shadow-md"></div>
                    //          <div class="h-56 w-72 rounded-xl bg-white p-10 shadow-md"></div>
                    //          <div class="h-56 w-full rounded-xl bg-white p-10 shadow-md"></div>
                    //          <div class="h-56 w-full rounded-xl bg-white p-10 shadow-md"></div>
                    //          <div class="h-56 w-full rounded-xl bg-white p-10 shadow-md"></div>
                    //     </div>
                ]
            ]
        ]
    ]

open Types
open Sutil.Styling

let render (store : IStore<State>) dispatch (page : Page) : SutilElement =
    fragment [
        headTitle "Imogen"

        Bind.el (store .>> (fun x -> x.Session), fun s ->
            let pageView = viewPage s dispatch page

            match s with
            | Some _ ->
                masterPage page pageView s dispatch
            | None ->
                pageView
        )
    ]

