module Modules.AppDocs.Types

type Page =
    | About
    | Team
    | Feedback
    override this.ToString() =
        match this with
        | About -> ""
        | Team -> "/team"
        | Feedback -> "/feedback"
