module Modules.Auth.Challenge

open Sutil
open Fable.Remoting.Client
open Auth
open IAuthApi
open Sutil.CoreElements

type private State =
    { AuthenticatingUser: Deferred<ClientSession> }
    static member Default : State =
        { AuthenticatingUser = Deferred.NotStarted }

type private Message = AuthenticateUser of Query<string, ClientSession>

let private init challenge state =
    state, Cmd.ofMsg (challenge |> Query.Started |> AuthenticateUser)

let private authApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IAuthApiRoute.builder
    |> Remoting.buildProxy<IAuthApi>

let private update startSession (msg: Message) (state: State) =
    match msg with
    | AuthenticateUser au ->
        match au with
        //| Query.Started _ when state.AuthenticatingUser = Deferred.InProgress -> state, Cmd.none
        | Query.Started challengeCode ->
            { state with AuthenticatingUser = Deferred.InProgress },
            remoteCallQuery
                authApi.challenge { Challenge = challengeCode } 
                AuthenticateUser
        | Query.Completed res ->
            { state with AuthenticatingUser = Deferred.Resolved res },
            Cmd.ofEffect (fun _ -> startSession (Some res))
        | Query.Error err ->
            { state with AuthenticatingUser = Deferred.NotStarted },
            Cmd.ofEffect (fun _ -> startSession None)

let create (challenge: string) startSession =
    let state, _ =
        State.Default
        |> Store.makeElmish (init challenge) (update startSession) ignore

    fragment [
        disposeOnUnmount [ state ]
    ]
