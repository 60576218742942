module Modules.AppAdmin.Types

type Page =
    | Home
    | WaitingList
    | Feedback
    override this.ToString() =
        match this with
        | Home -> ""
        | WaitingList -> "/waitinglist"
        | Feedback -> "/feedback"
