[<AutoOpen>]
module Claims

type Claim = { Claim: string; Resource: string }

let claimResourceCanBeMapped (fromC: Claim) (toC: Claim) : bool = 
    fromC.Resource = "*"
    || toC.Resource = ""
    || fromC.Resource = toC.Resource

let claimTypeCanBeMapped (fromC: Claim) (toC: Claim) : bool =
    fromC.Claim = "*"
    || fromC.Claim = toC.Claim

let (==>) (fromClaim: Claim) (toClaim: Claim) : bool = 
    claimResourceCanBeMapped fromClaim toClaim
    && claimTypeCanBeMapped fromClaim toClaim

let existsIn (userClaims: Claim list) (requiredClaim: Claim) : bool =
    let resourceClaims = userClaims |> List.filter (fun c -> c.Resource = requiredClaim.Resource || c.Resource = "*")

    resourceClaims
    |> List.exists (fun c -> c.Claim = requiredClaim.Claim || c.Claim = "*")

[<RequireQualifiedAccess>]
module Claim =
    let create claim resource = { Claim = claim; Resource = resource }