module App.App

open Sutil
open Browser.Dom
open App.Router
open Util
open Fable.Remoting.Client
open App.View
open Auth
open Types
open IAuthApi
open Sutil.CoreElements

let private authApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IAuthApiRoute.builder
    |> Remoting.buildProxy<IAuthApi>

let init () : State * Cmd<Message> =
    { Page = AuthHomePage; Session = loadBrowserSession () }, Cmd.none

let update (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | SetOrg (orgInfo : SessionOrgInfo) ->
        match state.Session with
        | None -> state, Cmd.none
        | Some session -> 
            let browserSession = loadBrowserSession ()
            match browserSession with
            | Some bs ->
                createBrowserSession { bs with OrgInfo = orgInfo }
            | None -> ()
            
            { state with Session = Some { session with OrgInfo = orgInfo } }, Cmd.none
    | SetPage (p: Page) ->
        let destination = authorize p state.Session

        window.location.href <- destination.ToString()
        { state with Page = destination }, Cmd.none
    | StartSession sd ->
        match sd with
        | Some sessionData ->
            createBrowserSession sessionData
            { state with Session = sd }, Cmd.ofMsg (SetPage AuthHomePage)
        | None ->
            state, Cmd.ofMsg (SetPage (AuthPage (Modules.Auth.Default.Page.Login)))
    | EndSession ->
        match state.Session with
            | Some session ->
                deleteBrowserSession ()
                authApi.logout { SessionId = session.SessionId.Value } |> Async.StartImmediate
            | None -> ()
        { state with Session = None }, Cmd.ofMsg (SetPage (AuthPage (Modules.Auth.Default.Page.Login)))

let page state = state.Page
let session state = state.Session

// this method is called just once
let create () =
    let state, dispatch =
        () 
        |> Store.makeElmish init update ignore

    let routerSubscription =
        Navigable.listenLocation((getPage (Store.get state).Session), (dispatch << SetPage))

    let page = state .>> page

    fragment [
        disposeOnUnmount [ state ]
        unsubscribeOnUnmount [routerSubscription]
        Bind.el (page, render state dispatch)
    ]

create () |> Program.mount
