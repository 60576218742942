module Modules.AppAdmin.Default

open Modules.AppAdmin.Types
open Elmish.UrlParser

let parser =
    oneOf [
        map Home (top)
        map WaitingList (s "waitinglist")
        map Feedback (s "feedback")
    ]

let getPage url : Page =
    match parseUrl parser url with
    | Some p -> p
    | None -> Home

let create page session dispatch setPage (unauthorizedRedirection : obj -> unit) =
    match page with
    | Home -> Home.create session setPage unauthorizedRedirection
    | WaitingList -> WaitingList.create session setPage unauthorizedRedirection
    | Feedback -> Feedback.create session setPage unauthorizedRedirection
