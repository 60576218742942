module App.Router

open Browser.Types
open Elmish.UrlParser
open Auth

type Page =
    | AnonHomePage
    | AuthHomePage
    | AppDocsPage of Modules.AppDocs.Types.Page
    | AuthPage of Modules.Auth.Default.Page
    | OrganizationsPage of Modules.Organizations.Types.Page
    | MembersPage of Modules.Members.Types.Page
    | EmployeePage of Modules.Employees.Types.Page
    | SalariesPage of Modules.Salaries.Types.Page
    | InventoriesPage of Modules.Inventories.Types.Page
    | AppAdminPage of Modules.AppAdmin.Types.Page
    | AbsencesPage of Modules.Absences.Types.Page
    override this.ToString() =
        match this with
        | AnonHomePage -> "#"
        | AuthHomePage -> "#dashboard"
        | AppDocsPage p -> "#appdocs" + p.ToString()
        | AuthPage p -> "#login" + p.ToString()
        | OrganizationsPage p -> "#organizations" + p.ToString()
        | MembersPage p -> "#members" + p.ToString()
        | EmployeePage p -> "#employees" + p.ToString()
        | SalariesPage p -> "#salaries" + p.ToString()
        | InventoriesPage p -> "#inventories" + p.ToString()
        | AppAdminPage p -> "#appadmin" + p.ToString()
        | AbsencesPage p -> "#absences" + p.ToString()

type Route =
    | AnonHome
    | AuthHome
    | AppDocs of string
    | Auth of string
    | Organizations of string
    | Members of string
    | Employees of string
    | Salaries of string
    | Inventories of string
    | AppAdmin of string
    | Absences of string
    static member get(page: Page): Route = 
        match page with
        | AnonHomePage -> AnonHome
        | AuthHomePage -> AuthHome
        | AppDocsPage _ -> AppDocs ""
        | AuthPage _ -> Auth ""
        | OrganizationsPage _ -> Organizations ""
        | MembersPage _ -> Members ""
        | EmployeePage _ -> Employees ""
        | SalariesPage _ -> Salaries ""
        | InventoriesPage _ -> Inventories ""
        | AppAdminPage _ -> AppAdmin ""
        | AbsencesPage _ -> Absences ""

type Message =
    | SetPage of Page
    | SetOrg of SessionOrgInfo
    | StartSession of ClientSession option
    | EndSession

let parser =
    oneOf
        [
            map AnonHome (top)
            map AuthHome (s "#dashboard")
            map AppDocs (s "#appdocs" </> remaining)
            map Auth (s "#login" </> remaining)
            map Organizations (s "#organizations" </> remaining)
            map Members (s "#members" </> remaining)
            map Employees (s "#employees" </> remaining)
            map Salaries (s "#salaries" </> remaining)
            map Inventories (s "#inventories" </> remaining)
            map AppAdmin (s "#appadmin" </> remaining)
            map Salaries (s "#salaries" </> remaining)
            map Absences (s "#absences" </> remaining)
        ]

let getPage session (loc: Location) : Page =
    let route =
        match parseUrl parser loc.hash with
        | Some p -> p
        | None -> AnonHome

    match route with
    | Auth url -> Modules.Auth.Default.getPage url |> AuthPage
    | AuthHome
    | AnonHome ->
        match session with
        | Some _ -> AuthHomePage
        | None -> AnonHomePage
    | AppDocs url ->
        match session with
        | Some _ -> Modules.AppDocs.Defaults.getPage url |> AppDocsPage
        | None -> AnonHomePage
    | Organizations url ->
        match session with
        | Some _ -> Modules.Organizations.Default.getPage url |> OrganizationsPage
        | None -> AnonHomePage
    | Members url ->
        match session with
        | Some _ -> Modules.Members.Default.getPage url |> MembersPage
        | None -> AnonHomePage
    | Employees url ->
        match session with
        | Some _ -> Modules.Employees.Default.getPage url |> EmployeePage
        | None -> AnonHomePage
    | Salaries url ->
        match session with
        | Some _ -> Modules.Salaries.Default.getPage url |> SalariesPage
        | None -> AnonHomePage
    | Inventories url ->
        match session with
        | Some _ -> Modules.Inventories.Default.getPage url |> InventoriesPage
        | None -> AnonHomePage
    | AppAdmin url ->
        match session with
        | Some _ -> Modules.AppAdmin.Default.getPage url |> AppAdminPage
        | None -> AnonHomePage
    | Absences url ->
        match session with
        | Some _ -> Modules.Absences.Default.getPage url |> AbsencesPage
        | None -> AnonHomePage

let authorize (page: Page) (session: ClientSession option) =
    match session with
    | Some _ ->
        match page with
        | AuthPage _ -> AuthHomePage
        | _ -> page
    | None ->
        match page with
        | AnonHomePage -> page
        | AuthPage _ -> page
        | _ -> AuthPage Modules.Auth.Default.Login
