module IApplicationApi

open Shared.Validation
open Shared
open Appl

type AddToWaitingListRequest = { Email: string }
type InviteRequest = { WaitingListId: string }

type FeedbackEntryView = { Email: string; Message: string }

type Statistics = {
  NumberOfOrganizations : int
  NumberOfEmployees: int
}

type IApplicationApi =
    { addToWaitingList: AddToWaitingListRequest -> Async<Result<unit, AggregateError>>
      getWaitingListEntries: SecureRequest<unit>
          -> Async<Result<Result<Domain.WaitingListEntry, AggregateError> list, AggregateError>>
      invite: SecureRequest<InviteRequest> -> Async<Result<unit, AggregateError>>
      sendFeedback: SecureRequest<string> -> Async<Result<unit, AggregateError>>
      getFeedbackEntries: SecureRequest<unit> -> Async<Result<FeedbackEntryView list, AggregateError>> 
      getStatistics: SecureRequest<unit> -> Async<Result<Statistics, AggregateError>> }

module IApplicationApiRoute =
    let builder typeName methodName = $"/api/{typeName}/{methodName}"
