module Modules.Organizations.Types

type Page =
    | Home
    | Create
    | Edit of string
    override this.ToString() =
        match this with
        | Home -> ""
        | Create -> "/create"
        | Edit orgId -> $"/edit/{orgId}"
