module Modules.Employees.Default

open Elmish.UrlParser
open Modules.Employees.Types

let parser =
    oneOf [
        map Home (s "home")
        map Create (s "create")
        map Edit (s "edit" </> str)
    ]

let getPage url : Page =
    match parseUrl parser url with
    | Some p -> p
    | None -> Home

let create page session setPage (unauthorizedRedirection : obj -> unit) =
    match page with
    | Home -> Home.create session setPage unauthorizedRedirection
    | Create ->  Create.create session setPage unauthorizedRedirection
    | Edit employeeId -> Edit.create employeeId session setPage unauthorizedRedirection