module Modules.Salaries.Home

open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Auth
open Modules.Salaries.Types
open ISalariesApi
open Shared
open System
open Fable.Core
open Fable.Core.JsInterop

type private State =
    { GettingOrgSalaries: Deferred<OrganizationSalariesResponse>
      Session: ClientSession
      SortedColumn: SortedColumn  }
      static member Default session =
        {  GettingOrgSalaries = Deferred.NotStarted
           Session = session
           SortedColumn = { Column = SortableColumn.LastName; Direction = SortDirection.Ascending } }

type private Message =
    | GetOrgSalaries of Query<string, OrganizationSalariesResponse>
    | SortColumn of SortedColumn

let private init state =
    state, Cmd.ofMsg (state.Session.OrgInfo.Id |> Query.Started |> GetOrgSalaries)

let private salariesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder ISalariesApiRoute.builder
    |> Remoting.buildProxy<ISalariesApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | GetOrgSalaries fo ->
        match fo with
        | Query.Started _ when state.GettingOrgSalaries = Deferred.InProgress -> state, Cmd.none
        | Query.Started orgId ->
            { state with GettingOrgSalaries = Deferred.InProgress },
            remoteCallQuery
                salariesApi.getOrganizationSalaries
                { SessionId = state.Session.SessionId.Value
                  Content = { OrganizationId = orgId; SortBy = state.SortedColumn.Column; SortDirection = state.SortedColumn.Direction } }
                GetOrgSalaries
        | Query.Completed result ->
            { state with GettingOrgSalaries = Deferred.Resolved(result) },
            Cmd.none
        | Query.Error err ->
            { state with GettingOrgSalaries = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | SortColumn col -> {state with SortedColumn = { Column = col.Column; Direction  = col.Direction }},
                            Cmd.ofMsg (state.Session.OrgInfo.Id |> Query.Started |> GetOrgSalaries)


let private view (state: IStore<State>) dispatch setPage =
    Html.div [
        UI.heading "Salaries"

        Bind.el(
            state .>> (fun s -> s.GettingOrgSalaries), fun gettingEmployees ->
            match gettingEmployees with
            | Deferred.NotStarted
            | Deferred.InProgress -> UI.loader
            | Deferred.Resolved employees ->
                Html.divc "overflow-x-auto w-full py-5" [

                    Html.divc "w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8" [
                        fragment (
                            employees.Stats |>
                            List.map
                                (fun stats ->
                                        Html.divc "bg-white rounded py-5 px-6 shadow text-center" [
                                            Html.h2c "text-xl font-medium text-gray-800" [
                                                Html.text stats.Currency
                                            ]
                                            Html.divc "mt-4" [
                                                Html.divc "text-4xl text-indigo-600 font-bold mr-4" [
                                                    Html.text (formatMoney stats.Total)
                                                ]
                                            ]
                                            Html.divc "mt-6" [
                                                Html.divc "card-body grid grid-cols-2 text-center" [
                                                    Html.divc "col" [
                                                        Html.divc "text-lg font-semibold" [
                                                            Html.text (formatMoney stats.Taxed)
                                                        ]
                                                        Html.divc "text-sm text-gray-500 uppercase" [
                                                            Html.text "taxed"
                                                        ]
                                                    ]
                                                    Html.divc "col" [
                                                        Html.divc "text-lg font-semibold" [
                                                            Html.text (formatMoney stats.Untaxed)
                                                        ]
                                                        Html.divc "text-sm text-gray-500 uppercase" [
                                                            Html.text "untaxed"
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    )
                            )

                    ]

                    Html.tablec "mx-auto w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden mt-4" [
                        Html.theadc "bg-gray-50" [
                            Html.trc "text-black text-left" [
                                let cell el = Html.tdc "font-semibold text-sm uppercase px-6 py-4" [ el ]
                                let sortableCell (column: SortableColumn) =
                                    Bind.el(
                                        state .>> (fun s -> s.SortedColumn), fun sc ->
                                            Html.tdc "font-semibold text-sm uppercase px-6 py-4 cursor-pointer"
                                                [
                                                    Html.text column.DisplayName
                                                    if column = sc.Column then
                                                        if sc.Direction = SortDirection.Ascending then
                                                            fragment [
                                                                Html.ic "fa-solid fa-sort-up ml-2" []
                                                                onClick (fun _ -> { Column = column; Direction  = SortDirection.Descending } |> SortColumn |> dispatch) [ PreventDefault ]
                                                            ]
                                                        else
                                                            fragment [
                                                                Html.ic "fa-solid fa-sort-down ml-2" []
                                                                onClick (fun _ -> { Column = column; Direction  = SortDirection.Ascending } |> SortColumn |> dispatch) [ PreventDefault ]
                                                            ]
                                                    else
                                                        fragment [
                                                            Html.ic "fa-solid fa-sort ml-2" []
                                                            onClick (fun _ -> { Column = column; Direction  = SortDirection.Ascending } |> SortColumn |> dispatch) [ PreventDefault ]
                                                        ]
                                                ]
                                            )

                                sortableCell SortableColumn.LastName
                                sortableCell SortableColumn.FirstName
                                cell (Html.text "Taxed")
                                cell (Html.text "Untaxed")
                                cell (Html.text "Actions")
                            ]
                        ]
                        Html.tbody [
                            Attr.className "divide-y divide-gray-200"

                            fragment (
                                employees.Salaries |>
                                List.map
                                    (fun employee ->
                                            Html.tr [
                                                let cell el = Html.tdc "px-6 py-4" [ el ]

                                                cell (Html.text $"{employee.LastName}")
                                                cell (Html.text $"{employee.FirstName}")
                                                cell (Html.text $"{formatMoney employee.TaxedAmount} {employee.TaxedCurrency}")
                                                cell (Html.text $"{formatMoney employee.UntaxedAmount} {employee.UntaxedCurrency}")
                                                cell (
                                                    Html.divc "flex items-center" [
                                                        UI.editBtn "Salary details" (fun _ -> setPage (Details employee.EmployeeId))
                                                    ]
                                                )
                                            ]
                                    )
                            )
                        ]
                    ]
                ]
            )
    ]

let create session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]