module DomHelper

    open Browser.Types
    open Browser.Dom
    open Sutil

    // A member of Cmd.OfFunc that would be useful, where we don't care about the outcome at all
    let cmdOfFuncUnit ( f : unit -> unit ) =
        [ fun _ -> f() ]

    // This needs to be exposed from Sutil, currently is internal to DomHelpers
    let applyIfElement (f : HTMLElement -> unit) (n : Node) : unit =
        if DomHelpers.isElementNode n then
            (n :?> HTMLElement) |> f

    let focusBySelector (selector : string) =
        document.querySelector(selector)
            |> applyIfElement (fun el -> el.focus())

    let focusById (id : string) =
        document.getElementById(id)
            |> applyIfElement (fun el -> el.focus())