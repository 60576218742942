module Modules.Auth.Login

open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open IAuthApi

type private State =
    { Email: string
      SendingMagicLink: Deferred<unit> }
    static member Default =
        { Email = ""
          SendingMagicLink = Deferred.NotStarted }

type private Message =
    | SetEmail of string
    | SendMagicLink of Command<string>

let private init state = state, Cmd.none

let private authApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IAuthApiRoute.builder
    |> Remoting.buildProxy<IAuthApi>

let private update msg (state : State) =
    match msg with
    | SetEmail email -> { state with Email = email }, Cmd.none
    | SendMagicLink vc ->
        match vc with
        | Command.Started _ when state.SendingMagicLink = Deferred.InProgress -> state, Cmd.none
        | Command.Started email ->
            { state with SendingMagicLink = Deferred.InProgress },
            remoteCallCommand 
                authApi.sendMagicLink 
                { Email = email }
                SendMagicLink
        | Command.Completed ->
            { state with
                SendingMagicLink = Deferred.Resolved()
                Email = "" },
            Cmd.none
        | Command.Error err ->
            { state with SendingMagicLink = Deferred.NotStarted },
            Cmd.errorToast err

let private view state dispatch =
    let sendingMagicLink state = state.SendingMagicLink

    let isSendingMagicLink state =
        state.SendingMagicLink = Deferred.InProgress

    Html.divc "flex flex-col justify-center antialiased bg-gray-200 text-gray-600 min-h-screen p-4" [
        Html.divc "h-full" [
            Html.divc "max-w-[360px] mx-auto" [
                Html.divc "bg-white shadow-lg rounded-lg mt-9" [
                    Html.divc "text-center px-5 pb-5" [
                        Html.divc "mb-2" [
                            Html.imgc "-mt-8 inline-flex rounded-full" [
                                Attr.src "images/login.png"
                                Attr.width 96
                                Attr.height 96
                            ]
                            Html.h3c "text-xl font-bold text-gray-900 mb-1 mt-3" [
                                Attr.text "Sign In / Sign Up"
                            ]
                            Html.divc "text-sm font-medium text-gray-500" [
                                Html.text "Enter your email address to access your account"
                            ]
                        ]
                        Html.divc "text-center px-5 py-2" [
                            Html.divc "text-sm mb-6" [
                                Html.text "No need to sign up - first time you sign in, we will create your account!"
                            ]
                        ]
                        Html.divc "space-y-3" [
                            Html.divc "flex shadow-sm rounded" [
                                Html.divc "flex-grow" [
                                    Html.inputc "mt-2 h-12 w-full rounded-md bg-gray-100 px-3 outline-none focus:ring" [
                                        Attr.typeEmail
                                        Attr.placeholder "info@example.com"
                                        Bind.attr ("value", state .>> (fun s -> s.Email), SetEmail >> dispatch)
                                        Bind.attr ("disabled", state .>> isSendingMagicLink)
                                        onKeyDown
                                            (fun e ->
                                                if e.key = "Enter" then
                                                   (state |> Store.get).Email |> Command.Started |> SendMagicLink |> dispatch)
                                            []
                                    ]
                                ]
                            ]
                            Bind.el (
                                state .>> sendingMagicLink,
                                fun sendingMagicLink ->
                                    match sendingMagicLink with
                                    | Deferred.NotStarted
                                    | Deferred.Resolved _ ->
                                        Html.buttonc "mt-5 w-full rounded-md bg-blue-600 p-2 text-center font-semibold text-white outline-none focus:ring" [
                                            Attr.typeButton
                                            text "Get Started"
                                            onClick (fun _ -> (state |> Store.get).Email |> Command.Started |> SendMagicLink |> dispatch) [ PreventDefault ]
                                        ]
                                    | Deferred.InProgress ->
                                        Html.buttonc "mt-5 w-full rounded-md bg-blue-600 p-2 text-center font-semibold text-white outline-none focus:ring" [
                                            Attr.typeButton
                                            text ""
                                            Html.ic "fas fa-spinner fa-spin" []
                                            Attr.disabled true
                                        ]
                            )

                            Bind.el (
                                state .>> sendingMagicLink,
                                fun sendingMagicLink ->
                                    match sendingMagicLink with
                                    | Deferred.Resolved () ->
                                        Html.pc "pb-4 text-gray-500 mt-8" [
                                            text
                                                "Login link was sent to your email, and will arrive in a few moments. Please check your inbox shortly."
                                        ]
                                    | _ -> Html.none
                            )
                        ]
                    ]
                ]
            ]
        ]
    ]
    
let create () =

    let state, dispatch =
        State.Default
        |> Store.makeElmish init update ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch
    ]
