module Modules.Employees.Types

type Page =
    | Home
    | Create
    | Edit of string
    override this.ToString() =
        match this with
        | Home -> ""
        | Create -> "/create"
        | Edit employeeId -> $"/edit/{employeeId}"

type RawFileInfo = {
    Content: byte array
    Name: string
    MimeType: string
    Size: int
}