module Modules.Members.Types

type Page =
    | Home
    | Create
    | Edit of string
    override this.ToString() =
        match this with
        | Home -> ""
        | Create -> "/create"
        | Edit memberId -> $"/edit/{memberId}"
