module Modules.AppDocs.Team

open Sutil
open Sutil.CoreElements
open Modules.AppDocs.Types

let private view setPage = 
    Html.div [
        Html.h1 "Teams page"
        Html.br []
        Html.p [
            text "this is About page content"
        ]
        Html.pc "cursor-pointer underline" [
            text "Back to About Page"
            onClick (fun _ -> setPage About) []
        ]
    ]

let create setPage = view setPage
