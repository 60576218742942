module Modules.Home.AuthHome

open Fable.Remoting.Client
open Sutil
open Auth
open Sutil.CoreElements
open IApplicationApi

type private State =
    { Session: ClientSession
      GettingStatistics: Deferred<Statistics> }
    static member Default session=
        { Session = session
          GettingStatistics = Deferred.NotStarted }

type Message =
    | GetStatistics of EmptyQuery<Statistics>

let private applicationApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IApplicationApiRoute.builder
    |> Remoting.buildProxy<IApplicationApi>

let private init state =
    state, Cmd.ofMsg (GetStatistics EmptyQuery.Started)

let private appApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IApplicationApiRoute.builder
    |> Remoting.buildProxy<IApplicationApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | GetStatistics getStats ->
        match getStats with
        | EmptyQuery.Started when state.GettingStatistics = Deferred.InProgress -> state, Cmd.none
        | EmptyQuery.Started ->
            { state with GettingStatistics = Deferred.InProgress },
            remoteCallEmptyQuery
                applicationApi.getStatistics
                { SessionId = state.Session.SessionId.Value
                  Content = () }
                GetStatistics
        | EmptyQuery.Completed res ->
            { state with GettingStatistics = Deferred.Resolved (res) },
            Cmd.none
        | EmptyQuery.Error err ->
            { state with GettingStatistics = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private authContent (state: IStore<State>) (setCreateOrgPage : obj -> unit) (setCreateEmployeePage : obj -> unit) =
    //Html.text $"Hello, {session.UserEmail.Value}"

    let renderChangelogEntry (date: string) (heading: string) (text: string) =
        Html.lic "mb-10 ml-4" [
            Html.divc "absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white" []
            Html.time [
                Attr.className "mb-1 font-normal text-gray-400"
                Html.text date
            ]
            Html.h3c "text-lg font-semibold text-gray-900" [
                Html.text heading
            ]
            Html.pc "mb-4 text-base font-normal text-gray-500" [
                Html.text text
            ]
        ]


    fragment [
        Bind.el (state .>> (fun s -> s.GettingStatistics), UI.spinnerDeferred)

        Bind.el(state .>> (fun state -> state.GettingStatistics),
            fun attentionOrganizations ->
                match attentionOrganizations with
                | (Deferred.Resolved stats) when (stats.NumberOfOrganizations = 0) ->
                    Html.divc "flex items-center justify-center px-4 mb-8" [
                        Html.divc "transition duration-150 ease-in-out w-full lg:w-11/12 mx-auto bg-white shadow rounded flex py-4 md:py-0 flex-col items-center md:flex-row justify-between" [
                            Attr.roleAlert
                            Html.divc "flex flex-col items-center md:flex-row" [
                                Html.divc "mr-3 p-4 bg-blue-400 rounded md:rounded-tr-none md:rounded-br-none text-white" [
                                    Html.imgc "focus:outline-none" [
                                        Attr.src "https://tuk-cdn.s3.amazonaws.com/can-uploader/simple-with-action-button-info-svg1.svg"
                                        Attr.alt "info"
                                    ]
                                ]
                                Html.pc "mr-2 text-base font-bold text-gray-800 mt-2 md:my-0" [
                                    Attr.text "Attention"
                                ]
                                Html.divc "h-1 w-1 bg-gray-300 rounded-full mr-2 hidden xl:block" []
                                Html.pc "text-sm lg:text-base text-gray-600 lg:pt-1 xl:pt-0 sm:mb-0 mb-2 text-center sm:text-left" [
                                    Attr.text "You do not have any organizations created at the moment. Organizations are basic building block for adding other information"
                                ]
                            ]
                            Html.divc "flex xl:items-center lg:items-center sm:justify-end justify-center pr-4" [
                                Html.buttonc "text-sm mr-4 font-bold focus:outline-none focus:text-indigo-400 hover:text-indigo-400 cursor-pointer text-indigo-700" [
                                    Attr.text "Create Orgnization"
                                    onClick setCreateOrgPage []
                                ]
                            ]
                        ]
                    ]
                | _ -> Html.none
        )



        Html.divc "grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 bg-gray-200" [
            Html.divc "bg-white rounded shadow" [
                Html.divc "border-l-4 border-indigo-300 flex items-center justify-between p-3" [
                    Html.h3c "focus:outline-none leading-4 text-gray-800 font-normal text-base" [
                        Attr.text "Total Organizations"
                    ]
                    Bind.el (state |> Store.map (fun state -> state.GettingStatistics),
                        fun stats ->
                            Html.h2c "focus:outline-none text-gray-800 text-2xl leading-normal font-bold" [
                                Attr.text (
                                    match stats with
                                    | Deferred.NotStarted
                                    | Deferred.InProgress -> "..."
                                    | Deferred.Resolved stats -> stats.NumberOfOrganizations.ToString()
                                )
                            ]
                    )
                ]
            ]
            Html.divc "bg-white rounded shadow" [
                Html.divc "border-l-4 border-yellow-300 flex items-center justify-between p-3" [
                    Html.h3c "focus:outline-none leading-4 text-gray-800 font-normal text-base" [
                        Attr.text "Total Employees"
                    ]
                    Bind.el (state |> Store.map (fun state -> state.GettingStatistics),
                        fun stats ->
                            Html.h2c "focus:outline-none text-gray-800 text-2xl leading-normal font-bold" [
                                Attr.text (
                                    match stats with
                                    | Deferred.NotStarted
                                    | Deferred.InProgress -> "..."
                                    | Deferred.Resolved stats -> stats.NumberOfEmployees.ToString()
                                )
                            ]
                    )
                ]
            ]
        ]

        Html.divc "grid grid-cols-1 my-4 xl:grid-cols-2 xl:gap-4" [
            Html.divc "p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-6 xl:mb-0" [
                Html.divc "flex items-center justify-between mb-4" [
                    Html.h3c "text-lg font-semibold text-gray-900" [
                        Html.text "Changelog"
                    ]
                ]
                Html.olc "relative border-l border-gray-200" [
                    renderChangelogEntry
                        "2023-09-23"
                        "Sorting salaries"
                        "Support for sorting salaries by employee name."

                    renderChangelogEntry
                        "2023-08-27"
                        "Absences Calendar"
                        "Creating employee absences with monthly calendar overview."

                    renderChangelogEntry
                        "2023-08-27"
                        "Salary totals in different currencies"
                        "Organization salary totals are now displayed in all currencies used."

                    renderChangelogEntry
                        "2023-08-01"
                        "Delete employees"
                        "You can now delete former employees of your organization."

                    renderChangelogEntry
                        "2023-07-20"
                        "Salaries parts"
                        "Support for taxed and untaxed parts of a salary."
                ]
            ]
        ]

        // Html.divc "w-full justify-center flex py-12 px-4" [

        //     let actionBlock heading body gotoPage =
        //         Html.divc "flex items-center py-8 px-4" [
        //             Html.divc "md:w-96 rounded shadow-lg p-5 bg-indigo-700" [
        //                 Html.pc "text-2xl font-bold leading-none text-white pt-7" [
        //                     Attr.text heading
        //                 ]
        //                 Html.pc "pt-4 mb-5 text-sm leading-5 text-white" [
        //                     Attr.text body
        //                 ]
        //                 Html.divc "flex items-center w-full justify-end relative" [
        //                     Html.divc "w-16 h-16 flex items-center justify-center bg-white rounded-full opacity-50" []
        //                     Html.buttonc "hover:opacity-75 focus:opacity-75 focus:outline-none focus:ring-2 focus:offset-2 focus:ring-indigo-700 w-12 h-12 mr-2 flex items-center cursor-pointer justify-center bg-white rounded-full absolute" [
        //                         Html.ic "fa-solid fa-arrow-right" []
        //                         onClick gotoPage []
        //                     ]
        //                 ]
        //             ]
        //         ]

        //     actionBlock "Manage Organizations" "How technology catapulted innovation in two decades" setCreateOrgPage
        //     actionBlock "Manage Employees" "How technology catapulted innovation in two decades" setCreateEmployeePage
        // ]
    ]

let private view (state: IStore<State>) (setCreateOrgPage : obj -> unit) (setCreateEmployeePage : obj -> unit) =
    authContent state setCreateOrgPage setCreateEmployeePage

let create (session: ClientSession) setCreateOrgPage setCreateEmployeePage (unauthorizedRedirection : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update unauthorizedRedirection) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state setCreateOrgPage setCreateEmployeePage
    ]
