module Modules.Employees.Utils

open Browser.Types
open Modules.Employees.Types
open Fable.Remoting.Client

let readContent (file: File) : Async<RawFileInfo> = async {
    let! fileBytes = file.ReadAsByteArray()
    return {
        Content  = fileBytes
        Name = file.name
        MimeType = file.``type``
        Size = file.size
    }
}