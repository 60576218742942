module Modules.Organizations.Edit

open Shared
open IOrganizationsApi
open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Modules.Organizations.Types
open Auth

type private State =
    { Session: ClientSession
      GettingOrg: Deferred<UpdateOrganizationCommand>
      UpdatingOrg: CommandExec<UpdateOrganizationCommand> }

    static member Default session =
        { Session = session
          GettingOrg = Deferred.NotStarted
          UpdatingOrg = CommandExec.NotStarted }

type private Message =
    | SetOrgName of string
    | SetEmail of string
    | SetRegNo of string
    | SetTaxNo of string
    | SetAddress of string
    | SetPhone of string
    | GetUpdateCommand of Query<string, UpdateOrganizationCommand>
    | SendUpdateCommand of Command<UpdateOrganizationCommand>

let private organizationsApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IOrganizationsApiRoute.builder
    |> Remoting.buildProxy<IOrganizationsApi>

let private init orgId state =
    state, Cmd.ofMsg (orgId |> Query.Started |> GetUpdateCommand )

let private update setPage setOrg unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match state.GettingOrg with
    | Deferred.Resolved org ->
        match msg with
        | SetOrgName orgName -> { state with GettingOrg = Deferred.Resolved { org with Name = orgName } }, Cmd.none
        | SetPhone phone -> { state with GettingOrg = Deferred.Resolved { org with Phone = phone } }, Cmd.none
        | SetEmail email -> { state with GettingOrg = Deferred.Resolved { org with Email = email } }, Cmd.none
        | SetRegNo regNo -> { state with GettingOrg = Deferred.Resolved { org with RegistrationNumber = regNo } }, Cmd.none
        | SetTaxNo taxNo -> { state with GettingOrg = Deferred.Resolved { org with TaxNumber = taxNo } }, Cmd.none
        | SetAddress address -> { state with GettingOrg = Deferred.Resolved { org with Address = address } }, Cmd.none
        | SendUpdateCommand sur ->
            match sur with
            | Command.Started _ when state.UpdatingOrg = CommandExec.InProgress -> state, Cmd.none
            | Command.Started org ->
                { state with UpdatingOrg = CommandExec.InProgress },
                remoteCallCommand
                    organizationsApi.sendUpdateRequest
                    { SessionId = state.Session.SessionId.Value
                      Content = org }
                    SendUpdateCommand
            | Command.Completed ->
                let cmdUpdateCurrentOrg =
                    if org.Id = state.Session.OrgInfo.Id then
                        Cmd.ofEffect (fun _ -> setOrg { Id = org.Id; Name = org.Name })
                    else
                        Cmd.none

                { state with UpdatingOrg = CommandExec.Completed },
                Cmd.batch [
                    Cmd.ofEffect (fun _ -> setPage Home)
                    cmdUpdateCurrentOrg
                    Cmd.successToast "Organization updated."
                ]
            | Command.Error err ->
                { state with UpdatingOrg = CommandExec.NotStarted },
                Cmd.ofErr err unauthorizedRedirPage
        | GetUpdateCommand _ ->
            state, Cmd.none
    | Deferred.NotStarted
    | Deferred.InProgress ->
        match msg with
        | GetUpdateCommand gur ->
            match gur with
            | Query.Started _ when state.GettingOrg = Deferred.InProgress -> state, Cmd.none
            | Query.Started orgId ->
                { state with GettingOrg = Deferred.InProgress },
                remoteCallQuery
                    organizationsApi.getUpdateRequest
                    { SessionId = state.Session.SessionId.Value
                      Content = orgId }
                    GetUpdateCommand
            | Query.Completed org ->
                { state with GettingOrg = Deferred.Resolved org },
                Cmd.none
            | Query.Error err ->
                { state with GettingOrg = Deferred.NotStarted },
                Cmd.ofErr err unauthorizedRedirPage
        | _ ->
            state, Cmd.none

let private view state dispatch setPage =

    let getFieldContent fieldSelector =
        (state .>> fun s ->
            match s.GettingOrg with
            | Deferred.Resolved x -> fieldSelector x
            | Deferred.InProgress -> "Loading..."
            | Deferred.NotStarted -> "")

    let renderTextField name fieldSelector onChange =
        UI.renderTextField name (getFieldContent fieldSelector) onChange

    let renderTextAreaField rows name fieldSelector onChange =
        UI.renderTextAreaField rows name (getFieldContent fieldSelector) onChange

    fragment [
        UI.heading "Organizations"
        Html.divc "w-full bg-white rounded-lg p-6" [
            Html.divc "grid grid-cols-1 sm:grid-cols-2 gap-4" [
                renderTextField "Organization name" (fun s -> s.Name) (SetOrgName >> dispatch)
                renderTextField "Phone" (fun s -> s.Phone) (SetPhone >> dispatch)
                renderTextField "Email" (fun s -> s.Email) (SetEmail >> dispatch)
                renderTextField "Registration Number" (fun s -> s.RegistrationNumber) (SetRegNo >> dispatch)
                renderTextField "Tax Number" (fun s -> s.TaxNumber) (SetTaxNo >> dispatch)
                renderTextAreaField 3 "Address" (fun s -> s.Address) (SetAddress >> dispatch)
            ]

            Html.divc "border-t my-4" []

            Html.divc "flex justify-start" [
                Bind.el (
                    state .>> (fun s -> (Deferred.resolved s.GettingOrg) && (CommandExec.notInProgress s.UpdatingOrg)),
                    UI.okBtn "Save" (fun _ -> (state |> Store.get).GettingOrg |> Deferred.exec (fun c -> c |> Command.Started |> SendUpdateCommand |> dispatch))
                )
                Bind.el (
                    state .>> (fun s -> (Deferred.notInProgress s.GettingOrg) && (CommandExec.notInProgress s.UpdatingOrg)),
                    UI.cancelBtn "Cancel" (fun _ -> setPage Home)
                )
            ]
        ]

        Bind.el (state .>> (fun s -> s.GettingOrg), UI.spinnerDeferred)
    ]

let create orgId session setPage setOrg (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish (init orgId) (update setPage setOrg unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]