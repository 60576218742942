module Modules.Home.AnonHome

open Fable.Remoting.Client
open Sutil
open Sutil.CoreElements
open IApplicationApi

type private State =
    { Email: string
      SubscribingToWaitingList: Deferred<unit> }
    static member Default =
        { Email = ""
          SubscribingToWaitingList = Deferred.NotStarted }

type Message =
    | SetEmail of string
    | SubscribeToWaitingList of Command<string>

let private applicationApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IApplicationApiRoute.builder
    |> Remoting.buildProxy<IApplicationApi>

let private init state =
    state, Cmd.none

let private update (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | SetEmail email -> { state with Email = email }, Cmd.none
    | SubscribeToWaitingList vc ->
        match vc with
        | Command.Started _ when state.SubscribingToWaitingList = Deferred.InProgress -> state, Cmd.none
        | Command.Started email ->
            { state with SubscribingToWaitingList = Deferred.InProgress },
            remoteCallCommand
                applicationApi.addToWaitingList
                { Email = email }
                SubscribeToWaitingList
        | Command.Completed ->
           { state with SubscribingToWaitingList = Deferred.Resolved() }, Cmd.none
        | Command.Error err ->
            { state with SubscribingToWaitingList = Deferred.NotStarted },
            Cmd.batch [
                DomHelper.cmdOfFuncUnit (fun _ -> DomHelper.focusById "email")
                Cmd.errorToast err
            ]

let private landingContent (state: IStore<State>) dispatch =
    Html.divc "2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4" [
        // Html.p [
        //     Attr.className "font-normal text-sm leading-3 text-indigo-700 dark:text-indigo-500 hover:text-indigo-800 cursor-pointer pb-2"
        //     text "About"
        // ]
        Html.divc "flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12" [
            Html.divc "w-full lg:w-6/12" [
                Html.h2c "w-full font-bold lg:text-4xl text-3xl lg:leading-10 leading-9" [
                    text "We are here to Digitalize your Organization"
                ]
                Html.pc "font-normal text-xl leading-6 text-gray-600 mt-6" [
                    text "Is your decision process data-driven?"
                ]
                Html.pc "font-normal text-xl leading-6 text-gray-600 mt-6" [
                    text "If not, what is driving it?"
                ]
                Html.pc "font-normal text-xl leading-6 text-gray-600 mt-6" [
                    text "If you are making changes, how do you measure their outcome?"
                ]
                Html.pc "font-normal text-xl leading-6 text-gray-600 mt-6" [
                    text "Can you, at any point in time, check current state of your organization?"
                ]
                Html.pc "font-normal text-xl leading-6 text-gray-600 mt-6" [
                    text "Are you waiting for quarterly or annual reviews to make decision?"
                ]
            ]
            Html.divc "w-full lg:w-6/12" [
                (*Html.img [
                    Attr.className "lg:block hidden w-full"
                    Attr.src "https://i.ibb.co/RjNH7QB/Rectangle-122-1.png"
                    Attr.alt "people discussing on board"
                ]
                Html.img [
                    Attr.className "lg:hidden sm:block hidden w-full"
                    Attr.src "https://i.ibb.co/16fPqrg/Rectangle-122-2.png"
                    Attr.alt "people discussing on board"
                ]
                Html.img [
                    Attr.className "sm:hidden block w-full"
                    Attr.src "https://i.ibb.co/Jxhpxh6/Rectangle-122.png"
                    Attr.alt "people discussing on board"
                ] *)
                (*Html.div[
                Attr.className "items-end"
                Html.div [
                    Attr.style "background-image: url('https://i.ibb.co/16fPqrg/Rectangle-122-2.png');"
                    Attr.className "w-full px-3 mb-6 md:mb-0 h-full "
                    Html.div [
                        Attr.className ""
                        Html.label[
                            Attr.className "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2"
                            Html.text "Organization name"
                        ]
                        Html.input [
                            Attr.className "appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            Attr.placeholder "Email"
                        ]
                    ]
                ]*)
                fragment [
                    Html.divc "relative px-4 sm:px-6 lg:px-8 mx-auto" [
                        Html.imgc "rounded-t shadow-lg" [
                            Attr.src "images/subscribe.jpg"
                            Attr.width 646
                            Attr.height 180
                        ]
                    ]
                    Html.divc "relative px-4 sm:px-6 lg:px-8 pb-8 mx-auto" [
                        Html.divc "bg-white px-8 pb-6 rounded-b shadow-lg" [
                            Bind.el(state .>> (fun s -> s.SubscribingToWaitingList),
                                fun subscribe ->
                                    match subscribe with
                                    | Deferred.NotStarted
                                    | Deferred.InProgress ->
                                        fragment [
                                            Html.divc "text-center mb-6 mt-6" [
                                                Html.h1c "text-xl leading-snug text-gray-800 font-semibold mb-2" [
                                                    Html.text "🔥 Interested? 🔥"
                                                ]
                                                Html.divc "text-sm" [
                                                    Html.text "Sign up for a waiting list and we will let you know when we launch."
                                                ]
                                            ]
                                            Html.divc "space-y-4" [
                                                Html.inputc "text-sm text-gray-800 bg-white border rounded leading-5 py-2 px-3 border-gray-200 hover:border-gray-300 focus:border-indigo-300 shadow-sm placeholder-gray-400 focus:ring-0 w-full" [
                                                    Attr.placeholder "Email"
                                                    Attr.id "email"
                                                    Bind.attr ("value", state .>> (fun state -> state.Email), SetEmail >> dispatch)
                                                    onKeyDown
                                                        (fun e ->
                                                            if e.key = "Enter" then
                                                                (state |> Store.get).Email |> Command.Started |> SubscribeToWaitingList |> dispatch)
                                                        []
                                                ]
                                            ]
                                            Html.divc "mt-6" [
                                                Html.divc "mb-4" [
                                                    match subscribe with
                                                    | Deferred.NotStarted
                                                    | Deferred.Resolved _ ->
                                                            Html.buttonc "font-medium text-sm inline-flex items-center justify-center px-3 py-2 border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out w-full bg-blue-500 hover:bg-blue-700 text-white focus:outline-none focus-visible:ring-2" [
                                                                Html.text "Join the Waiting List"
                                                                onClick (fun _ -> (state |> Store.get).Email |> Command.Started |> SubscribeToWaitingList |> dispatch) [ PreventDefault ]
                                                            ]
                                                    | Deferred.InProgress ->
                                                            Html.buttonc "font-medium text-sm inline-flex items-center justify-center px-3 py-2 border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out w-full bg-blue-500 hover:bg-blue-700 text-white focus:outline-none focus-visible:ring-2 disabled:opacity-50" [
                                                                Attr.disabled true
                                                                text ""
                                                                Html.ic "fas fa-spinner fa-spin" []
                                                            ]
                                               ]
                                            ]
                                        ]
                                    | Deferred.Resolved _ ->
                                        fragment [
                                            Html.divc "text-sm mt-6" [
                                                Html.text "Thank you for enlisting!"
                                            ]
                                            Html.divc "text-sm mt-6" [
                                                Html.text "You are now on our waiting list and we will let you know when Imogen becomes available."
                                            ]
                                        ]
                                )
                        ]
                    ]
                ]
            ]
        ]
        Html.divc "relative mt-24" [
            Html.divc "grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4" [
                Html.divc "z-20 w-12 h-12 bg-gray-800 rounded-full flex justify-center items-center" [
                    Html.img [
                        Attr.src "https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg1.svg"
                        Attr.alt "flag"
                    ]
                ]
                Html.imgc "z-20" [
                    Attr.src "https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg2.svg"
                    Attr.alt "note"
                ]
                Html.imgc "z-20 sm:block hidden" [
                    Attr.src "https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg3.svg"
                    Attr.alt "users"
                ]
            ]
            Html.hr [
                Attr.className "z-10 absolute top-2/4 w-full bg-gray-200"
            ]
        ]
        Html.divc "grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4" [
            Html.div [
                Html.pc "font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6" [
                    text "Collect"
                ]
                Html.pc "font-normal text-base leading-6 text-gray-600 mt-6" [
                    text "Collect data seamlessly as you lead your Organization"
                ]
            ]
            Html.div [
                Html.pc "font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6" [
                    text "Analyze"
                ]
                Html.pc "font-normal text-base leading-6 text-gray-600 mt-6" [
                    text "Observe digital snapshot of your Organization. In real time. Generated from collected data. Measure your success."
                ]
            ]
            Html.divc "sm:block hidden" [
                Html.pc "font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6" [
                    text "React!"
                ]
                Html.pc "font-normal text-base leading-6 text-gray-600 mt-6" [
                    text "Make informed decisions. Continously adapt and iterate. Recognize emerging trends and react. Innovate."
                ]
            ]
        ]
        Html.divc "sm:hidden block relative mt-8" [
            Html.divc "grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4" [
                Html.img [
                    Attr.src "https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg3.svg"
                    Attr.alt "user"
                ]
            ]
            Html.hr [
                Attr.className "z-10 absolute top-2/4 w-full bg-gray-200"
            ]
        ]
        Html.divc "sm:hidden grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4" [
            Html.div [
                Html.pc "font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6" [
                    text "React!"
                ]
                Html.pc "font-normal text-base leading-6 text-gray-600 mt-6" [
                    text "Make informed decisions. Continously adapt and iterate. Recognize emerging trends and react. Innovate."
                ]
            ]
        ]
        (*
        Html.div [
            Attr.className "flex lg:flex-row flex-col md:gap-14 gap-16 justify-between lg:mt-20 mt-16"
            Html.div [
                Attr.className "w-full lg:w-6/12"
                Html.h2 [
                    Attr.className "font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 dark:text-white"
                    text "Our Mission"
                ]
                Html.p [
                    Attr.className "font-normal text-base leading-6 text-gray-600 dark:text-gray-200 mt-6 w-full lg:w-10/12 xl:w-9/12"
                    text "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.In the first place we have granted to God, and by this our present charter confirmed for us and our heirs forever that the English Church shall be free, and shall have her rights entire, and her liberties inviolate; and we will that it be thus observed; which is apparent from"
                ]
                Html.p [
                    Attr.className "font-normal text-base leading-6 text-gray-600 dark:text-gray-200 w-full lg:w-10/12 xl:w-9/12 mt-10"
                    text "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.In the first place we have granted to God, and by this our present charter confirmed for us and our heirs forever that the English Church shall be free, and shall have her rights entire, and her liberties inviolate; and we will that it be thus observed; which is apparent from"
                ]
            ]

            Html.div [
                Attr.className "w-full lg:w-6/12"
                Html.div [
                    Attr.className "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 lg:gap-12 gap-10"
    //             <!-- Team Card -->
                    Html.div [
                        Attr.className "flex p-4 shadow-md"
                        Html.div [
                            Attr.className "mr-6"
                            Html.img [
                                Attr.className "mr-6"
                                Attr.src "https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg4.svg"
                                Attr.alt "team card"
                            ]
                        ]
                        Html.div [
                            Html.p [
                                Attr.className "font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 dark:text-white"
                                text "Team"
                            ]
                            Html.p [
                                Attr.className "mt-2 font-normal text-base leading-6 text-gray-600 dark:text-gray-200"
                                text "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                            ]
                        ]
                    ]

    //             <!-- Board Card -->
                    Html.div [
                        Attr.className "flex p-4 shadow-md"
                        Html.div [
                            Attr.className "mr-6"
                            Html.img [
                                Attr.src "https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg5.svg"
                                Attr.alt "board card"
                            ]
                        ]
                        Html.div [
                            Html.p [
                                Attr.className "font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 dark:text-white"
                                text "Board"
                            ]
                            Html.p [
                                Attr.className "mt-2 font-normal text-base leading-6 text-gray-600 dark:text-gray-200"
                                text "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                            ]
                        ]
                    ]

    //             <!-- Press Card -->
                    Html.div [
                        Attr.className "flex p-4 shadow-md"
                        Html.div [
                            Attr.className "mr-6"
                            Html.img [
                                Attr.src "https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg6.svg"
                                Attr.alt "press card"
                            ]
                        ]
                        Html.div [
                            Html.p [
                                Attr.className "font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 dark:text-white"
                                text "Press"
                            ]
                            Html.p [
                                Attr.className "mt-2 font-normal text-base leading-6 text-gray-600 dark:text-gray-200"
                                text "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                            ]
                        ]
                    ]
                ]
            ]
        ]
        *)
    ]

let private view (state: IStore<State>) dispatch =
    landingContent state dispatch

let create =

    let state, dispatch =
        State.Default
        |> Store.makeElmish init update ignore

    fragment [
        //disposeOnUnmount [ state ]
        view state dispatch
    ]

