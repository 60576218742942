module Modules.Organizations.Create

open Organization
open IOrganizationsApi
open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Modules.Organizations.Types
open Auth

type private State =
    { Session: ClientSession
      Organization: CreateOrganizationCommand
      CreatingOrganization: CommandExec<CreateOrganizationCommand> }

    static member Default session =
        { Session = session
          Organization = CreateOrganizationCommand.New (OrganizationId.Create().Value)
          CreatingOrganization = CommandExec.NotStarted }

type private Message =
    | CreateOrganization of Command<CreateOrganizationCommand>
    | SetOrgName of string
    | SetEmail of string
    | SetRegNo of string
    | SetTaxNo of string
    | SetAddress of string
    | SetPhone of string

let private organizationsApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IOrganizationsApiRoute.builder
    |> Remoting.buildProxy<IOrganizationsApi>

let private init details = details, Cmd.none

let private update setPage setOrg unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | SetOrgName orgName -> { state with Organization = { state.Organization with  Name = orgName } }, Cmd.none
    | SetPhone phone -> { state with Organization = { state.Organization with Phone = phone } }, Cmd.none
    | SetEmail email -> { state with Organization = { state.Organization with Email = email } }, Cmd.none
    | SetRegNo regNo -> { state with Organization = { state.Organization with RegistrationNumber = regNo } }, Cmd.none
    | SetTaxNo taxNo -> { state with Organization = { state.Organization with TaxNumber = taxNo } }, Cmd.none
    | SetAddress address -> { state with Organization = { state.Organization with Address = address } }, Cmd.none
    | CreateOrganization vc ->
        match vc with
        | Command.Started _ when state.CreatingOrganization = CommandExec.InProgress -> state, Cmd.none
        | Command.Started organization ->
            { state with CreatingOrganization = CommandExec.InProgress },
            remoteCallCommand
                organizationsApi.createOrganization
                { SessionId = state.Session.SessionId.Value
                  Content = organization }
                CreateOrganization
        | Command.Completed ->
            { state with CreatingOrganization = CommandExec.Completed },
            Cmd.batch [
                Cmd.successToast "Organization created."
                Cmd.ofEffect (fun _ -> setOrg { Id = state.Organization.Id; Name = state.Organization.Name })
                Cmd.ofEffect (fun _ -> setPage Home)
            ]
        | Command.Error err ->
            { state with CreatingOrganization = CommandExec.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private view state dispatch setPage =
    fragment [
        UI.heading "Create Organization"
        Html.divc "w-full bg-white rounded-lg p-6" [
            Html.divc "grid grid-cols-1 sm:grid-cols-2 gap-4" [
                UI.renderTextField "Organization name" (state .>> (fun s -> s.Organization.Name)) (SetOrgName >> dispatch)
                UI.renderTextField "Phone" (state .>> (fun s -> s.Organization.Phone)) (SetPhone >> dispatch)
                UI.renderTextField "Email" (state .>> (fun s -> s.Organization.Email)) (SetEmail >> dispatch)
                UI.renderTextField "Registration Number" (state .>> (fun s -> s.Organization.RegistrationNumber)) (SetRegNo >> dispatch)
                UI.renderTextField "Tax Number" (state .>> (fun s -> s.Organization.TaxNumber)) (SetTaxNo >> dispatch)
                UI.renderTextAreaField 3 "Address" (state .>> (fun s -> s.Organization.Address)) (SetAddress >> dispatch)
            ]

            Html.divc "border-t my-4" []

            Html.divc "flex justify-start" [
                Bind.el (
                    state .>> (fun s -> CommandState.notInProgress s.CreatingOrganization),
                    UI.okBtn "Create" (fun _ -> (state |> Store.get).Organization |> Command.Started |> CreateOrganization |> dispatch)
                )
                Bind.el (
                    state .>> (fun s -> CommandState.notInProgress s.CreatingOrganization),
                    UI.cancelBtn "Cancel" (fun _ -> setPage Home)
                )
            ]

        ]

    ]

let create session setPage setOrg (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update setPage setOrg unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]
