module Modules.Salaries.Types

type Page =
    | Home
    | Details of string
    override this.ToString() =
        match this with
        | Home -> ""
        | Details employeeId -> $"/details/{employeeId}"

