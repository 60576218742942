module Modules.Absences.Home

open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Auth
open Shared
open System
open IAbsencesApi
open Absences

type private CreateAbsenceModalState =
    | Closed
    | Opened

type private State =
    { CurrentMonth: CalendarMonth
      GettingCalendar: Deferred<GetCalendarMonthResponse>
      CreatingAbsence: CommandExec<CreateAbsenceCommand>
      Session: ClientSession
      CreateAbsenceModalState: CreateAbsenceModalState
      NewAbsence: CreateAbsenceCommand
      }
      static member Default session =
        {  CurrentMonth = CalendarMonth.current
           GettingCalendar = Deferred.NotStarted
           CreatingAbsence = CommandExec.NotStarted
           Session = session
           CreateAbsenceModalState = Closed
           NewAbsence = CreateAbsenceCommand.empty session.OrgInfo.Id
           }

type private Message =
    | GetMonthCalendar of Query<GetCalendarMonthRequest, GetCalendarMonthResponse>
    | CreateAbsence of Command<CreateAbsenceCommand>
    | OpenCreateAbsenceModal
    | CloseCreateAbsenceModal
    | SetNewAbsenceStartDate of string
    | SetNewAbsenceEndDate of string
    | SetNewAbsenceNote of string
    | SetNewAbsenceEmployee of string
    | SetNewAbsenceType of string

let private init state =
    state, Cmd.ofMsg ({ OrganizationId = state.Session.OrgInfo.Id; Month = state.CurrentMonth } |> Query.Started |> GetMonthCalendar)

let private absencesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IAbsencesApiRoute.builder
    |> Remoting.buildProxy<IAbsencesApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | GetMonthCalendar gmc ->
        match gmc with
        | Query.Started _ when state.GettingCalendar = Deferred.InProgress -> state, Cmd.none
        | Query.Started request ->
            { state with GettingCalendar = Deferred.InProgress },
            remoteCallQuery
                absencesApi.getCalendarMonth
                { SessionId = state.Session.SessionId.Value
                  Content = request }
                GetMonthCalendar
        | Query.Completed result ->
            { state with GettingCalendar = Deferred.Resolved(result) },
            Cmd.none
        | Query.Error err ->
            { state with GettingCalendar = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | CreateAbsence cv ->
        match cv with
        | Command.Started _ when state.CreatingAbsence = CommandExec.InProgress -> state, Cmd.none
        | Command.Started cmd ->
            { state with CreatingAbsence = CommandExec.InProgress },
            remoteCallCommand
                absencesApi.sendCreateAbsenceCommand
                { SessionId = state.Session.SessionId.Value
                  Content = cmd }
                CreateAbsence
        | Command.Completed ->
            { state with NewAbsence = CreateAbsenceCommand.empty state.Session.OrgInfo.Id; CreatingAbsence = CommandExec.Completed },
            Cmd.batch [
                Cmd.ofMsg CloseCreateAbsenceModal
                Cmd.successToast "Absence created"
                Cmd.ofMsg ({ OrganizationId = state.Session.OrgInfo.Id; Month = state.CurrentMonth } |> Query.Started |> GetMonthCalendar)
            ]
        | Command.Error err ->
            { state with CreatingAbsence = CommandExec.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | OpenCreateAbsenceModal ->
        { state with CreateAbsenceModalState = Opened }, Cmd.none
    | CloseCreateAbsenceModal ->
        { state with CreateAbsenceModalState = Closed }, Cmd.none
    | SetNewAbsenceStartDate date ->
        let absence = DateRange.setStart state.NewAbsence.Interval date
        match absence with
        | Ok ab ->
            { state with NewAbsence = { state.NewAbsence with Interval = ab } }, Cmd.none
        | Error err ->
            state,
            (
                match err with
                | DateRange.InvalidDateFormat -> errorToastMsg "Invalid date format"
                | DateRange.StartDateAfterEndDate -> errorToastMsg "Interval end comes before interval start"
            )
    | SetNewAbsenceEndDate date ->
        let absence = DateRange.setEnd state.NewAbsence.Interval date
        match absence with
        | Ok ab ->
            { state with NewAbsence = { state.NewAbsence with Interval = ab } }, Cmd.none
        | Error err ->
            state,
            (
                match err with
                | DateRange.InvalidDateFormat -> errorToastMsg "Invalid date format"
                | DateRange.StartDateAfterEndDate -> errorToastMsg "Interval end comes before interval start"
            )
    | SetNewAbsenceNote note ->
        { state with NewAbsence = { state.NewAbsence with Note = note } }, Cmd.none
    | SetNewAbsenceEmployee empId ->
        { state with NewAbsence = { state.NewAbsence with EmployeeId = empId } }, Cmd.none
    | SetNewAbsenceType absType ->
        { state with NewAbsence = { state.NewAbsence with Type = absType } }, Cmd.none

let private renderCalendar dispatch (orgId : string) (cal : GetCalendarMonthResponse) =
    let month = CalendarMonth.create cal.Calendar.Month cal.Calendar.Year

    fragment [
        Html.divc "mt-8" [
            Html.divc "wrapper bg-white rounded shadow w-full" [
                Html.divc "header flex justify-between border-b p-4" [
                    Html.divc "buttons" [
                        Html.buttonc "p-1" [
                            Html.ic "fa-solid fa-chevron-left" []
                            onClick (fun _ -> { OrganizationId = orgId; Month = CalendarMonth.prev month } |> Query.Started |> GetMonthCalendar |> dispatch) []
                        ]
                        Html.buttonc "p-1" [
                            Html.ic "fa-solid fa-chevron-right" []
                            onClick (fun _ -> { OrganizationId = orgId; Month = CalendarMonth.next month } |> Query.Started |> GetMonthCalendar |> dispatch) []
                        ]
                    ]
                    Html.spanc "text-lg font-bold" [
                        Html.text $"{cal.Calendar.Year} : {cal.Calendar.Month}"
                    ]

                    Html.divc "flex flex-col justify-end" [
                        Html.buttonc "focus:ring outline-none rounded-lg text-white bg-blue-600 px-3 py-2 font-bold active:scale-95 hover:opacity-90" [
                            Html.text "Create New"
                            onClick (fun _ -> OpenCreateAbsenceModal |> dispatch) [ PreventDefault ]
                        ]
                    ]
                ]
                Html.tablec "w-full" [
                    Html.thead [
                        Html.tr [
                            let dayHeading (long : string) (short : string) =
                                Html.th [
                                    Attr.className "p-2 border-r h-10 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 xl:text-sm text-xs"
                                    Html.spanc "xl:block lg:block md:block sm:block hidden" [ Html.text long ]
                                    Html.spanc "xl:hidden lg:hidden md:hidden sm:hidden block" [ Html.text short ]
                                ]

                            dayHeading "Monday" "Mon"
                            dayHeading "Tuesday" "Tue"
                            dayHeading "Wednesday" "Wed"
                            dayHeading "Thursday" "Thu"
                            dayHeading "Friday" "Fri"
                            dayHeading "Saturday" "Sat"
                            dayHeading "Sunday" "Sun"
                        ]
                    ]
                    Html.tbody [
                        let renderAbsence (absence : CalendarAbsence) =
                            Html.divc $"event bg-{absence.Color}-400 text-white rounded text-sm mb-1 w-full" [
                                Html.spanc "event-name" [
                                    Html.text absence.Heading
                                ]
                            ]
                            |> UI.elementWithTooltip absence.Tooltip

                        let renderCell (text : string) (bgColor : string) events =
                            Html.tdc $"{bgColor} border p-1 h-40 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-40 transition cursor-pointer duration-500 ease hover:bg-gray-300" [
                                Html.divc "flex flex-col h-40 mx-auto xl:w-full xl:w-30 lg:w-30 md:w-30 sm:w-full w-10 mx-auto" [
                                    Html.divc "top h-5 w-full" [
                                        Html.spanc "text-gray-500" [
                                            Html.text text
                                        ]
                                    ]
                                    Html.divc "bottom flex-grow h-30 py-1 w-full cursor-pointer" [
                                        events
                                    ]
                                ]
                            ]

                        let renderAbsence (absences : CalendarAbsence list) =
                            absences
                            |> Seq.map renderAbsence
                            |> fragment

                        let renderDay (day : CalendarDay) =
                            match day with
                            | ActiveDate ad -> renderCell $"{ad.Day}" "" (renderAbsence ad.Absences)
                            | PassiveDate pd ->  renderCell $"{pd.Day}" "bg-gray-100" (renderAbsence pd.Absences)

                        let renderWeek (week : CalendarWeek) =
                            Html.trc "text-center h-20" [
                                for day in week do
                                    renderDay day
                            ]

                        for week in cal.Calendar.Weeks do
                            renderWeek week
                    ]
                ]
            ]
        ]
    ]

let private view (state: IStore<State>) dispatch setPage =

    let renderModal (calendar : GetCalendarMonthResponse ) visible =
        match visible with
        | true ->
            fragment [
                Html.divc "fixed left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full flex" [
                    Html.divc "relative w-full h-full max-w-2xl px-4 md:h-auto" [
                        Html.divc "relative bg-white rounded-lg shadow" [
                            Html.divc "flex items-start justify-between p-5 border-b rounded-t" [
                                Html.h3c "text-xl font-semibold" [
                                    Html.text "Create new Absence"
                                ]
                                Html.buttonc "ext-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" [
                                    Html.ic "fa-solid fa-xmark" []
                                    onClick (fun _ -> CloseCreateAbsenceModal |> dispatch) [ PreventDefault ]
                                ]
                            ]
                            Html.divc "p-6 space-y-6" [
                                Html.divc "grid grid-cols-6 gap-6" [
                                    Html.divc "col-span-6 sm:col-span-3" [
                                        Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                                            Html.text "Employee"
                                        ]
                                        Html.divc "relative mt-1 rounded-md shadow-sm" [
                                            Html.select [
                                                Attr.className "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                Bind.selectSingle (state .>> (fun s -> s.NewAbsence.EmployeeId), SetNewAbsenceEmployee >> dispatch)
                                                Html.option [
                                                    Attr.value ""
                                                    text "--- Select Employee ---"
                                                ]

                                                calendar.Employees
                                                |> List.map
                                                    (fun (empId, empName) ->
                                                        Html.option [
                                                            Attr.value empId
                                                            text empName
                                                        ]
                                                    )
                                                |> fragment
                                            ]
                                        ]
                                    ]

                                    Html.divc "col-span-6 sm:col-span-3" [
                                        Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                                            Html.text "Type"
                                        ]
                                        Html.divc "relative mt-1 rounded-md shadow-sm" [
                                            Html.select [
                                                Attr.className "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                Bind.selectSingle (state .>> (fun s -> s.NewAbsence.Type), SetNewAbsenceType >> dispatch)
                                                Html.option [
                                                    Attr.value ""
                                                    text "--- Select Type ---"
                                                ]

                                                ["Personal Time Off"; "Sick Day"; "Religious Holiday"]
                                                |> List.map
                                                    (fun absType ->
                                                        Html.option [
                                                            Attr.value absType
                                                            text absType
                                                        ]
                                                    )
                                                |> fragment
                                            ]
                                        ]
                                    ]

                                    Html.divc "col-span-6 sm:col-span-3" [
                                        Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                                            Html.text "From"
                                        ]
                                        Html.inputc "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" [
                                            Html.text "From"
                                            Attr.typeDate
                                            Bind.attr ("value", (state .>> (fun s -> DateRange.startValue s.NewAbsence.Interval)), (SetNewAbsenceStartDate >> dispatch))
                                        ]
                                    ]

                                    Html.divc "col-span-6 sm:col-span-3" [
                                        Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                                            Html.text "To"
                                        ]
                                        Html.inputc "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" [
                                            Html.text "To"
                                            Attr.typeDate
                                            Bind.attr ("value", (state .>> (fun s -> DateRange.endValue s.NewAbsence.Interval)), (SetNewAbsenceEndDate >> dispatch))
                                        ]
                                    ]

                                    Html.divc "col-span-6" [
                                        Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                                            Html.text "Notes"
                                        ]
                                        Html.textarea [
                                            Attr.className "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                            Attr.placeholder "Notes"
                                            Attr.rows 3
                                            Bind.attr ("value", (state .>> (fun s -> s.NewAbsence.Note)), (SetNewAbsenceNote >> dispatch))
                                        ]
                                    ]
                                ]
                            ]

                            Html.divc "items-center p-6 border-t border-gray-200 rounded-b" [
                                Bind.el (
                                    state .>> (fun s -> s.NewAbsence, s.CreatingAbsence),
                                    fun (newAbsence, creatingAbsence) ->
                                        UI.renderBtn
                                            {
                                                Type = UI.ButtonType.Ok
                                                Label = "Add New"
                                                State =
                                                    match newAbsence, creatingAbsence with
                                                    | CreateAbsenceCommand.Valid _, CommandExec.InProgress ->
                                                        UI.ButtonState.Processing
                                                    | CreateAbsenceCommand.Valid _, _ ->
                                                        UI.ButtonState.Enabled
                                                    | _ ->
                                                        UI.ButtonState.Disabled
                                                OnClick =
                                                    (fun _ ->
                                                        match newAbsence, creatingAbsence with
                                                        | CreateAbsenceCommand.Valid _, CommandExec.NotInProgress _  ->
                                                            newAbsence |> Command.Started |> CreateAbsence |> dispatch
                                                        | _ ->
                                                            ()
                                                    )
                                            }
                                    )
                            ]
                        ]
                    ]
                ]
                Html.divc "bg-gray-900 bg-opacity-50 fixed inset-0 z-40" []
            ]
        | false -> Html.none

    fragment [
        Bind.el (state .>> (fun s -> s.CreatingAbsence), UI.commandSpinner)
        Bind.el (state .>> (fun s -> (s.Session.OrgInfo.Id, s.GettingCalendar)), fun (orgId, calendar) ->
            fragment [
                UI.spinnerDeferred calendar

                match calendar with
                | Deferred.Resolved cal -> renderCalendar dispatch orgId cal
                | Deferred.NotStarted
                | Deferred.InProgress -> Html.none
            ]
        )
        Bind.el (
            state .>> (fun s -> (s.CreateAbsenceModalState = Opened, s.GettingCalendar)),
            fun (modalVisible, calendar) ->
                match calendar with
                | Deferred.Resolved cal -> renderModal cal modalVisible
                | _ -> Html.none
        )
    ]

let create session setPage (unauthorizedRedirPage: obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]