module Modules.AppAdmin.Home

open Sutil
open Sutil.CoreElements
open Modules.AppAdmin.Types

type private State = 
    { text: string }
      static member Default session =
        {  text = "" }

type private Msg = | Init

let private init details = details, Cmd.none

let private update unauthorizedRedirPage msg state = state, Cmd.none

let private view state dispatch setPage =
    Html.div [
        Html.h1 "App Admin page"
        Html.br []
        Html.p [
            text "this is App Admin page content"
        ]
        Html.pc "cursor-pointer underline" [
            text "Waiting List"
            onClick (fun _ -> setPage WaitingList) []
        ]
    ]

let create session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session 
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore 

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]