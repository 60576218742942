namespace Shared

[<AutoOpen>]
module Types =
    type SecureRequest<'t> =
        { SessionId: string
          Content: 't }

    type SortDirection =
        | Ascending
        | Descending

    type SortableColumn =
       | FirstName
       | LastName
        member this.DisplayName =
            match this with
            | FirstName -> "First Name"
            | LastName -> "Last Name"

    type SortedColumn =
        {
            Column : SortableColumn
            Direction: SortDirection
        }

    module Email =
        type From = { Email: string; Name: string }

        type To = { Email: string; Name: string }

        type Message =
            { From: From
              To: To array
              Subject: string
              TextPart: string
              HtmlPart: string }

        type Payload = { Messages: Message array }

    type FileData =
        { Name: string
          ContentType: string
          Content: byte [] }

    type FileMeta =
        { FileId: string
          Content: byte []
          Name: string
          Size: int
          MimeType: string }


