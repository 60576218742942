[<RequireQualifiedAccess>]
module UI

open System
open Sutil

let heading txt =
    Html.h1c "text-2xl font-black text-gray-800 mb-6" [
        text txt
    ]

let loader =
    Html.divc "flex justify-center items-center h-screen fixed top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden bg-gray-400 opacity-75" [
        Html.ic "fa-solid fa-spinner fa-4x animate-spin" []
    ]

let spinnerDeferred func =
    match func with
    | Deferred.InProgress ->
        loader
    | Deferred.NotStarted
    | Deferred.Resolved _ ->
        Html.none

let commandSpinner func =
    match func with
    | CommandExec.InProgress ->
        loader
    | CommandExec.NotStarted
    | CommandExec.Completed _ ->
        Html.none

let elementWithTooltip (text: string) element =
    if (String.IsNullOrWhiteSpace text) then
        element
    else
        Html.divc "relative flex flex-col items-center group" [
            element
            Html.divc "absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex" [
                Html.spanc "relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap rounded-md bg-gray-700 shadow-lg" [
                    Html.text text
                ]
                Html.divc "w-3 h-3 -mt-2 rotate-45 bg-gray-700 mb-4" []
            ]
        ]

let renderField attributes (label: string) (placeholder: string) (bind : IObservable<string>) onChange =
    Html.divc "" [
        Html.labelc "block mb-2 text-sm font-medium text-gray-900" [
            Html.text label
        ]
        Html.inputc "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" [
            Attr.placeholder placeholder
            attributes
            Bind.attr ("value", bind, onChange)
        ]
    ]

let renderDateField (label: string) (bind : IObservable<string>) onChange =
    renderField Attr.typeDate label label bind onChange

let renderTextField (label: string) (bind : IObservable<string>) onChange =
    renderField Attr.typeText label label bind onChange

let renderTextAreaField (rows: int) (label: string) (bind : IObservable<string>) onChange =
    Html.divc "" [
        Html.labelc "block mb-2 text-sm font-medium text-gray-900" [
            Html.text label
        ]
        Html.textarea [
            Attr.className "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
            Attr.placeholder label
            Attr.rows rows
            Bind.attr ("value", bind, onChange)
        ]
    ]

let renderTextAreaFieldFullWidth (rows: int) (label: string) (bind : IObservable<string>) onChange =
    Html.divc "sm:col-span-2" [
        Html.labelc "block mb-2 text-sm font-medium text-gray-900" [
            Html.text label
        ]
        Html.textarea [
            Attr.className "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
            Attr.placeholder label
            Attr.rows rows
            Bind.attr ("value", bind, onChange)
        ]
    ]

open Sutil.CoreElements

type ButtonState =
    | Enabled
    | Processing
    | Disabled

[<RequireQualifiedAccess>]
type ButtonType =
    | Ok

type Button =
    {
        Type : ButtonType
        Label : string
        OnClick : Browser.Types.Event -> unit
        State: ButtonState
    }

let renderBtn (btn: Button) =
    match btn.Type with
    | ButtonType.Ok ->
        match btn.State with
        | Enabled ->
            fragment [
                Html.buttonc "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" [
                    Html.text btn.Label
                    onClick btn.OnClick [ PreventDefault ]
                ]
            ]
        | Disabled ->
            fragment [
                Html.buttonc "bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50" [
                    Html.text btn.Label
                    Attr.disabled true
                ]
            ]
        | Processing ->
            fragment [
                Html.buttonc "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded disabled:opacity-50" [
                    Attr.disabled true
                    text ""
                    Html.ic "fas fa-spinner fa-spin" []
                ]
            ]

//let okBtn2 (label: string) (onClickFn : Browser.Types.Event -> unit) (state: ButtonState) =
let okBtn2 (label: string) (onClickFn : Browser.Types.Event -> unit) (state: ButtonState) =
    match state with
    | Enabled ->
        fragment [
            Html.buttonc "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" [
                Html.text label
                onClick onClickFn [ PreventDefault ]
            ]
        ]
    | Disabled ->
        fragment [
            Html.buttonc "bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50" [
                Html.text label
                Attr.disabled true
            ]
        ]
    | Processing ->
        fragment [
            Html.buttonc "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded disabled:opacity-50" [
                Attr.disabled true
                text ""
                Html.ic "fas fa-spinner fa-spin" []
            ]
        ]


let okBtn (label: string) onClickFn (enabled: bool) =
    match enabled with
    | true ->
        fragment [
            Html.buttonc "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" [
                Html.text label
                onClick onClickFn [ PreventDefault ]
            ]
        ]
    | false ->
        fragment [
            Html.buttonc "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded disabled:opacity-50" [
                Attr.disabled true
                text ""
                Html.ic "fas fa-spinner fa-spin" []
            ]
        ]

let cancelBtn (label: string) onClickFn (enabled: bool) =
    match enabled with
    | true ->
        fragment [
            Html.buttonc "bg-white hover:bg-gray-200 text-gray-600 font-medium py-2 px-4 border border-gray-200 rounded ml-2" [
                Html.text label
                onClick onClickFn [ PreventDefault ]
            ]
        ]
    | false ->
        fragment [
            Html.buttonc "py-2 px-4 bg-white border border-gray-200 text-gray-600 rounded hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50" [
                Attr.disabled true
                Html.text label
            ]
        ]

let editBtn tooltip onClickFn =
    elementWithTooltip
        tooltip
        Html.a[
            Attr.className "rounded border border-transparent focus:outline-none focus:border-gray-800 focus:shadow-outline-gray"
            onClick onClickFn []
            Html.divc "p-2 bg-gray-100 hover:bg-gray-200 rounded cursor-pointer text-blue-600" [
                Html.ic "fa-regular fa-pen-to-square" []
            ]
        ]

let switchBtn tooltip onClickFn =
    elementWithTooltip
        tooltip
        Html.a[
            Attr.className "mx-2 rounded border border-transparent focus:outline-none focus:border-gray-800 focus:shadow-outline-gray"
            onClick onClickFn []
            Html.divc "p-2 bg-gray-100 hover:bg-gray-200 rounded cursor-pointer text-blue-600" [
                Html.ic "fa-solid fa-right-left" []
            ]
        ]

let confirmDeleteBtn onClickConfirm (enabled: bool) (btnText:string) =
    match enabled with
    | true ->
        fragment [
             Html.buttonc "text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5 text-center mr-2" [
                Html.text btnText
                onClick onClickConfirm [ PreventDefault ]
            ]
        ]
    | false ->
        fragment [
            Html.buttonc "text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5 text-center mr-2" [
                Attr.disabled true
                text ""
                Html.ic "fas fa-spinner fa-spin" []
            ]
        ]

let declineDeleteBtn onClickClose (enabled: bool) =
    fragment [
        Html.buttonc "text-gray-900 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 border border-gray-200 font-medium inline-flex items-center rounded-lg text-base px-3 py-2.5 text-center" [
            Html.text "Cancel"

            match enabled with
            | true -> onClick onClickClose []
            | false -> Attr.disabled true
       ]
    ]


let closeModalBtn onClickClose (enabled: bool) =
    fragment [
        Html.buttonc "text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" [
            Html.ic "fa-solid fa-xmark" []

            match enabled with
            | true -> onClick onClickClose []
            | false -> Attr.disabled true
        ]
    ]

let renderDeleteConfirmationModal (visible: bool) (text:string) onClickClose onClickConfirm buttonsEnabled =
    match visible with
    | true ->
        fragment [
            Html.divc "fixed left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full flex" [
                Html.divc "relative w-full h-full max-w-md px-4 md:h-auto" [
                    Html.divc "relative bg-white rounded-lg shadow" [
                        Html.divc "flex justify-end p-2" [
                            closeModalBtn onClickClose buttonsEnabled
                        ]
                        Html.divc "p-6 pt-0 text-center" [
                            Html.ic "fa-regular fa-trash-can text-red-600 text-5xl" []
                            Html.h3c "mt-5 mb-6 text-lg text-gray-500" [
                                Html.p text
                            ]
                            confirmDeleteBtn onClickConfirm buttonsEnabled "Terminate"
                            declineDeleteBtn onClickClose buttonsEnabled
                        ]
                    ]
                ]
            ]
            Html.divc "bg-gray-900 bg-opacity-50 fixed inset-0 z-40" []
        ]
    | false -> Html.none