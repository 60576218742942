module IMembersApi

open Shared
open Member
open Shared.Validation

type GetMemberRequest = { Id: string }
type GetOrgMembersRequest = { OrganizationId: string }

type UpdateMemberCommand =
    { MemberId: string
      OrganizationId: string
      FirstName: string
      LastName: string
      Jmbg: string
      PersonalId: string
      Address: string
      Joined: string
      BankAccountNumber: string
      Education: string
      Position: string
      Notes: string
      Email: string }

module UpdateMemberCommand =
    open Organization
    let empty memberId : UpdateMemberCommand =
        { MemberId = memberId
          OrganizationId = ""
          FirstName = ""
          LastName = ""
          Jmbg = ""
          PersonalId = ""
          Address = ""
          Joined = ""
          BankAccountNumber = ""
          Education = ""
          Position = ""
          Notes = ""
          Email = "" }

    let toDomain (r: UpdateMemberCommand) : Result<Domain.Member, AggregateError> =
        result {
            let! id = MemberId.New r.MemberId
            and! organizationId = OrganizationId.New r.OrganizationId
            and! firstName = FirstName.New r.FirstName
            and! lastName = LastName.New r.LastName
            and! jmbg = Jmbg.NewOptional r.Jmbg
            and! personalId = PersonalId.NewOptional r.PersonalId
            and! address = Address.NewOptional r.Address
            and! joined = Date.New r.Joined
            and! bankAccountNumber = BankAccountNumber.NewOptional r.BankAccountNumber
            and! email = Email.Parse r.Email

            return
                Domain.Member.Create
                    id
                    organizationId
                    firstName
                    lastName
                    jmbg
                    personalId
                    address
                    joined
                    bankAccountNumber
                    r.Education
                    r.Position
                    r.Notes
                    email
        }
        |> Result.flatten

type CreateMemberCommand =
    { Id : string
      OrganizationId : string
      FirstName : string
      LastName : string
      Jmbg : string
      PersonalId : string
      Address : string
      Joined : string
      BankAccountNumber : string
      Education : string
      Position : string
      Notes : string
      Email : string  }
    static member New(memberId) =
        { Id = memberId
          OrganizationId = ""
          FirstName = ""
          LastName = ""
          Jmbg = ""
          PersonalId = ""
          Address = ""
          Joined = ""
          BankAccountNumber = ""
          Education = ""
          Position = ""
          Notes = ""
          Email = "" }

module CreateMemberCommand =
    open Organization
    let toDomain (command : CreateMemberCommand) : Result<Domain.Member, AggregateError> =
        result {
            let! id = MemberId.New command.Id
            and! organizationId = OrganizationId.New command.OrganizationId
            and! firstName = FirstName.New command.FirstName
            and! lastName = LastName.New command.LastName
            and! jmbg = Jmbg.NewOptional command.Jmbg
            and! personalId = PersonalId.NewOptional command.PersonalId
            and! address = Address.NewOptional command.Address
            and! joined = Date.New command.Joined
            and! bankAccountNumber = BankAccountNumber.NewOptional command.BankAccountNumber
            and! email = Email.Parse command.Email

            return
                { Id = id
                  OrganizationId = organizationId
                  FirstName = firstName
                  LastName = lastName
                  Jmbg = jmbg
                  PersonalId = personalId
                  Address = address
                  Joined = joined
                  BankAccountNumber = bankAccountNumber
                  Education = command.Education
                  Position = command.Position
                  Notes = command.Notes
                  Email = email }
        }

    let fromDomain (this: Domain.Member) : CreateMemberCommand =
        { Id = this.Id.Value
          OrganizationId = this.OrganizationId.Value
          FirstName = this.FirstName.Value
          LastName = this.LastName.Value
          Jmbg = match this.Jmbg with
                  | None -> ""
                  | Some jmbg -> jmbg.Value
          PersonalId = match this.PersonalId with
                       | None -> ""
                       | Some pid -> pid.Value
          Address = match this.Address with
                    | None -> ""
                    | Some address -> address.Value
          Joined = this.Joined.Value |> toISO
          BankAccountNumber = match this.BankAccountNumber with
                              | None -> ""
                              | Some ban -> ban.Value
          Education = this.Education
          Position = this.Position
          Notes = this.Notes
          Email = match this.Email with
                  | None -> ""
                  | Some email -> email.Value }

type GetOrgMembersResponse = Domain.Member list

type IMembersApi =
    {   getMember: SecureRequest<GetMemberRequest> -> Async<Result<Domain.Member, AggregateError>>
        getOrgMembers: SecureRequest<GetOrgMembersRequest> -> Async<Result<GetOrgMembersResponse, AggregateError>>
        createMember: SecureRequest<CreateMemberCommand> -> Async<Result<unit, AggregateError>>
        getUpdateCommand: SecureRequest<string> -> Async<Result<UpdateMemberCommand, AggregateError>>
        sendUpdateCommand: SecureRequest<UpdateMemberCommand> -> Async<Result<unit, AggregateError>> }

module IMembersApiRoute =
    let builder typeName methodName = $"/api/{typeName}/{methodName}"
