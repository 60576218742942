module Member

open Organization
open Shared
open Validation
open Ulid

type MemberId =
    private
    | MemberId of string
    member this.Value =
        let (MemberId value) = this in value

    static member Create () =
        MemberId (Ulid.New.ToString())

    static member New id =
        match id with
        | null
        | "" -> [ ValidationError "member id is empty" ] |> AggregateError |> Error
        | _ -> MemberId id |> Ok

type FirstName =
    private
    | FirstName of string
    member this.Value =
        let (FirstName value) = this in value

    static member New(name) =
        match name with
        | null
        | "" -> [ ValidationError "first name is empty" ] |> AggregateError |> Error
        | _ -> FirstName name |> Ok

type LastName =
    private
    | LastName of string
    member this.Value =
        let (LastName value) = this in value

    static member New(name) =
        match name with
        | null
        | "" -> [ ValidationError "last name is empty" ] |> AggregateError |> Error
        | _ -> LastName name |> Ok

type Jmbg =
    private
    | Jmbg of string
    member this.Value =
        let (Jmbg value) = this in value

    static member New(jmbg) =
        match jmbg with
        | null
        | "" -> [ ValidationError "jmbg is empty" ] |> AggregateError |> Error
        | _ -> Jmbg jmbg |> Ok

    static member NewOptional(jmbg) =
        match jmbg with
        | null
        | "" -> None |> Ok
        | _ -> Jmbg jmbg |> Some |> Ok

type PersonalId =
    private
    | PersonalId of string
    member this.Value =
        let (PersonalId value) = this in value

    static member New(personalId) =
        match personalId with
        | null
        | "" -> [ ValidationError "personal id is empty" ] |> AggregateError |> Error
        | _ -> PersonalId personalId |> Ok

    static member NewOptional(personalId) =
        match personalId with
        | null
        | "" -> None |> Ok
        | _ -> PersonalId personalId |> Some |> Ok

type BankAccountNumber =
    private
    | BankAccountNumber of string
    member this.Value =
        let (BankAccountNumber value) = this in value

    static member New(ban) =
        match ban with
        | null
        | "" -> [ ValidationError "bank account number is empty" ] |> AggregateError |> Error
        | _ -> BankAccountNumber ban |> Ok

    static member NewOptional(ban) =
        match ban with
        | null
        | "" -> None |> Ok
        | _ -> BankAccountNumber ban |> Some |> Ok

[<RequireQualifiedAccess>]
module Domain =
    type Member =
        { Id: MemberId
          OrganizationId: OrganizationId
          FirstName: FirstName
          LastName: LastName
          Jmbg: Jmbg option
          PersonalId: PersonalId option
          Address: Address option
          Joined: Date
          BankAccountNumber: BankAccountNumber option
          Education: string
          Position: string
          Notes: string
          Email: Email option }


    module Member = 
        let Create
            (id: MemberId)
            (organizationId: OrganizationId)
            (firstName: FirstName)
            (lastName: LastName)
            (jmbg: Jmbg option)
            (personalId: PersonalId option)
            (address: Address option)
            (joined: Date)
            (bankAccountNumber)
            (education: string)
            (position: string)
            (notes: string)
            (email: Email option)
            : Result<Member, AggregateError>
            =
                Ok {
                        Id = id
                        OrganizationId = organizationId
                        FirstName = firstName
                        LastName = lastName
                        Jmbg = jmbg
                        PersonalId = personalId
                        Address = address
                        Joined = joined
                        BankAccountNumber = bankAccountNumber
                        Education = education
                        Position = position
                        Notes = notes
                        Email = email }

