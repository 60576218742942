module Modules.Auth.Default

open Elmish.UrlParser

type Page =
    | Login
    | Challenge of string
    override this.ToString() =
        match this with
        | Login -> ""
        | Challenge c -> $"/challenge/{c}"

let parser =
    oneOf [
        map Login (top)
        map Challenge (s "challenge" </> str)
    ]

let getPage url : Page =
    match parseUrl parser url with
    | Some p -> p
    | None -> Login

let create page startSession =
    match page with
    | Login -> Login.create ()
    | Challenge challenge -> Challenge.create challenge startSession
