module Modules.Employees.Home

open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Auth
open Modules.Employees.Types
open IEmployeesApi
open Shared

type private ConfirmTerminateModalState =
    | Closed
    | Opened

type private State =
    { GettingOrgEmployees: Deferred<GetOrgEmployeesResponse>
      TerminatingEmployee: CommandExec<TerminateEmployeeCommand>
      Session: ClientSession
      EmployeeForTermination: OrgEmployee option
      TerminateModalState: ConfirmTerminateModalState }
      static member Default session =
        { GettingOrgEmployees = Deferred.NotStarted
          TerminatingEmployee = CommandExec.NotStarted
          Session = session
          EmployeeForTermination = None
          TerminateModalState = Closed }

type private Message =
    | GetOrgEmployees of Query<string, GetOrgEmployeesResponse>
    | OpenConfirmTerminateModal of OrgEmployee
    | CloseModal
    | TerminateEmployee of Command<TerminateEmployeeCommand>

let private init state =
    state, Cmd.ofMsg (state.Session.OrgInfo.Id |> Query.Started |> GetOrgEmployees)

let private employeesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IEmployeesApiRoute.builder
    |> Remoting.buildProxy<IEmployeesApi>

let private update unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match msg with
    | CloseModal -> { state with EmployeeForTermination = None; TerminateModalState = Closed }, Cmd.none
    | GetOrgEmployees fo ->
        match fo with
        | Query.Started _ when state.GettingOrgEmployees = Deferred.InProgress -> state, Cmd.none
        | Query.Started orgId ->
            { state with GettingOrgEmployees = Deferred.InProgress },
            remoteCallQuery
                employeesApi.getOrgEmployees
                { SessionId = state.Session.SessionId.Value
                  Content = { OrganizationId = orgId } }
                GetOrgEmployees
        | Query.Completed result ->
            { state with GettingOrgEmployees = Deferred.Resolved result },
            Cmd.none
        | Query.Error err ->
            { state with GettingOrgEmployees = Deferred.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage
    | OpenConfirmTerminateModal employee ->
        { state with
            TerminateModalState = Opened
            EmployeeForTermination = Some employee
            }, Cmd.none
    | TerminateEmployee de ->
        match de with
        | Command.Started _ when state.TerminatingEmployee = CommandExec.InProgress -> state, Cmd.none
        | Command.Started employeeId ->
            { state with TerminatingEmployee = CommandExec.InProgress },
            remoteCallCommand
                employeesApi.sendTerminateCommand
                { SessionId = state.Session.SessionId.Value
                  Content =  employeeId }
                TerminateEmployee
        | Command.Completed ->
            match state.GettingOrgEmployees with
                | Deferred.Resolved employees->
                    { state with
                        TerminatingEmployee = CommandExec.Completed
                        GettingOrgEmployees =
                            match state.EmployeeForTermination with
                            | Some terminated -> Deferred.Resolved (employees |> List.filter (fun employee -> employee.Id <> terminated.Id))
                            | None -> Deferred.Resolved (employees)
                        EmployeeForTermination = None
                    }
                | _ ->
                    { state with
                        TerminatingEmployee = CommandExec.Completed
                        EmployeeForTermination = None
                    }
            ,
            Cmd.batch [
                Cmd.ofMsg CloseModal
                Cmd.successToast "Employee terminated."
            ]
        | Command.Error err ->
            { state with TerminatingEmployee =   CommandExec.NotStarted },
            Cmd.ofErr err unauthorizedRedirPage

let private view (state: IStore<State>) dispatch setPage =
    Html.div [
        UI.heading "Employees"

        Html.divc "flex w-full flex-col justify-start space-y-2 sm:flex-row sm:space-y-0" [
            Html.buttonc "focus:ring outline-none rounded-lg text-white bg-blue-600 px-8 py-2 font-bold active:scale-95 hover:opacity-90" [
                Html.text "Create New"
                onClick (fun _ -> setPage Create) []
            ]
        ]

        Bind.el(
            state .>> (fun s -> s.GettingOrgEmployees), fun gettingEmployees ->
            match gettingEmployees with
            | Deferred.NotStarted
            | Deferred.InProgress -> UI.loader
            | Deferred.Resolved employees ->
                Html.divc "overflow-x-auto w-full py-5" [
                    Html.tablec "mx-auto w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden" [
                        Html.theadc "bg-gray-50" [
                            Html.trc "text-black text-left" [
                                let cell el =
                                    Html.tdc "font-semibold text-sm uppercase px-6 py-4" [el]

                                cell (Html.text "First Name")
                                cell (Html.text "Last Name")
                                cell (Html.text "Email")
                                cell (Html.text "Joined on")
                                cell (Html.text "Actions")
                            ]
                        ]
                        Html.tbody [
                            Attr.className "divide-y divide-gray-200"
                            fragment (
                                employees |>
                                List.map (fun employee ->
                                    Html.tr [
                                        let cell el = Html.tdc "px-6 py-4" [el]

                                        cell(Html.text $"{employee.FirstName}")
                                        cell(Html.text $"{employee.LastName}")
                                        cell(Html.text $"{employee.Email}")
                                        cell(Html.text $"{employee.Joined}")
                                        cell(
                                            Html.divc "flex items-center" [
                                                UI.elementWithTooltip
                                                    "Edit employee"
                                                    Html.a[
                                                        Attr.className "rounded border border-transparent focus:outline-none focus:border-gray-800 focus:shadow-outline-gray"
                                                        onClick (fun _ -> setPage (Edit employee.Id)) []
                                                        Html.divc "p-2 bg-gray-100 hover:bg-gray-200 rounded cursor-pointer text-blue-600" [
                                                            Html.ic "fa-regular fa-pen-to-square" []
                                                        ]
                                                    ]
                                                UI.elementWithTooltip
                                                    "Terminate employee"
                                                    Html.a[
                                                        Attr.className "mx-2 rounded border border-transparent focus:outline-none focus:border-gray-800 focus:shadow-outline-gray"
                                                        onClick (fun _ -> employee |> OpenConfirmTerminateModal  |> dispatch) []
                                                        Html.divc "p-2 bg-gray-100 hover:bg-gray-200 rounded cursor-pointer text-red-600" [
                                                            Html.ic "fa-regular fa-trash-can" []
                                                        ]
                                                    ]
                                            ]
                                        )
                                    ]
                                )
                            )
                        ]
                    ]
                ]
            )

        Bind.el (
            state .>> (fun s ->
                s.TerminateModalState = Opened && Option.isSome s.EmployeeForTermination,
                CommandState.notInProgress s.TerminatingEmployee,
                s.EmployeeForTermination
            ),
            fun (modalVisible, buttonsEnabled, employee) ->
                match employee with
                | Some e ->
                    UI.renderDeleteConfirmationModal
                        modalVisible
                        $"Are you sure you want to terminate {e.FirstName} {e.LastName}?"
                        (fun _ -> CloseModal |> dispatch)
                        (fun _ -> ({ EmployeeId = e.Id } |> Command.Started |> TerminateEmployee |> dispatch))
                        buttonsEnabled
                | None -> Html.none
        )
    ]

let create session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish init (update unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]
