module Modules.Employees.Edit

open IEmployeesApi
open Sutil
open Sutil.CoreElements
open Fable.Remoting.Client
open Modules.Employees.Types
open Auth
open Browser.Types
open Modules.Employees.Utils
open Employees.UI

type private State =
    { Session: ClientSession
      GettingEmployee: Deferred<UpdateEmployeeCommand>
      UpdatingEmployee: CommandExec<UpdateEmployeeCommand> }

    static member Default session =
        { Session = session
          GettingEmployee = Deferred.NotStarted
          UpdatingEmployee = CommandExec.NotStarted }

type private FileInfo = {
    Id: string
    Name: string
}

type private Message =
    | GetUpdateCommand of Query<string, UpdateEmployeeCommand>
    | SendUpdateCommand of Command<UpdateEmployeeCommand>
    | SetFirstName of string
    | SetLastName of string
    | SetJoinedDate of string
    | SetEmail of string
    | SetJmbg of string
    | SetPersonalId of string
    | SetAddress of string
    | SetBankAccountNumber of string
    | SetEducation of string
    | SetPosition of string
    | SetNotes of string
    | RemoveFile of string
    | AddFile of File
    | DownloadFile of Query<FileInfo, GetFileResponse>
    | HandleAddedFileContent of RawFileInfo

let private employeesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder IEmployeesApiRoute.builder
    |> Remoting.buildProxy<IEmployeesApi>

let private init employeeId state  = state, Cmd.ofMsg (employeeId |> Query.Started |> GetUpdateCommand)

let private update setPage unauthorizedRedirPage (msg: Message) (state: State) : State * Cmd<Message> =
    match state.GettingEmployee with
    | Deferred.Resolved emp ->
        match msg with
        | SetFirstName firstName -> {state with GettingEmployee = Deferred.Resolved { emp with FirstName = firstName }}, Cmd.none
        | SetLastName lastName -> {state with GettingEmployee = Deferred.Resolved { emp with LastName = lastName }}, Cmd.none
        | SetJoinedDate joinedDate -> {state with GettingEmployee = Deferred.Resolved { emp with Joined = joinedDate }}, Cmd.none
        | SetEmail email -> {state with GettingEmployee = Deferred.Resolved { emp with Email = email }}, Cmd.none
        | SetJmbg jmbg -> {state with GettingEmployee = Deferred.Resolved { emp with Jmbg = jmbg }}, Cmd.none
        | SetPersonalId personalId -> {state with GettingEmployee = Deferred.Resolved { emp with PersonalId = personalId }}, Cmd.none
        | SetAddress address -> {state with GettingEmployee = Deferred.Resolved { emp with Address = address }}, Cmd.none
        | SetBankAccountNumber ban -> {state with GettingEmployee = Deferred.Resolved { emp with BankAccountNumber = ban }}, Cmd.none
        | SetEducation education -> {state with GettingEmployee = Deferred.Resolved { emp with Education = education }}, Cmd.none
        | SetPosition position -> {state with GettingEmployee = Deferred.Resolved { emp with Position = position }}, Cmd.none
        | SetNotes notes -> {state with GettingEmployee = Deferred.Resolved { emp with Notes = notes }}, Cmd.none
        | SendUpdateCommand sur ->
            match sur with
            | Command.Started _ when state.UpdatingEmployee = CommandExec.InProgress -> state, Cmd.none
            | Command.Started uer ->
                { state with UpdatingEmployee = CommandExec.InProgress },
                remoteCallCommand
                    employeesApi.sendUpdateCommand
                    { SessionId = state.Session.SessionId.Value
                      Content = uer }
                    SendUpdateCommand
            | Command.Completed ->
                { state with UpdatingEmployee = CommandExec.Completed },
                Cmd.batch [
                    Cmd.successToast "Employee updated."
                    Cmd.ofEffect (fun _ -> setPage Home)
                ]
            | Command.Error err ->
                { state with UpdatingEmployee = CommandExec.NotStarted },
                Cmd.ofErr err unauthorizedRedirPage
        | RemoveFile fileId ->
            { state with
                GettingEmployee = Deferred.Resolved {
                    emp with
                        Files = emp.Files |> List.filter (fun file -> file.FileId <> fileId);
                        RemoveFiles = emp.RemoveFiles @ [ fileId ]
                } },
            Cmd.none
        | AddFile file ->
            state,
            Cmd.OfAsync.perform readContent file HandleAddedFileContent
        | HandleAddedFileContent fi ->
            let newFile : Shared.Types.FileMeta = { FileId = ""; Content = fi.Content; Name = fi.Name; MimeType = fi.MimeType; Size = fi.Size}

            { state with
                GettingEmployee = Deferred.Resolved {
                    emp with
                        Files = emp.Files @ [ newFile ]
                        AddFiles = emp.AddFiles @ [ newFile ]
                }
            },
            Cmd.none
        | DownloadFile msg ->
            match msg with
            | Query.Started _ when state.UpdatingEmployee = CommandExec.InProgress -> state, Cmd.none
            | Query.Started fileInfo ->
                { state with UpdatingEmployee = CommandExec.InProgress },
                remoteCallQuery
                    employeesApi.getFileRequest
                        { SessionId = state.Session.SessionId.Value
                          Content = { FileId = fileInfo.Id; FileName = fileInfo.Name ; EmployeeId = emp.EmployeeId } }
                    DownloadFile
            | Query.Completed response ->
                response.FileContent.SaveFileAs(response.FileName)
                { state with UpdatingEmployee = CommandExec.Completed },
                Cmd.successToast "File downloaded."
            | Query.Error err ->
                { state with UpdatingEmployee = CommandExec.NotStarted },
                Cmd.errorToast err
        | GetUpdateCommand gur ->
            state, Cmd.none
    | Deferred.NotStarted
    | Deferred.InProgress ->
        match msg with
        | GetUpdateCommand gur ->
            match gur with
            | Query.Started _ when state.GettingEmployee = Deferred.InProgress -> state, Cmd.none
            | Query.Started employeeId ->
                { state with GettingEmployee = Deferred.InProgress },
                remoteCallQuery
                    employeesApi.getUpdateCommand
                    { SessionId = state.Session.SessionId.Value
                      Content = employeeId }
                    GetUpdateCommand
            | Query.Completed res ->
                { state with GettingEmployee = Deferred.Resolved res },
                Cmd.none
            | Query.Error err ->
                { state with GettingEmployee = Deferred.NotStarted },
                Cmd.ofErr err unauthorizedRedirPage
        | _ ->
            state, Cmd.none

let private view state dispatch setPage =

    let files = Store.make Unchecked.defaultof<Browser.Types.FileList>
    let fileSeq = files |> Store.map (Helpers.fileListToSeq >> Seq.toList)

    fragment [
        disposeOnUnmount [ files ]

        disposeOnUnmount [
            fileSeq
            |> Store.iter (
                fun files ->
                   for file in files do
                        dispatch (AddFile file)
            )
        ]

        let getFiles s =
            match s.GettingEmployee with
            | Deferred.NotStarted
            | Deferred.InProgress -> []
            | Deferred.Resolved x -> x.Files

        let getFieldContent fieldSelector =
            (state .>> fun s ->
                match s.GettingEmployee with
                | Deferred.Resolved x -> fieldSelector x
                | Deferred.InProgress -> "Loading..."
                | Deferred.NotStarted -> "")

        let renderTextField name fieldSelector onChange =
            UI.renderTextField name (getFieldContent fieldSelector) onChange

        let renderDateField name fieldSelector onChange =
            UI.renderDateField name (getFieldContent fieldSelector) onChange

        let renderTextAreaField rows name fieldSelector onChange =
            UI.renderTextAreaField rows name (getFieldContent fieldSelector) onChange

        let renderTextAreaFieldFullWidth rows name fieldSelector onChange =
            UI.renderTextAreaFieldFullWidth rows name (getFieldContent fieldSelector) onChange

        UI.heading "Edit employee"
        Html.divc "w-full bg-white rounded-lg p-6" [
            Html.divc "grid grid-cols-1 sm:grid-cols-2 gap-4" [
                renderTextField "First name" (fun s -> s.FirstName) (SetFirstName >> dispatch)
                renderTextField "Last name" (fun s -> s.LastName) (SetLastName >> dispatch)
                renderTextField "Email" (fun s -> s.Email) (SetEmail >> dispatch)
                renderDateField "Joined on" (fun s -> s.Joined) (SetJoinedDate >> dispatch)
                renderTextField "JMBG" (fun s -> s.Jmbg) (SetJmbg >> dispatch)
                renderTextField "Personal ID" (fun s -> s.PersonalId) (SetPersonalId >> dispatch)
                renderTextField "Bank Account Number" (fun s -> s.BankAccountNumber) (SetBankAccountNumber >> dispatch)
                renderTextField "Education" (fun s -> s.Education) (SetEducation >> dispatch)
                renderTextAreaField 3 "Address" (fun s -> s.Address) (SetAddress >> dispatch)
                renderTextField "Position" (fun s -> s.Position) (SetPosition >> dispatch)
                renderTextAreaFieldFullWidth 6 "Notes" (fun s -> s.Notes) (SetNotes >> dispatch)
            ]

            Html.divc "flex flex-col sm:flex-row justify-start mt-4" [
                Html.divc "" [
                    Html.labelc "block uppercase tracking-wide text-gray-700 text-xs font-medium mb-2" [
                        Html.text "Files"
                    ]

                    Html.divc "flex flex-wrap justify-start" [

                        Bind.each (
                            state .>> (fun s -> s |> getFiles),
                            fun file ->
                                Html.divc "relative mr-5 mb-5 md:mb-0 cursor-pointer" [
                                    renderPreview file.MimeType file.Content
                                    removeFileBtn (fun _ -> RemoveFile file.FileId |> dispatch)
                                    renderFileName file.Name
                                    onClick (fun _ -> { Id = file.FileId; Name = file.Name } |> Query.Started |> DownloadFile |> dispatch) []
                                ]
                        )

                        uploadFileArea files
                    ]
                ]
            ]


            Html.divc "border-t my-4" [ ]

            Html.divc "flex justify-start" [
                Bind.el (
                    state .>> (fun s -> (Deferred.resolved s.GettingEmployee) && (CommandExec.notInProgress s.UpdatingEmployee)),
                    UI.okBtn "Update" (fun _ -> (state |> Store.get).GettingEmployee |> Deferred.exec (fun c -> c |> Command.Started |> SendUpdateCommand |> dispatch))
                )
                Bind.el (
                    state .>> (fun s -> (Deferred.notInProgress s.GettingEmployee) && (CommandExec.notInProgress s.UpdatingEmployee)),
                    UI.cancelBtn "Cancel" (fun _ -> setPage Home)
                )
            ]

        ]

        Bind.el (state .>> (fun s -> s.GettingEmployee), UI.spinnerDeferred)
    ]

let create employeeId session setPage (unauthorizedRedirPage : obj -> unit) =
    let state, dispatch =
        State.Default session
        |> Store.makeElmish (init employeeId) (update setPage unauthorizedRedirPage) ignore

    fragment [
        disposeOnUnmount [ state ]
        view state dispatch setPage
    ]
